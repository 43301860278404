import {
	faSquareCheck,
	faSquareExclamation,
	faSquareInfo,
	faSquareQuestion,
} from "@fortawesome/pro-duotone-svg-icons";
import { StatusCardStatus, StatusCardVariant } from "./statusCardTypes";

export const getStatusCardParentStyles = ({
	status,
	variant,
	className = "",
}: {
	status: StatusCardStatus;
	variant: StatusCardVariant;
	className?: string;
}): string => {
	const base =
		"relative group/card flex cursor-pointer items-center rounded-md border duration-300 hover:bg-background-secondary hover:border-blue-rinse-1";

	const variantClasses = {
		[StatusCardVariant.card]: "pl-2 pr-4 justify-between",
		[StatusCardVariant.tile]: "justify-center",
	};

	return `${base} ${className} ${variantClasses[variant]}`;
};

export const getStatusCardBorderStyles = ({
	status,
}: { status: StatusCardStatus }): string => {
	const base =
		"absolute inset-x-0 -bottom-px block h-px w-full bg-gradient-to-r from-transparent to-transparent";
	const borderStyles = {
		[StatusCardStatus.operational]: "via-success-6",
		[StatusCardStatus.disturbance]: "via-warning-6",
		[StatusCardStatus.maintenance]: "via-info-6",
		[StatusCardStatus.unknown]: "",
	};

	return `${base} ${borderStyles[status]}`;
};

export const getIconColor = ({
	status,
	isPrimaryColor,
}: {
	status: StatusCardStatus;
	isPrimaryColor: boolean;
}): string => {
	const colorMap = {
		[StatusCardStatus.operational]: isPrimaryColor ? "success" : "success-5",
		[StatusCardStatus.disturbance]: isPrimaryColor ? "warning" : "warning-9",
		[StatusCardStatus.maintenance]: isPrimaryColor ? "info" : "info-6",
		[StatusCardStatus.unknown]: isPrimaryColor ? "blue-rinse" : "blue-rinse-9",
	};

	return colorMap[status];
};

export const getStatusIcon = (status: StatusCardStatus) => {
	const iconMap = {
		[StatusCardStatus.operational]: faSquareCheck,
		[StatusCardStatus.disturbance]: faSquareExclamation,
		[StatusCardStatus.maintenance]: faSquareInfo,
		[StatusCardStatus.unknown]: faSquareQuestion,
	};

	return iconMap[status];
};
