import { IconSize } from "@/components/ui/icon/helpers/iconTypes";
import { Icon } from "@/components/ui/icon/icon";
import { faFolders, faImage, faClapperboardPlay, faFileLines, faQuestionCircle } from "@fortawesome/pro-duotone-svg-icons";
import { ContentItemType } from "shared-types"

export const getContentItemTypeIcon = (type: ContentItemType) => {
  if (type === ContentItemType.Folder) {
    return (
      <Icon
        fixedWidth
        icon={faFolders}
        primaryColor="sea-green-9"
        secondaryColor="sea-green-6"
        size={IconSize.Large}
      />
    );
  }
  if (type === ContentItemType.Image) {
    return (
      <Icon
        fixedWidth
        icon={faImage}
        primaryColor="cerise-pink"
        secondaryColor="cerise-pink-6"
        size={IconSize.Large}
      />
    );
  }
  if (type === ContentItemType.Document) {
    return (
      <Icon
        fixedWidth
        icon={faFileLines}
        primaryColor="coral-9"
        secondaryColor="coral-6"
        size={IconSize.Large}
      />
    );
  }
  if (type === ContentItemType.Video) {
    return (
      <Icon
        fixedWidth
        icon={faClapperboardPlay}
        primaryColor="khaki-9"
        secondaryColor="khaki-6"
        size={IconSize.Large}
      />
    );
  }
  return <Icon fixedWidth icon={faQuestionCircle} />;
};
