import { ButtonType } from "@/components/ui/button/helpers/buttonTypes";
import { AnimatePresence, motion } from "framer-motion";
import { FaqTabData } from "../helpers/faqData";
import type { FaqTabsProps } from "../helpers/faqTypes";

const FaqTabs: React.FC<FaqTabsProps> = ({ selectedTab, setSelectedTab }) => {
	return (
		<div className="relative z-10 flex flex-wrap items-center justify-center gap-4">
			{FaqTabData.map((tab) => (
				<button
					type={ButtonType.Button}
					onClick={() => setSelectedTab(tab.key)}
					className={`relative overflow-hidden whitespace-nowrap rounded-md border px-3 py-1.5 text-sm font-medium transition-colors duration-500 ${
						selectedTab === tab.key
							? "border-primary"
							: "bg-transparent text-foreground-secondary"
					}`}
					key={tab.key}
				>
					<span className="relative z-10">{tab.title}</span>
					<AnimatePresence>
						{selectedTab === tab.key && (
							<motion.span
								initial={{ y: "100%" }}
								animate={{ y: "0%" }}
								exit={{ y: "100%" }}
								transition={{
									duration: 0.5,
									ease: "backIn",
								}}
								className="absolute inset-0 z-0 bg-gradient-to-r from-primary to-primary"
							/>
						)}
					</AnimatePresence>
				</button>
			))}
		</div>
	);
};

FaqTabs.displayName = "FaqTabs";
export default FaqTabs;
