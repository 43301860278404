import apiClient from "@/lib/api";
import type { IServerResArray } from "@/lib/responses";
import type { IContentClient } from "shared-types";

/**
 * @description Fetches all content from the server
 *
 * @returns {Promise<IServerResArray<IContentClient[]>>} A promise that resolves to an array of content.
 *
 * @throws {Error} Throws an error if the fetch request fails or if the response is not valid.
 */
const fetchAllContent = async (): Promise<
	IServerResArray<IContentClient[]>
> => {
	const response = await apiClient
		.get("content")
		.json<IServerResArray<IContentClient[]>>();

	return response;
};

export default fetchAllContent;
