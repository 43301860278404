import { IconSize } from "@/components/ui/icon/helpers/iconTypes";
import { Icon } from "@/components/ui/icon/icon";
import * as t from "@/i18n/paraglide/messages";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "@tanstack/react-router";
import { motion } from "framer-motion";
import type { FeatureCardProps } from "../helpers/featureTypes";

const FeatureCard: React.FC<FeatureCardProps> = ({
	feature,
	index,
	open,
	setOpen,
}) => {
	const isOpen = index === open;

	return (
		<div
			onClick={() => setOpen(index)}
			className="relative cursor-pointer overflow-hidden rounded-md p-0.5"
		>
			<motion.div
				initial={false}
				animate={{
					height: isOpen ? "240px" : "60px",
				}}
				className="relative z-20 flex flex-col justify-between rounded-md bg-background-tertiary p-4"
			>
				<div>
					<motion.div
						initial={false}
						animate={{
							color: isOpen ? "" : "",
						}}
						className="w-full flex justify-between items-center"
					>
						<div className="flex gap-3 items-center ">
							<Icon
								fixedWidth
								icon={feature.Icon}
								size={IconSize.Large}
								primaryColor={feature.colors.primary}
								secondaryColor={feature.colors.secondary}
							/>
							<span className="text-lg">{feature.title}</span>
						</div>
						{feature.isEnterprise && (
							<div className="flex h-6 bg-accent px-2 rounded-md items-center text-sm">
								Enterprise
							</div>
						)}
					</motion.div>
					<motion.p
						initial={false}
						animate={{
							opacity: isOpen ? 1 : 0,
						}}
						className="mt-4 bg-clip-text text-foreground-secondary leading-relaxed"
					>
						{feature.description}
					</motion.p>
				</div>
				<Link
					to={feature.href}
					hash={feature.hash}
					className="group -mx-6 -mb-5 mt-4 flex justify-center items-center rounded-b-md bg-primary py-2 transition-[gap]"
				>
					<motion.button
						initial={false}
						animate={{
							opacity: isOpen ? 1 : 0,
						}}
						className="flex items-center justify-center gap-1"
					>
						<span>{t.learnMore()}</span>
						<FontAwesomeIcon
							fixedWidth
							icon={faArrowRight}
							size={IconSize.Small}
							className="transition-transform group-hover:translate-x-1"
						/>
					</motion.button>
				</Link>
			</motion.div>
			<motion.div
				initial={false}
				animate={{
					opacity: isOpen ? 1 : 0,
				}}
				className="absolute inset-0 z-10 bg-primary"
			/>
			<div className="absolute inset-0 z-0 bg-background-secondary" />
		</div>
	);
};

FeatureCard.displayName = "FeatureCard";
export default FeatureCard;
