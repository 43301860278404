import LoginScreen from "@/components/screens/landingPage/auth/login/loginScreen";
import * as t from "@/i18n/paraglide/messages";
import { createFileRoute } from "@tanstack/react-router";
import { zodSearchValidator } from "@tanstack/router-zod-adapter";
import { z } from "zod";

const loginScreenSchema = z.object({
	verificationSuccess: z.coerce.boolean().optional(),
});

export const Route = createFileRoute("/_landingPageLayout/(auth)/login")({
	component: LoginScreen,
	validateSearch: zodSearchValidator(loginScreenSchema),
	beforeLoad: () => {
		document.title = `${import.meta.env.VITE_APP_NAME} • ${t.loginMetaTitle()}`;
	},
});
