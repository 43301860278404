export function formatFileSize(bytes: number): {
	formattedSize: string;
	unit: string;
} {
	const units = ["B", "KB", "MB", "GB", "TB"];
	let unitIndex = 0;

	// Convert bytes to the appropriate unit
	while (bytes >= 1024 && unitIndex < units.length - 1) {
		bytes /= 1024;
		unitIndex++;
	}

	// Format with at most 2 decimal places for readability
	const formattedSize = bytes.toFixed(bytes >= 10 ? 0 : 2); // No decimals for values >= 10
	return { formattedSize, unit: units[unitIndex] };
}
