import { z } from "zod";

export const AddressSchema = z.object({
	formattedAddress: z.string().optional(),
	street: z.string().optional(),
	streetNumber: z.string().optional(),
	postalCode: z.string().optional(),
	city: z.string().optional(),
	addition: z.string().optional(),
	lat: z.number().optional(),
	lng: z.number().optional(),
	country: z
		.object({
			name: z.string().optional(),
			code: z.string().optional(),
		})
		.optional(),
	location: z.object({
		type: z.string().optional(),
		coordinates: z.array(z.number()).optional(),
	}),
});
