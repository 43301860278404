import ScreenWrapper from "@/components/layout/app/screenWrapper/screenWrapper";
import Tabs from "@/components/ui/tabs/tabs";
import { TabsContent } from "@radix-ui/react-tabs";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import {
	BillingTabData,
	type BillingTabDataProps,
	BillingTabIds,
} from "./helpers/billingScreenData";
import BillingDetailDataTab from "./tabs/billingDetailTab";
import BillingInvoicesTab from "./tabs/billingInvoicesTab";

const BillingScreen = () => {
	const [activeTab, setActiveTab] = useState<BillingTabDataProps>(
		BillingTabData[0],
	);

	const getTabContent = (tab: BillingTabDataProps) => {
		switch (tab.id) {
			case BillingTabIds.Details:
				return <BillingDetailDataTab />;
			case BillingTabIds.Invoices:
				return <BillingInvoicesTab />;
			default:
				return <BillingDetailDataTab />;
		}
	};

	return (
		<ScreenWrapper breadcrumbs={[]} title={"Zahlung"}>
			<div className="flex">
				<Tabs
					tabs={BillingTabData}
					activeTab={activeTab}
					handleClick={(tab) => {
						setActiveTab(tab);
					}}
				>
					{BillingTabData.map((tab) => (
						<TabsContent key={tab.id} value={tab.id}>
							<AnimatePresence mode="wait">
								<motion.div
									key={tab.id}
									initial={{ y: 10, opacity: 0 }}
									animate={{ y: 0, opacity: 1 }}
									exit={{ y: -10, opacity: 0 }}
									transition={{ duration: 0.2 }}
								>
									<div className="mt-6">{getTabContent(tab)}</div>
								</motion.div>
							</AnimatePresence>
						</TabsContent>
					))}
				</Tabs>
			</div>
		</ScreenWrapper>
	);
};

BillingScreen.displayName = "BillingScreen";
export default BillingScreen;
