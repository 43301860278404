import type { MenuButtonProps } from "./helpers/menuButtonTypes";

/**
 * MenuButton component renders a button that toggles a menu open or closed.
 * It uses a hidden checkbox input to manage the open/closed state and a label
 * to style the button with three bars (commonly known as a hamburger menu).
 *
 * @component
 * @param {boolean} isOpen - Indicates whether the menu is open.
 * @param {() => void} onClick - Function to handle the click event, toggling the menu state.
 * @returns {JSX.Element} The rendered MenuButton component.
 */
function MenuButton({ isOpen, onClick }: MenuButtonProps) {
	return (
		<>
			<input
				type="checkbox"
				id="checkbox"
				checked={isOpen}
				onChange={onClick}
				style={{ display: "none" }}
			/>
			<label htmlFor="checkbox" className="toggle">
				<div className="bars" id="bar1" />
				<div className="bars" id="bar2" />
				<div className="bars" id="bar3" />
			</label>
		</>
	);
}

MenuButton.displayName = "MenuButton";
export default MenuButton;
