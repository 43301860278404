import { HoverCardIconSize, HoverCardVariant } from "./hoverCardProps";

export const getStylingParent = ({
	variant,
	colors: { secondary, tertiary },
}: {
	variant: HoverCardVariant;
	colors: {
		secondary: string;
		tertiary: string;
	};
}): string => {
	const base = `group flex transition gap-4 rounded-md px-4 py-4 cursor-pointer duration-300 hover:bg-${tertiary}`;

	const config = {
		variant: {
			[HoverCardVariant.Bordered]: `border border-${secondary} hover:border-${tertiary}`,
			[HoverCardVariant.Borderless]: "",
		},
	};

	return `${base} ${config.variant[variant]}`;
};

export const getStylingIcon = ({
	iconSize,
}: { iconSize: HoverCardIconSize }): string => {
	const base = "flex flex-col";

	const config = {
		iconSize: {
			[HoverCardIconSize.Small]: "justify-start pt-1",
			[HoverCardIconSize.Large]: "justify-center",
		},
	};

	return `${base} ${config.iconSize[iconSize]}`;
};
