import { cn } from "@/lib/utils";
import React from "react";

const TableRow = React.forwardRef<
	HTMLTableRowElement,
	React.HTMLAttributes<HTMLTableRowElement>
>(({ className, ...props }, ref) => (
	<tr
		ref={ref}
		className={cn(
			"border-b transition-colors duration-300 data-[state=selected]:bg-background-quaternary hover:cursor-pointer hover:bg-background-quaternary [&_td:last-child]:rounded-br-md [&_td:first-child]:rounded-bl-md",
			className,
		)}
		{...props}
	/>
));

TableRow.displayName = "TableRow";
export default TableRow;
