import Button from "@/components/ui/button/button";
import { ButtonType } from "@/components/ui/button/helpers/buttonTypes";
import { CommandShortcut } from "@/components/ui/command/command";
import Dialog from "@/components/ui/dialog/dialog";
import FormFileInput from "@/components/ui/form/components/formFileInput/formFileInput";
import FormTextInput from "@/components/ui/form/components/formTextInput/formTextInput";
import Label from "@/components/ui/label/label";
import * as t from "@/i18n/paraglide/messages";
import { QueryKeys } from "@/lib/enums/enums";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { type SubmitHandler, useForm, useFormContext } from "react-hook-form";
import { toast } from "react-toastify";
import { ContentItemType, FileEntity } from "shared-types";
import { z } from "zod";
import { useUpdateContent } from "../../../hooks/useUpdateContent";
import { ContentTypeCardData } from "../helpers/contentTreeData";
import ContentTypeCard from "./contentTypeCard/contentTypeCard";
import { AddContentItemFunctionType } from "../../../hooks/useContentItemFormField";

export interface CreateContentTreeItemDialogProps {
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	addItem: AddContentItemFunctionType;
	parentPrefix?: string;
}

export interface CreateContentTreeItem {
	name: string;
	type: ContentItemType;
	url?: string;
}

const CreateContentTreeItemDialog: React.FC<
	CreateContentTreeItemDialogProps
> = ({ addItem, isOpen, setIsOpen, parentPrefix}) => {
	const { mutateAsync: updateContent } = useUpdateContent();

	const queryClient = useQueryClient();
	const parentForm = useFormContext();
	const contentId = parentForm.getValues("_id");

	const CreateContentTreeItemSchema = z.object({
		name: z.string({ message: t.validationRequired({ fieldName: t.name() }) }),
		type: z.nativeEnum(ContentItemType),
		file: z.object({
			name: z.string(),
			url: z.string().url(),
			size: z.number(),
			fileExtension: z.string(),
		}),
	});

	const dialogForm = useForm<z.infer<typeof CreateContentTreeItemSchema>>({
		resolver: zodResolver(CreateContentTreeItemSchema),
		defaultValues: {
			type: ContentItemType.Folder,
		},
		mode: "onBlur",
	});

	console.log("dialogFormValues", dialogForm.getValues());
	console.log("dialogFormState", dialogForm.formState);
	

	const contentType = dialogForm.watch("type");

	const handleCardSelect = (type: ContentItemType) => {
		dialogForm.setValue("type", type);
	};

	const onSubmit: SubmitHandler<
		z.infer<typeof CreateContentTreeItemSchema>
	> = async (values) => {

		console.log("submitvalues", values);

		addItem({ name: values.name, type: values.type, file: values.file, depth: 0});

		toast.promise(
			updateContent(
				{ data: parentForm.getValues() },
				{
					onSuccess: () => {
						queryClient.invalidateQueries({
							queryKey: [QueryKeys.Content, contentId],
						});
						setIsOpen(false);
					},
					onSettled: () => {
						dialogForm.reset();
					},
				},
			),
			{
				pending: t.toastSavePending({ entityName: t.content() }),
				success: t.toastSaveSuccess({ entityName: t.content() }),
				error: t.toastSaveError({ entityName: t.content() }),
			},
		);
	};

	const getFileDescription = () => {
		if (contentType === ContentItemType.Folder) {
			return (
				<span>
					Erlaubte Formate für das Thumbnail sind{" "}
					<CommandShortcut>.jpg</CommandShortcut>,{" "}
					<CommandShortcut>.jpeg</CommandShortcut>,{" "}
					<CommandShortcut>.png</CommandShortcut>,{" "}
					<CommandShortcut>.svg</CommandShortcut>,{" "}
					<CommandShortcut>.webp</CommandShortcut>. Die Maximale Dateigröße
					beträgt <CommandShortcut>10 MB</CommandShortcut>.
				</span>
			);
		}
		if (contentType === ContentItemType.Image) {
			return (
				<span>
					Erlaubte Formate für Bilder sind{" "}
					<CommandShortcut>.jpg</CommandShortcut>,{" "}
					<CommandShortcut>.jpeg</CommandShortcut>,{" "}
					<CommandShortcut>.png</CommandShortcut>,{" "}
					<CommandShortcut>.svg</CommandShortcut>,{" "}
					<CommandShortcut>.webp</CommandShortcut>. Die Maximale Dateigröße
					beträgt <CommandShortcut>10 MB</CommandShortcut>.
				</span>
			);
		}
		if (contentType === ContentItemType.Document) {
			return (
				<span>
					Erlaubte Formate für Dateien sind{" "}
					<CommandShortcut>.pdf</CommandShortcut>,{" "}
					<CommandShortcut>.docx</CommandShortcut>. Die Maximale Dateigröße
					beträgt <CommandShortcut>20 MB</CommandShortcut>.
				</span>
			);
		}
		if (contentType === ContentItemType.Video) {
			return (
				<span>
					Erlaubte Formate für Videos sind{" "}
					<CommandShortcut>.mp4</CommandShortcut>,{" "}
					<CommandShortcut>.webm</CommandShortcut>,{" "}
					<CommandShortcut>.avi</CommandShortcut>,{" "}
					<CommandShortcut>.mpeg</CommandShortcut>. Die Maximale Dateigröße
					beträgt <CommandShortcut>300 MB</CommandShortcut>.
				</span>
			);
		}
		return "Bitte wähle zunächst einen Typ aus.";
	};

	return (
		<Dialog
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			title={"Neuer Content"}
			actionButtons={[
				<Button
					isLoading={dialogForm.formState.isSubmitting}
					disabled={!dialogForm.formState.isValid}
					label={t.add()}
					type={ButtonType.Submit}
					onClick={() => dialogForm.handleSubmit(onSubmit)()}
				/>,
			]}
		>
			<div className="flex flex-col gap-4">
				<span>
					Vergeben Sie einen Namen, wählen Sie den Typ und laden sie die
					gewünschte Datei hoch.
				</span>
				<div className="flex flex-col gap-6">
					<div className="w-full">
						<FormTextInput
							form={dialogForm}
							name="name"
							placeholder="Name"
							label="Name"
							isRequired
						/>
					</div>
					<div className="space-y-3">
						<div className="flex flex-col space-y-1 ml-2">
							<Label>Typ</Label>
							<span className="text-sm text-muted-foreground">
								Was soll angelegt werden?
							</span>
						</div>
						<div className="grid grid-cols-4 gap-4 w-full box-border">
							{ContentTypeCardData.map((data) => (
								<ContentTypeCard
									key={data.id}
									data={data}
									selectedType={contentType}
									setSelectedType={() => handleCardSelect(data.type)}
								/>
							))}
						</div>
					</div>
					<FormFileInput
						name="file"
						form={dialogForm}
						label="Datei"
						entity={FileEntity.Content}
						entityId={contentId}
						description={getFileDescription()}
						isRequired
						fileType={contentType}
					/>
				</div>
			</div>
		</Dialog>
	);
};

CreateContentTreeItemDialog.displayName = "CreateContentTreeItemDialog";
export default CreateContentTreeItemDialog;
