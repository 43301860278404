import { Icon } from "@/components/ui/icon/icon";
import {
	faCheckCircle,
	faExclamationCircle,
	faInfoCircle,
	faSpinnerThird,
	faXmarkCircle,
} from "@fortawesome/pro-duotone-svg-icons";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import type { FC, PropsWithChildren } from "react";
import { Slide, ToastContainer } from "react-toastify";
import { LanguageProvider } from "./languageProvider";

const Providers: FC<PropsWithChildren> = ({ children }) => {
	const CustomIcon = ({ type }: { type: string }) => {
		switch (type) {
			case "success":
				return (
					<Icon
						icon={faCheckCircle}
						primaryColor="success"
						secondaryColor="success-9"
					/>
				);
			case "error":
				return (
					<Icon
						icon={faXmarkCircle}
						primaryColor="error"
						secondaryColor="error-9"
					/>
				);
			case "info":
				return (
					<Icon
						icon={faInfoCircle}
						primaryColor="info"
						secondaryColor="info-9"
					/>
				);
			case "warning":
				return (
					<Icon
						icon={faExclamationCircle}
						primaryColor="warning"
						secondaryColor="warning-9"
					/>
				);
			default:
				return <Icon icon={faSpinnerThird} className="animate-spin" />;
		}
	};

	return (
		<>
			<LanguageProvider>
				{children}
				{import.meta.env.DEV && <ReactQueryDevtools initialIsOpen={false} />}
				{import.meta.env.DEV && (
					<TanStackRouterDevtools position="bottom-left" />
				)}
			</LanguageProvider>
			<ToastContainer
				transition={Slide}
				stacked
				icon={({ type }) => <CustomIcon type={type!} />}
			/>
		</>
	);
};

export default Providers;
