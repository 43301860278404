import apiClient from "@/lib/api";
import type { IServerRes } from "@/lib/responses";
import type { IEventClient } from "shared-types";


export const createEvent = async ({
	values,
}: any): Promise<IServerRes<IEventClient>> => {
	const response = await apiClient
		.post(`events`, {
      json: values,
    })
		.json<IServerRes<IEventClient>>();

	return response;
};

export default createEvent;