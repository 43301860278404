import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TabsTrigger } from "@radix-ui/react-tabs";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { IconSize } from "../../icon/helpers/iconTypes";
import type { TabItemProps } from "../helpers/tabTypes";

const TabItem: React.FC<TabItemProps> = ({
	tab,
	setPosition,
	isDense = false,
	isDisabled = false,
}) => {
	const ref = useRef<null | HTMLLIElement>(null);
	const [isActive, setIsActive] = useState(false);

	useEffect(() => {
		if (ref.current) {
			// Check the initial state
			setIsActive(ref.current.getAttribute("data-state") === "active");

			// Create an observer to watch for changes in the data-state attribute
			const observer = new MutationObserver(() => {
				setIsActive(ref.current?.getAttribute("data-state") === "active");
			});

			// Start observing the ref element for attribute changes
			observer.observe(ref.current, {
				attributes: true,
				attributeFilter: ["data-state"],
			});

			// Cleanup the observer when the component unmounts or the ref changes
			return () => observer.disconnect();
		}
	}, []);

	return (
		<TabsTrigger
			disabled={isDisabled}
			ref={ref as React.RefObject<HTMLButtonElement>}
			value={tab.id}
			onMouseEnter={() => {
				if (!ref?.current) return;

				const { width } = ref.current.getBoundingClientRect();

				setPosition({
					left: ref.current.offsetLeft,
					width,
					opacity: 1,
				});
			}}
			className={clsx(
				`group/item relative z-10 block cursor-pointer px-3 md:px-4 items-center hover:text-white`,
				isDisabled && "cursor-not-allowed",
				isDense ? "p-0.5 text-sm" : "py-1 text-[0.95rem]",
			)}
		>
			<span className="flex gap-1.5 items-center relative z-10">
				{tab.Icon && (
					<FontAwesomeIcon
						fixedWidth
						icon={tab.Icon}
						size={IconSize.Medium}
								className={clsx("transition duration-300 group-hover/item:text-white", {
									"text-[#8d8f95]":
										!isActive,
								})}
					/>
				)}
				<span className="transition duration-300">{tab.name}</span>
			</span>
			<AnimatePresence>
				{isActive && (
					<motion.span
						className="absolute inset-0 z-0 rounded-md bg-primary"
						initial={{ scale: 0 }}
						animate={{ scale: 1 }}
						exit={{ scale: 0 }}
					/>
				)}
			</AnimatePresence>
		</TabsTrigger>
	);
};

TabItem.displayName = "TabItem";
export default TabItem;
