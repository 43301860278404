import Button from "@/components/ui/button/button";
import {
	ButtonIconSide,
	ButtonType,
	ButtonVariant,
} from "@/components/ui/button/helpers/buttonTypes";
import FormTextInput from "@/components/ui/form/components/formTextInput/formTextInput";
import { Form } from "@/components/ui/form/form";
import { Separator } from "@/components/ui/separator/separator";
import * as t from "@/i18n/paraglide/messages";
import { useUserStore } from "@/store/userStore";
import {
	faArrowRightToBracket,
	faUserPlus,
} from "@fortawesome/pro-duotone-svg-icons";
import { zodResolver } from "@hookform/resolvers/zod";
import { Link } from "@tanstack/react-router";
import { useNavigate } from "@tanstack/react-router";
import { useForm } from "react-hook-form";
import type { z } from "zod";
import { LoginFormSchema, type LoginFormValues } from "./data/loginFormSchema";
import { useSubmitLoginForm } from "./hooks/useSubmitLoginForm";

const LoginForm: React.FC = () => {
	const form = useForm<z.infer<typeof LoginFormSchema>>({
		resolver: zodResolver(LoginFormSchema),
		mode: "onBlur",
	});

	const navigate = useNavigate({ from: "/login" });
	const { mutate, isPending } = useSubmitLoginForm();

	const handleSubmit = async (loginFormValues: LoginFormValues) => {
		mutate(loginFormValues, {
			onSuccess: (response) => {
				useUserStore.getState().setUser(response.data!);
				navigate({
					to: "/app/dashboard",
				});
			},
		});
	};

	return (
		<Form {...form}>
			<form
				onSubmit={form.handleSubmit(handleSubmit)}
				className="w-full flex flex-col"
			>
				<div className="mb-5 space-y-3">
					<FormTextInput
						form={form}
						name="email"
						label={t.email()}
						placeholder={t.email()}
						isRequired
						isDisabled={form.formState.isSubmitting}
					/>
					<FormTextInput
						form={form}
						name="password"
						label="Password"
						placeholder="Password"
						isDisabled={form.formState.isSubmitting}
						isRequired
						hasHideOption
					/>
					<p className="text-right text-[0.8rem] text-muted-foreground">
						<Link to={"/reset-password"} className="link text-foreground">
							{t.forgotPassword()}
						</Link>
					</p>
				</div>
				<Button
					icon={faArrowRightToBracket}
					type={ButtonType.Submit}
					label={t.loginAction()}
					isDisabled={!form.formState.isValid}
					iconSide={ButtonIconSide.Right}
					className="w-full"
				/>
				<Separator className="w-12 self-center my-5 bg-foreground-muted" />
				<Link to="/registration">
					<Button
						variant={ButtonVariant.Outline}
						label={t.registrationAction()}
						iconSide={ButtonIconSide.Right}
						icon={faUserPlus}
						className="w-full"
					/>
				</Link>
			</form>
		</Form>
	);
};

LoginForm.displayName = "LoginForm";
export default LoginForm;
