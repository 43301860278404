import type { IAddress } from "./addressFormTypes";

export interface FormAddress {
	city: {
		name: string;
		value: string;
	};
	street: {
		name: string;
		value: string;
	};
	formattedAddress: {
		name: string;
		value: string;
	};
	streetNumber: {
		name: string;
		value: string;
	};
	postalCode: {
		name: string;
		value: string;
	};
	country: {
		name: {
			name: string;
			value: string;
		};
		code: {
			name: string;
			value: string;
		};
	};
	addition: {
		name: string;
		value: string;
	};
	lat: {
		name: string;
		value: number;
	};
	lng: {
		name: string;
		value: number;
	};
	location: {
		coordinates: {
			name: string;
			value: [number, number];
		};
	};
}

export const formatAddress = (
	addressPathPrefix: string,
	values: IAddress,
): FormAddress => {
	return {
		city: {
			name: `${addressPathPrefix}.city`,
			value: values.city,
		},
		formattedAddress: {
			name: `${addressPathPrefix}.formattedAddress`,
			value: values.formattedAddress,
		},
		street: {
			name: `${addressPathPrefix}.street`,
			value: values.street,
		},
		streetNumber: {
			name: `${addressPathPrefix}.streetNumber`,
			value: values.streetNumber,
		},
		postalCode: {
			name: `${addressPathPrefix}.postalCode`,
			value: values.postalCode,
		},
		country: {
			name: {
				name: `${addressPathPrefix}.country.name`,
				value: values.country.name,
			},
			code: {
				name: `${addressPathPrefix}.country.code`,
				value: values.country.code,
			},
		},
		addition: {
			name: `${addressPathPrefix}.addition`,
			value: values.addition,
		},
		lat: {
			name: `${addressPathPrefix}.lat`,
			value: values.lat,
		},
		lng: {
			name: `${addressPathPrefix}.lng`,
			value: values.lng,
		},
		location: {
			coordinates: {
				name: `${addressPathPrefix}.location.coordinates`,
				value: values.location.coordinates,
			},
		},
	};
};
