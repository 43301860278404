import * as t from "@/i18n/paraglide/messages";
import { memo } from "react";
import {
	type UsageCardType,
	usageCardHeight,
	usageCardMargin,
	usageCardWidth,
} from "../helpers/usageTypes";

const UsageCard: React.FC<UsageCardType> = memo(
	({ image, futureFeature, title, description }) => {
		return (
			<div
				className="relative shrink-0 rounded-2xl shadow-md transition-all"
				style={{
					width: usageCardWidth,
					height: usageCardHeight,
					marginRight: usageCardMargin,
				}}
			>
				<img
					src={`/images/${image}`}
					alt="Usage Card Image"
					className="absolute top-0 left-0 w-full h-full object-cover rounded-2xl"
				/>
				<div className="absolute inset-0 z-20 rounded-2xl bg-gradient-to-b from-black/90 via-black/65 to-black/0 py-3 px-6">
					{futureFeature && (
						<div className="absolute flex w-fit right-3 h-6 bg-accent px-2 rounded-md items-center text-xs uppercase">
							{t.soon()}
						</div>
					)}
					<div className="flex flex-col gap-2 pt-5">
						<p className="text-2xl font-bold">{title}</p>
						<p className="text-lg text-foreground-secondary">{description}</p>
					</div>
				</div>
			</div>
		);
	},
);

UsageCard.displayName = "UsageCard";
export default UsageCard;
