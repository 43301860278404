import FeaturesScreen from "@/components/screens/landingPage/features/featuresScreen";
import * as t from "@/i18n/paraglide/messages";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_landingPageLayout/features")({
	component: FeaturesScreen,
	beforeLoad: () => {
		document.title = `${import.meta.env.VITE_APP_NAME} • ${t.featuresMetaTitle()}`;
	},
});
