import { cn } from "@/lib/utils";
import React from "react";

const TableWrapper = React.forwardRef<
	HTMLTableElement,
	React.HTMLAttributes<HTMLTableElement>
>(({ className, ...props }, ref) => (
	<div className="overflow-auto">
		<table
			ref={ref}
			className={cn(
				"w-full caption-bottom rounded-md bg-[#2B3540] text-sm",
				className,
			)}
			{...props}
		/>
	</div>
));

TableWrapper.displayName = "TableWrapper";
export default TableWrapper;
