import * as t from "@/i18n/paraglide/messages";
import { Page } from "@/store/sidebarState";
import {
	faAddressBook,
	faAnalytics,
	faCalendar,
	faClipboardList,
	faCreditCard,
	faImage,
	faTablet,
	faUsers,
} from "@fortawesome/pro-light-svg-icons";
import { useRouter } from "@tanstack/react-router";
import { UserRole } from "shared-types";
import type { SidebarItem } from "./sidebarTypes";

export const getSidebarItems = (): Array<SidebarItem> => {
	const { routesByPath } = useRouter();
	return [
		{
			link: routesByPath["/app/dashboard"].fullPath,
			page: Page.Dashboard,
			label: t.dashboard(),
			icon: faAnalytics,
			authorizedUserRoles: [UserRole.Admin, UserRole.Employee],
		},
		{
			link: routesByPath["/app/client"].fullPath,
			page: Page.Client,
			label: t.app(),
			icon: faTablet,
			authorizedUserRoles: [UserRole.Admin, UserRole.Employee],
		},
		{
			link: routesByPath["/app/leads"].fullPath,
			page: Page.Leads,
			label: t.leads(),
			icon: faAddressBook,
			authorizedUserRoles: [UserRole.Admin, UserRole.Employee],
		},
		{
			link: routesByPath["/app/forms"].fullPath,
			page: Page.Forms,
			label: t.forms(),
			icon: faClipboardList,
			authorizedUserRoles: [UserRole.Admin, UserRole.Employee],
		},
		{
			link: routesByPath["/app/events"].fullPath,
			page: Page.Events,
			label: t.events(),
			icon: faCalendar,
			authorizedUserRoles: [UserRole.Admin, UserRole.Employee],
		},
		{
			link: routesByPath["/app/content"].fullPath,
			page: Page.Content,
			label: t.content(),
			icon: faImage,
			authorizedUserRoles: [UserRole.Admin, UserRole.Employee],
		},
		{
			link: routesByPath["/app/users"].fullPath,
			page: Page.Users,
			label: t.users(),
			icon: faUsers,
			authorizedUserRoles: [UserRole.Admin],
		},
		{
			link: routesByPath["/app/billing"].fullPath,
			page: Page.Billing,
			label: t.billing(),
			icon: faCreditCard,
			authorizedUserRoles: [UserRole.Admin],
		},
	];
};
