import Button from "@/components/ui/button/button";
import {
	ButtonIconSide,
	ButtonVariant,
} from "@/components/ui/button/helpers/buttonTypes";
import Calendar from "@/components/ui/calendar/calendar";
import { Popover } from "@/components/ui/popover/popover";
import { useLanguage } from "@/providers/languageProvider";
import { faCalendarLines } from "@fortawesome/pro-light-svg-icons";
import { PopoverContent, PopoverTrigger } from "@radix-ui/react-popover";
import { useState } from "react";
import type { FieldValues } from "react-hook-form";
import { FormControl, FormField, FormMessage } from "../../form";
import FormItem from "../formItem/formItem";
import FormLabel from "../formLabel/formLabel";
import type { FormDatePickerProps } from "./helpers/formDatePickerTypes";

function FormDatePicker<T extends FieldValues>({
	label,
	name,
	isRequired,
	form,
	isDisabled,
}: FormDatePickerProps<T>) {
	const [calendarOpen, setCalendarOpen] = useState(false);
	const { control } = form;
	const { locale } = useLanguage();

	return (
		<FormField
			control={control}
			name={name}
			render={({ field, fieldState }) => (
				<FormItem className="flex flex-col">
					<FormLabel>
						{label}
						{isRequired && (
							<span
								className={fieldState.error ? "text-error" : "text-warning"}
							>
								*
							</span>
						)}
					</FormLabel>
					<Popover open={calendarOpen} onOpenChange={setCalendarOpen}>
						<PopoverTrigger asChild>
							<FormControl>
								<Button
									isDisabled={isDisabled}
									variant={ButtonVariant.Input}
									iconSide={ButtonIconSide.Left}
									icon={faCalendarLines}
									label={
										field.value
											? field.value.toLocaleDateString(locale, {
													day: "numeric",
													month: "long",
													year: "numeric",
												})
											: label
									}
									className={`flex w-full justify-start ${field.value && "text-foreground"}`}
								/>
							</FormControl>
						</PopoverTrigger>
						<PopoverContent className="w-auto p-0 bg-background-primary border rounded-md" align="start">
							{/* TODO: Localization & validation so startDate is before endDate*/}
							<Calendar
								mode="single"
								selected={field.value}
								onSelect={(value) => {
									field.onChange(value);
									setCalendarOpen(false);
								}}
								initialFocus
								numberOfMonths={1}
								weekStartsOn={1}
							/>
						</PopoverContent>
					</Popover>
					<FormMessage />
				</FormItem>
			)}
		/>
	);
}

FormDatePicker.displayName = "FormDatePicker";
export default FormDatePicker;
