import type { AvailableLanguageTag } from "@/i18n/paraglide/runtime";
import { useMutation } from "@tanstack/react-query";
import registration from "../api/registration";
import type { RegistrationFormValues } from "../data/registrationFormSchema";

export const useSubmitRegisterForm = () => {
	return useMutation({
		mutationFn: (
			registrationFormValues: RegistrationFormValues & {
				language: AvailableLanguageTag;
			},
		) => registration({ registrationFormValues }),
	});
};
