import { AddressSchema } from "@/components/ui/addressForm/data/addressFormSchema";
import * as t from "@/i18n/paraglide/messages";
import { EventStatus } from "shared-types";
import { z } from "zod";

export const EventSchema = z.object({
	_id: z.string().optional(),
	name: z.string({ message: t.validationRequired({ fieldName: t.name() }) }),
	startDate: z.date().optional(),
	endDate: z.date().optional(),
	address: AddressSchema,
	status: z.nativeEnum(EventStatus),
});

export type Event = z.infer<typeof EventSchema>;
