import { cn } from "@/lib/utils";
import {
	faFileLines,
	faQuestion,
	faSpinnerThird,
	faTrash,
	faUpload,
	faVideo,
} from "@fortawesome/pro-duotone-svg-icons";
import { faImage } from "@fortawesome/pro-light-svg-icons";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { type ChangeEvent, useRef } from "react";
import Button from "../button/button";
import {
	ButtonColor,
	ButtonIconSide,
	ButtonSize,
	ButtonVariant,
} from "../button/helpers/buttonTypes";
import { IconSize } from "../icon/helpers/iconTypes";
import { Icon } from "../icon/icon";
import { FileTypes } from "./helpers/fileInputTypes";

interface FileInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
	name: string;
	fileName?: string;
	fileSize?: string;
	fileType?: string;
	allowedFileTypes: string[];
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
	onRemove?: () => void;
	className?: string;
	uploadProgress?: number;
}

const FileInput: React.FC<FileInputProps> = ({
	name,
	fileName,
	fileSize,
	fileType,
	allowedFileTypes,
	onChange,
	onRemove,
	className,
	uploadProgress,
}) => {
	const fileInputRef = useRef<HTMLInputElement>(null);

	const handleDivClick = () => {
		fileInputRef.current?.click();
	};

	const getPreviewIcon = () => {
		if (fileType?.includes(FileTypes.Image)) {
			return faImage;
		}
		if (fileType?.includes(FileTypes.Video)) {
			return faVideo;
		}
		if (fileType?.includes(FileTypes.File)) {
			return faFileLines;
		}
		return faQuestion;
	};

	return (
		<div className={cn("w-full relative flex grow items-center", className)}>
			<div
				className={clsx(
					"w-full border border-dashed border-slate-600 rounded-md h-32 cursor-pointer transition duration-300 flex flex-col items-center justify-center p-4",
					!fileName && "hover:bg-background-secondary",
				)}
				onClick={handleDivClick}
			>
				<input
					type="file"
					className="hidden"
					name={name}
					ref={fileInputRef}
					accept={allowedFileTypes.join(", ")}
					onChange={onChange}
				/>
				<AnimatePresence>
					{!fileName ? (
						<motion.div
							key="upload-label"
							initial={{ opacity: 0, y: -10 }}
							animate={{ opacity: 1, y: 0 }}
							exit={{ opacity: 0, y: 10, transition: { duration: 0.3 } }}
							transition={{ duration: 0.3 }}
							className="flex flex-col items-center w-full"
							style={{ position: "absolute", width: "100%" }} // Add this line
						>
							<div className="cursor-pointer flex flex-col items-center gap-3 w-full">
								{uploadProgress && uploadProgress > 0 ? (
									<>
										<Icon
											spin
											icon={faSpinnerThird}
											size={IconSize.ExtraLarge}
										/>
										<span className="text-muted-foreground text-md">
											Datei wird hochgeladen.
										</span>
									</>
								) : (
									<>
										<Icon icon={faUpload} size={IconSize.ExtraLarge} />
										<span className="text-muted-foreground text-md">
											Klicke hier um eine Datei auszuwählen.
										</span>
									</>
								)}
							</div>
						</motion.div>
					) : (
						<motion.div
							key="file-info"
							initial={{ opacity: 0, y: 10 }}
							animate={{
								opacity: 1,
								y: 0,
								transition: { delay: 0.3, duration: 0.3 },
							}}
							transition={{ duration: 0.3 }}
							className="flex flex-row items-center w-full gap-4 px-4 absolute w-100"
						>
							<div className="flex flex-col items-center justify-center size-[102.5px] bg-background-tertiary rounded-md">
								<Icon icon={getPreviewIcon()} size={IconSize.Large} />
							</div>
							<div className="flex flex-col h-full space-y-5">
								<div className="flex flex-col gap-0.5">
									<span className="text-md font-semibold">{fileName}</span>
									<span className="text-sm text-muted-foreground">
										{fileSize}
									</span>
								</div>
								<Button
									label="Löschen"
									onClick={onRemove}
									iconSide={ButtonIconSide.Left}
									icon={faTrash}
									variant={ButtonVariant.Outline}
									color={ButtonColor.Error}
									className="text-error w-max"
									size={ButtonSize.ExtraSmall}
								/>
							</div>
						</motion.div>
					)}
				</AnimatePresence>
				{!fileName && (
					<div className="w-11/12 rounded-md h-1.5 bg-slate-600 mx-3 absolute bottom-3">
						<motion.div
							className="bg-primary h-1.5 rounded-md"
							initial={{ width: 0 }}
							animate={{ width: `${uploadProgress}%` }}
							transition={{ ease: "easeOut", duration: 0.5 }}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

FileInput.displayName = "FileInput";
export default FileInput;
