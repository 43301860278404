import type { IServerRes } from "@/lib/responses";
import { type UseMutationResult, useMutation } from "@tanstack/react-query";
import type { IUserInfo } from "shared-types";
import login from "../api/login";
import type { LoginFormValues } from "../data/loginFormSchema";

export const useSubmitLoginForm = (): UseMutationResult<
	IServerRes<IUserInfo>,
	unknown,
	LoginFormValues
> => {
	return useMutation({
		mutationFn: (loginFormValues: LoginFormValues) =>
			login({ loginFormValues }),
	});
};
