import { cn } from "@/lib/utils";
import { faEye, faEyeSlash } from "@fortawesome/pro-light-svg-icons";
import { forwardRef, useState } from "react";
import { IconSize } from "../icon/helpers/iconTypes";
import { Icon } from "../icon/icon";
import type { TextInputProps } from "./helpers/textInputTypes";

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
	(
		{ className, type, icon, hasHideOption = false, isDense = false, ...props },
		ref,
	) => {
		const [isInputVisible, setIsInputVisible] = useState(false);

		if (hasHideOption) {
			type = isInputVisible ? "text" : "password";
		}

		return (
			<div className="w-100 relative flex grow items-center">
				{icon && (
					<div className="absolute flex left-3 top-1/2 -translate-y-1/2 items-center">
						<Icon
							fixedWidth
							className="size-4"
							icon={icon}
							size={isDense ? IconSize.ExtraSmall : IconSize.Medium}
						/>
					</div>
				)}
				<input
					type={type}
					value={props.value}
					className={cn(
						"flex w-full rounded-md border border-input bg-background-tertiary px-3 py-2 text-sm transition duration-300 file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground active:border-border-hover hover:border-border-hover focus:border-border-hover  focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50",
						icon ? "pl-10" : "",
						isDense ? "h-8" : "h-10",
						className,
					)}
					ref={ref}
					{...props}
				/>
				{hasHideOption && (
					<div
						className="absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer flex items-center"
						onClick={() => setIsInputVisible(!isInputVisible)}
						onKeyUp={(e) => {
							if (e.key === "Enter" || e.key === " ") {
								setIsInputVisible(!isInputVisible);
							}
						}}
					>
						{isInputVisible ? (
							<Icon fixedWidth className="size-4" icon={faEyeSlash} />
						) : (
							<Icon fixedWidth className="size-4" icon={faEye} />
						)}
					</div>
				)}
			</div>
		);
	},
);

TextInput.displayName = "TextInput";
export default TextInput;
