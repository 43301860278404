import { IUserInfo, OrganisationStatus, PaymentPlan, UserRole } from "shared-types";
import { z } from "zod";


const UserProfileSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  position: z.string().optional(),
  phone: z.string().optional(),
  mobile: z.string().optional(),
  image: z.string().optional(),
  birthday: z.date().optional(),
});

const UserTokenSchema = z.object({
  id: z.string(),
  token: z.string(),
  revoked: z.boolean().optional(),
  createdAt: z.date(),
  lastUsed: z.date().optional(),
  clientInfo: z.object({
    ip: z.string().optional(),
    userAgent: z.string().optional(),
    deviceType: z.string().optional(),
  }),
});

const UserSecuritySchema = z.object({
  lastActive: z.date(),
  password: z.string(),
  invalidPasswordAttempts: z.number().optional(),
  accountLockedUntil: z.date().optional(),
  resetPasswordToken: z.string().optional(),
  resetPasswordExpire: z.date().optional(),
  activationToken: z.string().optional(),
  activationExpires: z.date().optional(),
  tokens: z.array(UserTokenSchema),
});

const OrganisationSchema = z.object({
  _id: z.string(),
  name: z.string(),
  identifier: z.string(),
  homepage: z.string(),
  paymentPlan: z.nativeEnum(PaymentPlan),
  status: z.nativeEnum(OrganisationStatus),
  users: z.array(z.lazy(() => z.object({ /* Define minimal IUserClient structure here */ }))),
  createdBy: z.lazy(() => z.object({ /* Define minimal IUserInfo structure here */ })),
  updatedBy: z.lazy(() => z.object({ /* Define minimal IUserInfo structure here */ })),
});

const IUserInfoSchema: z.ZodType<IUserInfo> = z.object({
  _id: z.string(),
  email: z.string().email(),
  profile: UserProfileSchema,
  role: z.nativeEnum(UserRole),
  organisation: OrganisationSchema,
});
export default IUserInfoSchema;
