import {
	flexRender,
	getCoreRowModel,
	useReactTable,
} from "@tanstack/react-table";
import { Skeleton } from "../../skeleton/skeleton";
import type { TableLoadingSkeletonProps } from "../helpers/tableTypes";

import { Checkbox } from "../../checkbox/checkbox";
import TableBody from "./tableBody";
import TableCell from "./tableCell";
import TableHead from "./tableHead";
import TableHeader from "./tableHeader";
import TablePagination from "./tablePagination";
import TableRow from "./tableRow";
import TableToolbar from "./tableToolbar";
import TableWrapper from "./tableWrapper";

function TableLoadingSkeleton<T>({
	rowCount,
	columns,
}: TableLoadingSkeletonProps<T>) {
	const table = useReactTable({
		columns,
		data: [],
		getCoreRowModel: getCoreRowModel(),
	});

	return (
		<div className="space-y-4">
			<TableToolbar table={table} />
			<div className="rounded-md border">
				<TableWrapper>
					<TableHeader>
						{table.getHeaderGroups().map((headerGroup, key) => (
							<TableRow key={key}>
								{headerGroup.headers.map((header) => {
									const columnSize =
										header.column.columnDef.size !== 9999
											? `${header.column.columnDef.size}px`
											: "100%";
									return (
										<TableHead
											key={header.id}
											style={{
												width: columnSize,
												minWidth:
													header.column.columnDef.size !== 9999
														? columnSize
														: "",
											}}
										>
											{header.id === "select" ? (
												<Checkbox isDisabled />
											) : header.isPlaceholder ? null : (
												flexRender(
													header.column.columnDef.header,
													header.getContext(),
												)
											)}
										</TableHead>
									);
								})}
							</TableRow>
						))}
					</TableHeader>
					<TableBody>
						<>
							{Array.from({ length: rowCount }, (_, rowIndex) => (
								<TableRow key={rowIndex}>
									{columns.map((column, key) => (
										<TableCell key={key}>
											<Skeleton
												className={`h-5 ${column.size ? `w-[${column.size}px]` : "w-auto"}`}
											/>
										</TableCell>
									))}
								</TableRow>
							))}
						</>
					</TableBody>
				</TableWrapper>
			</div>
			<TablePagination table={table} isLoading />
		</div>
	);
}

TableLoadingSkeleton.displayName = "TableLoadingSkeleton";
export default TableLoadingSkeleton;
