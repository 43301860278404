import { create } from "zustand";

interface SidebarState {
	activePage: Page;
}

export enum Page {
	Dashboard = "Dashboard",
	Events = "Events",
	Leads = "Leads",
	Billing = "Billing",
	Forms = "Forms",
	Content = "Content",
	Client = "Client",
	Users = "Users",
}

export const useSidebarState = create<SidebarState>()(() => ({
	activePage: Page.Dashboard,
}));
