import * as t from "@/i18n/paraglide/messages";
import { z } from "zod";

export const ResetPasswordFormSchema = z.object({
	email: z
		.string({ message: t.validationRequired({ fieldName: t.email() }) })
		.email({
			message: t.validationEmail(),
		}),
});
