const DigitalLeadManagementScreen: React.FC = () => {
	return (
		<>
			<div className="bg-background-tertiary">
				<div className="max-w-7xl mx-auto h-96">
					<div className="flex h-full items-center gap-8 -pt-16">
						<div className="flex flex-col gap-5 w-5/6">
							<span className="text-2xl font-semibold">
								Digitales Lead Management
							</span>
							<span className="text-justify">
								Die manuelle Erfassung von Messekontakten per Papier oder
								Excel-Listen kostet nicht nur Zeit, sondern birgt auch das
								Risiko, wichtige Informationen zu verlieren.
								<br />
								Eine digitale Leadmanagement-Lösung ermöglicht es Ihnen, Daten
								effizient und fehlerfrei zu erfassen und direkt
								weiterzuverarbeiten. So bleiben wertvolle Messeleads nicht
								ungenutzt, und Ihr Vertriebsteam kann sofort mit der
								Lead-Qualifizierung und der Follow-up-Kommunikation beginnen.{" "}
								<br />
								Optimieren Sie Ihre Messe-Datenverarbeitung, steigern Sie Ihre
								Conversion-Rate und machen Sie jeden Messestand-Besucher zu
								einer potenziellen Geschäftsmöglichkeit.
							</span>
						</div>
					</div>
				</div>
			</div>
			<div className="max-w-6xl mx-auto flex gap-20 justify-between">
				<div className="bg-error-1 w-1/2 rounded-md -mt-16 px-10 py-4">
					<div className="flex flex-col gap-10">
						<span className="text-[1.2rem] font-semibold">Das Problem</span>
						<ul className="flex flex-col gap-6 list-disc">
							<li>
								<span className="font-bold">Manueller Aufwand</span> <br />
								Das händische Übertragen von Daten kostet Ihr Team wertvolle
								Ressourcen und Zeit.
							</li>
							<li>
								<span className="font-bold">Fehleranfälligkeit</span> <br />
								Unleserliche Notizen oder verlorene Informationen führen zu
								unvollständigen Datensätzen.
							</li>
							<li>
								<span className="font-bold">Verzögerungen im Follow-up</span>{" "}
								<br />
								Wertvolle Tage vergehen, bevor Leads weiterverarbeitet oder
								kontaktiert werden können.
							</li>
							<li>
								<span className="font-bold">Unprofessionelles Image</span>{" "}
								<br />
								Die papierbasierte Datenerfassung wirkt altmodisch und passt
								nicht zu modernen Unternehmensstandards.
							</li>
						</ul>
					</div>
				</div>
				<div className="bg-success-1 w-1/2 rounded-md -mt-16 px-10 py-4">
					<div className="flex flex-col gap-10">
						<span className="text-[1.2rem] font-semibold">Unsere Lösung</span>
						<ul className="flex flex-col gap-6 list-disc">
							<li>
								<span className="font-bold">Effiziente Datenerfassung</span>{" "}
								<br />
								Erfassen Sie Besucherdaten direkt vor Ort über eine intuitive
								App mit individuell erstellten Formularen
							</li>
							<li>
								<span className="font-bold">Nahtlose Integration</span> <br />
								Übermitteln Sie Daten automatisch an Ihr CRM-System ohne
								manuellen Zwischenschritt.
							</li>
							<li>
								<span className="font-bold">Sofortige Follow-ups</span> <br />
								Kontaktieren Sie potenzielle Kunden unmittelbar nach dem
								Gespräch mit personalisierten E-Mails oder Angeboten..
							</li>
							<li>
								<span className="font-bold">Multimediale Präsentationen</span>{" "}
								<br />
								Zeigen Sie Ihren Leads direkt auf der Messe multimediale
								Inhalte, die im Gespräch relevant sind.
							</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	);
};

DigitalLeadManagementScreen.displayName = "DigitalLeadManagementScreen";
export default DigitalLeadManagementScreen;
