import Button from "@/components/ui/button/button";
import { ButtonVariant, ButtonIconSide } from "@/components/ui/button/helpers/buttonTypes";
import FormItem from "@/components/ui/form/components/formItem/formItem";
import FormLabel from "@/components/ui/form/components/formLabel/formLabel";
import { FormControl, FormField } from "@/components/ui/form/form";
import { FC } from "react";
import {useFormContext } from "react-hook-form";
import { ContentItemType } from "shared-types";
import { getContentItemTypeIconObject } from "./contentTypeCard/helpers/getContentItemTypeIconObject";
import { getContentItemTypeIconColors } from "./contentTypeCard/helpers/getContentItemTypeIconColors";
import * as t from "@/i18n/paraglide/messages";
import { getContentItemTypeLabel } from "./contentTypeCard/helpers/getContentItemTypeLabel";

interface ContentTypeDisplayFieldProps {
  contentType: ContentItemType;
}

const ContentTypeDisplayField: FC<ContentTypeDisplayFieldProps> = ({ contentType }) => {
	const form = useFormContext();

	return (
		<FormField
			control={form.control}
			name={"type"}
			render={() => (
				<FormItem className="flex flex-col gap-1.5">
					<FormLabel>
						{t.contentItemType()}
					</FormLabel>
							<FormControl>
								<Button
									isDisabled
									variant={ButtonVariant.Input}
									iconSide={ButtonIconSide.Left}
									icon={getContentItemTypeIconObject(contentType)}
									duotoneIconColors={getContentItemTypeIconColors(contentType)}
									label={getContentItemTypeLabel(contentType)}
									className={`flex w-full justify-start`}
								/>
							</FormControl>
				</FormItem>
			)}
		/>
	);
};

ContentTypeDisplayField.displayName = "ContentTypeDisplayField";
export default ContentTypeDisplayField;
