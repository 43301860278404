import { IconSize } from "@/components/ui/icon/helpers/iconTypes";
import { Icon } from "@/components/ui/icon/icon";
import type { FormComponentCardProps } from "../helpers/formFeatureComponentGridTypes";

const ComponentCard: React.FC<FormComponentCardProps> = ({
	title,
	description,
	icon,
	colors,
}) => {
	return (
		<div
			className={
				"rounded-2xl w-full h-48 p-4 overflow-hidden bg-background-tertiary border  relative z-20 cursor-default"
			}
		>
			<div className={"flex gap-4 rounded-md p-4"}>
				<div>
					<Icon
						fixedWidth
						icon={icon}
						size={IconSize.ExtraLarge}
						primaryColor={colors.primary}
						secondaryColor={colors.secondary}
					/>
				</div>
				<div className="flex flex-col gap-2.5">
					<div className="text-lg font-semibold">{title}</div>
					<div className="text-md text-foreground-secondary">{description}</div>
				</div>
			</div>
		</div>
	);
};

ComponentCard.displayName = "ComponentCard";
export default ComponentCard;
