import apiClient from "@/lib/api";
import type { IServerRes } from "@/lib/responses";
import { useQuery } from "@tanstack/react-query";

// TODO: Add locales
export const fetchPlacesAutocomplete = async ({
	searchValue,
	t,
}: {
	searchValue: string;
	t: "de-DE" | "en-EN";
}): Promise<IServerRes<Array<{ description: string; place_id: string }>>> => {
	return apiClient
		.get("places/autocompletion", {
			searchParams: {
				searchValue,
				languageKey: t,
			},
		})
		.json<IServerRes<Array<{ description: string; place_id: string }>>>();
};

/**
 * This hook will fetch google place autocomplete api
 * @params searchValue is the input value where we fetch the address
 * @returns autocompleted addresses
 */
export const useFetchPlacesAutocomplete = ({
	searchValue,
	t,
}: { searchValue: string; t: "de-DE" | "en-EN" }) => {
	return useQuery({
		queryKey: ["placeAutocompletion", searchValue, t],
		queryFn: () => fetchPlacesAutocomplete({ searchValue, t }),
		refetchOnWindowFocus: false,
		enabled: !!t && !!searchValue,
	});
};
