import { ButtonProps } from "@/components/ui/button/helpers/buttonTypes";
import { ContentItemType } from "shared-types";


export const getContentItemTypeIconColors = (type: ContentItemType): ButtonProps["duotoneIconColors"] => {

  console.log(type);

  switch (type) {
    case ContentItemType.Folder:
      return {
          primary: "sea-green-9",
          secondary: "sea-green-6",
      };
    case ContentItemType.Image:
      return {
          primary: "cerise-pink",
          secondary: "cerise-pink-6",
      };
    case ContentItemType.Document:
      return {
          primary: "coral-9",
          secondary: "coral-6",
      };
    case ContentItemType.Video:
      return {
          primary: "khaki-9",
          secondary: "khaki-6",
      };
    default:
      return {
          primary: "gray-9",
          secondary: "gray-6",
      };
  }
};
