import type { IContentItemClientWithCollapse } from "@/components/screens/app/content/data/contentFormSchema";
import { create } from "zustand";

interface ContentItemState {
	contentItem: IContentItemClientWithCollapse | null;
	contentItemPath: string;
	setContentItem: (contentItem: IContentItemClientWithCollapse) => void;
	setContentItemPath: (contentItemPath: string) => void;
	removeContentItem: () => void;
}

export const useContentItemStore = create<ContentItemState>((set) => ({
	contentItem: null,
	contentItemPath: "",
	setContentItem: (contentItem) => {
		set({ contentItem });
	},
	setContentItemPath: (contentItemPath) => {
		set({ contentItemPath });
	},
	removeContentItem: () => {
		set({ contentItem: null });
	},
}));
