import SectionWrapper from "@/components/layout/sectionWrapper/sectionWrapper";
import Button from "@/components/ui/button/button";
import { ButtonVariant } from "@/components/ui/button/helpers/buttonTypes";
import { IconSize } from "@/components/ui/icon/helpers/iconTypes";
import { Icon } from "@/components/ui/icon/icon";
import * as t from "@/i18n/paraglide/messages";
import { faExclamationCircle } from "@fortawesome/pro-duotone-svg-icons";
import { Link } from "@tanstack/react-router";
import type React from "react";
import { useState } from "react";
import PriceCard from "./components/priceCard";
import PricingToggle from "./components/pricingToggle";
import { EnterprisePlan, FreePlan, ProPlan } from "./helpers/pricingData";
import { BillingPeriod } from "./helpers/pricingTypes";

const Pricing: React.FC = () => {
	const [billingPeriod, setBillingPeriod] = useState<BillingPeriod>(
		BillingPeriod.Annually,
	);

	return (
		<SectionWrapper title={t.pricing()} subtitle={t.pricingSubtitle()}>
			<div className="mx-auto max-w-7xl items-center flex flex-col gap-6 ">
				<div className="relative border bg-coral-1 border-coral-6 p-4 rounded-md gap-2 flex items-center">
					<Icon size={IconSize.Large} icon={faExclamationCircle} />
					<span className="tracking-wide">
						Nutzen Sie den 30-tätigen Testzeitraum um unser Produkt risikofrei
						testen.
					</span>
				</div>
				<PricingToggle
					billingPeriod={billingPeriod}
					setBillingPeriod={setBillingPeriod}
				/>
				<div className="flex flex-col md:flex-row gap-4 justify-center w-full">
					<PriceCard
						tier="Basic"
						price={billingPeriod === BillingPeriod.Annually ? "79€" : "99€"}
						billingPeriod={billingPeriod}
						pricePeriod="/m"
						bestFor={t.basicPlanBestFor()}
						Button={
							<Link to="/registration">
								<Button className="w-full" label={t.getStarted()} />
							</Link>
						}
						benefits={FreePlan}
					/>
					<PriceCard
						tier="Pro"
						price={billingPeriod === BillingPeriod.Annually ? "119€" : "149€"}
						pricePeriod="/m"
						billingPeriod={billingPeriod}
						bestFor={t.proPlanBestFor()}
						Button={
							<Link to="/registration">
								<Button className="w-full" label={t.getStarted()} />
							</Link>
						}
						benefits={ProPlan}
					/>
					<PriceCard
						tier="Enterprise"
						price={t.custom()}
						billingPeriod={billingPeriod}
						bestFor={t.enterprisePlanBestFor()}
						Button={
							<Link to="/contact">
								<Button
									variant={ButtonVariant.Outline}
									className="w-full"
									label={t.contact()}
								/>
							</Link>
						}
						benefits={EnterprisePlan}
					/>
				</div>
			</div>
		</SectionWrapper>
	);
};

Pricing.displayName = "Pricing";
export default Pricing;
