import apiClient from "@/lib/api";
import type { IServerRes } from "@/lib/responses";
import { useQuery } from "@tanstack/react-query";
import type { TAddressWithoutLocation } from "../helpers/addressFormTypes";

export const fetchAddressDetails = async (placeId: string) => {
	return apiClient
		.get("places/details", { searchParams: { placeId } })
		.json<IServerRes<TAddressWithoutLocation>>();
};

/**
 * This hook will fetch the address details from google places api
 * @params placeId is the google id of the place
 * @returns address details
 */
export const useFetchPlaceDetails = (placeId: string) => {
	return useQuery({
		queryKey: ["placeDetails", placeId],
		queryFn: () => fetchAddressDetails(placeId),
		refetchOnWindowFocus: false, // prevent from refetching
		enabled: !!placeId,
		gcTime: 0,
	});
};
