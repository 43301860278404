import * as t from '@/i18n/paraglide/messages';
import { z } from 'zod';

export const LoginFormSchema = z.object({
	email: z.string({ message: t.validationRequired({ fieldName: t.email() }) }),
	password: z.string({ message: t.validationRequired({ fieldName: t.password() }) }).min(8, {
		message: t.validationMinLength({ fieldName: t.password(), length: 8 }),
	}),
});

export type LoginFormValues = z.infer<typeof LoginFormSchema>;
