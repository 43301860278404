import Cookies from "js-cookie";
import ky from "ky";
import type { IUserInfo } from "shared-types";
import { create } from "zustand";
import { persist } from "zustand/middleware";

interface UserState {
	user: IUserInfo | null;
	setUser: (user: IUserInfo) => void;
	fetchUser: () => Promise<void>;
}

export const useUserStore = create<UserState>()(
	persist(
		(set) => ({
			user: null,
			setUser: (user) => set({ user }),
			fetchUser: async () => {
				try {
					const user = await ky
						.get("/auth/user-info", { credentials: "include" })
						.json<IUserInfo>();
					set({ user });
				} catch (error) {
					set({ user: null });

					Cookies.remove("accessToken");
					Cookies.remove("refreshToken");
				}
			},
		}),
		{
			name: "user-storage",
			partialize: (state) => ({ user: state.user }),
		},
	),
);
