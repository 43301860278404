import apiClient from '@/lib/api';
import type { IServerRes } from '@/lib/responses';
import type { IUserInfo } from 'shared-types';
import type { LoginFormValues } from '../data/loginFormSchema';

const login = async ({
	loginFormValues,
}: { loginFormValues: LoginFormValues }): Promise<IServerRes<IUserInfo>> => {
	return await apiClient
		.post('auth/login', {
			json: loginFormValues,
		})
		.json<IServerRes<IUserInfo>>();
};

export default login;
