import ScreenWrapper from "@/components/layout/app/screenWrapper/screenWrapper";
import Button from "@/components/ui/button/button";
import {
	ButtonIconSide,
	ButtonVariant,
} from "@/components/ui/button/helpers/buttonTypes";
import TableErrorSkeleton from "@/components/ui/table/components/tableErrorSkeleton";
import TableLoadingSkeleton from "@/components/ui/table/components/tableLoadingSkeleton";
import Table from "@/components/ui/table/table";
import * as t from "@/i18n/paraglide/messages";
import { useTableActionBarState } from "@/store/tableActionBarState";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { useCallback, useEffect, useState } from "react";
import { ContentStatus, type IContentClient } from "shared-types";
import ContentCreateDialog from "../dialogs/contentCreateDialog";
import { useSelectedContentTableRowIds } from "../helpers/selectedContentTableRowIdsStore";
import { queryAllContent } from "../hooks/useFetchAllContent";
import useGetContentTableColumns from "../hooks/useGetContentTableColumns";
import useDeleteContent from "../hooks/useDeleteContent";
import { c } from "node_modules/vite/dist/node/types.d-aGj9QkWt";

const ContentListScreen: React.FC = () => {
	const columns = useGetContentTableColumns();
	const { clearContentRowIds, contentRowIds } = useSelectedContentTableRowIds();
	const navigate = useNavigate({ from: "/app/content" });

	const [isOpen, setIsOpen] = useState(false);

	const handleRowClick = useCallback((content: IContentClient) => {
		navigate({
			to: `/app/content/${content._id}`,
		});
	}, []);

	const { mutate: deleteContent } = useDeleteContent(clearContentRowIds);

	useEffect(() => {
		return () => {
			clearContentRowIds();
			useTableActionBarState.setState({ isOpen: false });
		};
	}, [clearContentRowIds]);

	const {
		data: response,
		isLoading,
		isError,
	} = useSuspenseQuery(queryAllContent);

	return (
		<>
			<ScreenWrapper
				title={t.content()}
				breadcrumbs={[]}
				actionButtons={[
					<Button
						variant={ButtonVariant.Fill}
						icon={faPlus}
						iconSide={ButtonIconSide.Left}
						label={t.add()}
						isResponsive
						onClick={() => setIsOpen(true)}
					/>,
				]}
			>
				{isLoading && (
					<TableLoadingSkeleton<IContentClient>
						columns={columns}
						rowCount={10}
					/>
				)}

				{!isLoading && (isError || !response?.success) && (
					<TableErrorSkeleton<IContentClient> columns={columns} />
				)}

				{!isLoading && !isError && response?.success && (
					<Table<IContentClient>
						columns={columns}
						data={response?.data}
						onRowClick={handleRowClick}
						onDelete={() => deleteContent({ contentIds: contentRowIds })}
						onDuplicate={() => {}}
						tableColumnFilters={[
							{
								accessorKey: "status",
								label: t.status(),
								options: [
									{ label: t.active(), value: ContentStatus.Active },
									{ label: t.inactive(), value: ContentStatus.Inactive },
								],
							},
						]}
					/>
				)}
			</ScreenWrapper>
			<ContentCreateDialog isOpen={isOpen} setIsOpen={setIsOpen} />
		</>
	);
};

ContentListScreen.displayName = "ContentListScreen";
export default ContentListScreen;
