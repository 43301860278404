import ContentDetailScreen from "@/components/screens/app/content/contentDetail/contentDetailScreen";
import { queryOneContent } from "@/components/screens/app/content/hooks/useFetchOneContent";
import * as t from "@/i18n/paraglide/messages";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_appLayout/app/content/$contentId")({
	component: ContentDetailScreen,
	loader: ({ context: { queryClient }, params }) =>
		queryClient.ensureQueryData(
			queryOneContent({ contentId: params.contentId }),
		),
	beforeLoad: () => {
		document.title = `${import.meta.env.VITE_APP_NAME} • ${t.contentMetaTitle()}`;
	},
});
