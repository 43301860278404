import useDebounce from "@/lib/useDebounce";
import { useFetchPlacesAutocomplete } from "../api/useFetchPlaceAutocompletion";

const useAddressCompletion = (address: string) => {
	const debouncedSearchValue = useDebounce(address, 500);
	const { data: addresses } = useFetchPlacesAutocomplete({
		searchValue: debouncedSearchValue,
		t: "de-DE",
	});

	return { addresses };
};

export default useAddressCompletion;
