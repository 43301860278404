import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import type { TAddressWithoutLocation } from "../helpers/addressFormTypes";
import type { FormAddress } from "../helpers/formatAddress";

interface Props {
	formAddress: FormAddress;
}

const useAddressSetters = ({ formAddress }: Props) => {
	const { setValue } = useFormContext();

	const setAddressFieldValues = useCallback(
		(dataPlaceDetails: TAddressWithoutLocation) => {
			setValue(formAddress.city.name, dataPlaceDetails.city);
			setValue(formAddress.lat.name, dataPlaceDetails.lat);
			setValue(formAddress.lng.name, dataPlaceDetails.lng);
			setValue(formAddress.location.coordinates.name, [
				dataPlaceDetails.lng,
				dataPlaceDetails.lat,
			]);
			setValue(
				formAddress.formattedAddress.name,
				dataPlaceDetails.formattedAddress,
			);
			setValue(formAddress.street.name, dataPlaceDetails.street);
			setValue(formAddress.streetNumber.name, dataPlaceDetails.streetNumber);
			setValue(formAddress.postalCode.name, dataPlaceDetails.postalCode);
			setValue(formAddress.country.name.name, dataPlaceDetails.country.name);
			setValue(formAddress.country.code.name, dataPlaceDetails.country.code);
		},
		[
			formAddress.city.name,
			formAddress.country.code.name,
			formAddress.country.name.name,
			formAddress.formattedAddress.name,
			formAddress.lat.name,
			formAddress.lng.name,
			formAddress.location.coordinates.name,
			formAddress.postalCode.name,
			formAddress.street.name,
			formAddress.streetNumber.name,
			setValue,
		],
	);

	const resetAddressFields = useCallback(() => {
		setValue(formAddress.formattedAddress.name, "");
		setValue(formAddress.street.name, "");
		setValue(formAddress.lat.name, "");
		setValue(formAddress.lng.name, "");
		setValue(formAddress.location.coordinates.name, [0, 0]);
		setValue(formAddress.streetNumber.name, "");
		setValue(formAddress.city.name, "");
		setValue(formAddress.postalCode.name, "");
		setValue(formAddress.country.name.name, "");
		setValue(formAddress.country.code.name, "");
		setValue(formAddress.addition.name, "");
	}, [
		formAddress.addition.name,
		formAddress.city.name,
		formAddress.country.code.name,
		formAddress.country.name.name,
		formAddress.formattedAddress.name,
		formAddress.lat.name,
		formAddress.lng.name,
		formAddress.location.coordinates.name,
		formAddress.postalCode.name,
		formAddress.street.name,
		formAddress.streetNumber.name,
		setValue,
	]);

	return {
		setAddressFieldValues,
		resetAddressFields,
	};
};

export default useAddressSetters;
