import { motion, useTransform } from "framer-motion";
import type { ContentCarouselItemProps } from "../helpers/appFeatureCarouselTypes";

const AppFeatureCarouselCard: React.FC<ContentCarouselItemProps> = ({
	scrollYProgress,
	position,
	numItems,
}) => {
	const stepSize = 1 / numItems;
	const end = stepSize * position;
	const start = end - stepSize;

	const opacity = useTransform(scrollYProgress, [start, end], [1, 0]);
	const scale = useTransform(scrollYProgress, [start, end], [1, 0.75]);

	return (
		<motion.div
			style={{
				opacity,
				scale,
			}}
			className="grid h-80 w-full shrink-0 place-content-center rounded-2xl bg-background-tertiary"
		>
			<img
				className="h-80 rounded-md"
				src="/images/placeholder.webp"
				alt="Image"
			/>
		</motion.div>
	);
};

AppFeatureCarouselCard.displayName = "AppFeatureCarouselCard";
export default AppFeatureCarouselCard;
