import apiClient from "@/lib/api";
import { QueryKeys } from "@/lib/enums/enums";
import type { IServerRes, IServerResArray } from "@/lib/responses";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import type { IUserClient } from "shared-types";

const useInviteUser = () => {
	const queryClient = useQueryClient();

	return useMutation<IServerRes<IUserClient>, Error, IUserClient>({
		mutationFn: async (
			values: IUserClient,
		): Promise<IServerRes<IUserClient>> => {
			return apiClient
				.post(`user/create`, {
					json: values,
				})
				.json();
		},
		onMutate: async (values) => {
			await queryClient.cancelQueries({ queryKey: [QueryKeys.Users] });

			const previousUsers = queryClient.getQueryData<
				IServerResArray<IUserClient[]>
			>([QueryKeys.Users]);

			if (previousUsers) {
				queryClient.setQueryData(
					[QueryKeys.Users],
					(old: IServerResArray<IUserClient[]> | undefined) => {
						if (!old) {
							return {
								count: 1,
								data: [{ ...values, _id: "temp" }],
							};
						}

						return {
							...old,
							count: old.count + 1,
							data: [...old.data, { ...values, _id: "temp" }],
						};
					},
				);
			} else {
				queryClient.setQueryData([QueryKeys.Users], {
					count: 1,
					data: [{ ...values, _id: "temp" }],
				});
			}

			return { previousUsers };
		},
		onSuccess: (result) => {
			queryClient.setQueryData(
				[QueryKeys.Users],
				(old: IServerResArray<IUserClient[]>) => {
					const updatedData = old.data.map((user: IUserClient) =>
						user._id === "temp" ? result.data : user,
					);
					return {
						...old,
						data: updatedData,
					};
				},
			);

			toast.success(`User ${result.data.name} created`, {
				// TODO: Ensure theme handling is aligned with your application's context
				theme: "dark",
			});
		},
		onError: (error, values, context) => {
			const ctx = context as {
				previousUsers?: IServerResArray<IUserClient[]>;
			};

			if (ctx?.previousUsers) {
				const previousUserIds = ctx.previousUsers.data.map((user) => user._id);
				queryClient.setQueryData(
					[QueryKeys.Users],
					(old: IServerResArray<IUserClient[]>) => {
						const updatedData = old.data.filter(
							(user: IUserClient) => !previousUserIds.includes(user._id),
						);
						return {
							...old,
							count: old.count - 1,
							data: updatedData,
						};
					},
				);
			}

			toast.error((error as Error).message, {
				// TODO: Ensure theme handling is aligned with your application's context
				theme: "dark",
			});
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: [QueryKeys.Users] });
		},
	});
};

export default useInviteUser;
