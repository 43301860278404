import api from "@/lib/api";
import { QueryKeys } from "@/lib/enums/enums";
import type { IServerRes } from "@/lib/responses";
import type { IEventClient } from "shared-types";

/**
 * @description Delete multiple events by their IDs.
 *
 * @param {Object} params - The parameters for the delete request.
 * @param {string} params.eventIds - Array of event IDs to be deleted.
 *
 * @returns {Promise<IServerRes>} A promise that resolves to a server response.
 *
 * @throws {Error} Throws an error if the delete request fails.
 */
export const deleteEvents = async ({
	eventIds,
}: { eventIds: Array<IEventClient["_id"]> }): Promise<IServerRes> => {
	const response: IServerRes = await api
		.delete(`${QueryKeys.Events}`, {
			json: eventIds,
		})
		.json<IServerRes>();
	return response;
};

export default deleteEvents;
