import * as t from "@/i18n/paraglide/messages";
import {
	faLocationCrosshairs,
	faMapLocationDot,
} from "@fortawesome/pro-duotone-svg-icons";
import { faLocationDot } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import type { FieldValues, Path, UseFormReturn } from "react-hook-form";
import Button from "../button/button";
import { ButtonIconSide, ButtonVariant } from "../button/helpers/buttonTypes";
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
} from "../command/command";
import FormItem from "../form/components/formItem/formItem";
import FormLabel from "../form/components/formLabel/formLabel";
import FormTextInput from "../form/components/formTextInput/formTextInput";
import { FormControl, FormField, FormMessage } from "../form/form";
import { IconSize } from "../icon/helpers/iconTypes";
import { Icon } from "../icon/icon";
import { Popover, PopoverContent, PopoverTrigger } from "../popover/popover";
import { formatAddress } from "./helpers/formatAddress";
import useAddressCompletion from "./hooks/useAddressCompletion";
import useAddressDetails from "./hooks/useAddressDetails";

interface AddressFormProps<T extends FieldValues> {
	addressPathPrefix?: string;
	form: UseFormReturn<T>;
}

function AddressForm<T extends FieldValues>({
	addressPathPrefix = "address",
	form,
}: AddressFormProps<T>) {
	const [open, setOpen] = useState(false);

	const formAddress = formatAddress(
		addressPathPrefix,
		form.getValues(addressPathPrefix as Path<T>),
	);

	const [placeId, setPlaceId] = useState("");
	const [value, setValue] = useState("");

	const [searchValue, setSearchValue] = useState("");

	const { addresses } = useAddressCompletion(searchValue);

	const _ = useAddressDetails({ formAddress, placeId, setPlaceId });

	return (
		<div className="flex flex-col gap-8">
			<div className="flex w-full flex-col gap-5">
				<div className="flex gap-5">
					{/* Street */}
					<div className="w-10/12">
						<FormField
							name={`${addressPathPrefix}.street` as Path<T>}
							control={form.control}
							render={() => (
								<FormItem>
									<FormLabel>{t.addressStreet()}</FormLabel>
									<FormControl>
										<Popover open={open} onOpenChange={setOpen}>
											<PopoverTrigger asChild>
												<Button
													variant={ButtonVariant.Input}
													label={
														formAddress.formattedAddress.value ||
														t.addressStreet()
													}
													role="combobox"
													aria-expanded={open}
													className={`flex w-full justify-between ${formAddress.formattedAddress.value && "text-foreground"}`}
													icon={faLocationCrosshairs}
													iconSide={ButtonIconSide.Right}
												/>
											</PopoverTrigger>
											<PopoverContent className="w-48 p-0">
												<Command>
													<CommandInput
														placeholder="Search Address..."
														value={searchValue}
														onChangeCapture={(e) =>
															setSearchValue(
																(e.target as HTMLInputElement).value,
															)
														}
													/>
													{!addresses && (
														<CommandEmpty>No address found.</CommandEmpty>
													)}
													{addresses?.data && addresses?.data.length > 0 && (
														<>
															<CommandGroup>
																{/* TODO: type address */}
																{addresses.data.map((address: any) => (
																	<CommandItem
																		key={address.place_id}
																		onSelect={(currentValue) => {
																			setValue(
																				currentValue === value
																					? ""
																					: currentValue,
																			);
																			setOpen(false);
																		}}
																	>
																		<div
																			className="flex items-center justify-center gap-2"
																			onClick={() => {
																				setPlaceId(address.place_id);
																			}}
																		>
																			<FontAwesomeIcon
																				icon={faLocationDot}
																				size={IconSize.Small}
																			/>
																			{address.description}
																		</div>
																	</CommandItem>
																))}
															</CommandGroup>
														</>
													)}
												</Command>
											</PopoverContent>
										</Popover>
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>
					</div>
					{/* streetNumber */}
					<div className="w-2/12">
						<FormTextInput
							name={`${addressPathPrefix}.streetNumber` as Path<T>}
							form={form}
							placeholder="Nr."
							label={t.addressStreetNumber()}
							isDisabled
						/>
					</div>
				</div>
				<div className="flex flex-row gap-5">
					{/* PostalCode */}
					<div className="w-3/12">
						<FormTextInput
							name={`${addressPathPrefix}.postalCode` as Path<T>}
							form={form}
							placeholder="Postleitzahl"
							label={t.addressPostalCode()}
							isDisabled
						/>
					</div>
					{/* City */}
					<div className="w-5/12">
						<FormTextInput
							name={`${addressPathPrefix}.city` as Path<T>}
							form={form}
							placeholder="Stadt"
							label={t.addressCity()}
							isDisabled
						/>
					</div>
					{/* Country */}
					<div className="w-4/12">
						<FormTextInput
							name={`${addressPathPrefix}.country.name` as Path<T>}
							form={form}
							placeholder="Land"
							label={t.addressCountry()}
							isDisabled
						/>
					</div>
				</div>
				{/* addition */}
				<FormTextInput
					name={`${addressPathPrefix}.addition` as Path<T>}
					placeholder="Adresszusatz"
					label={t.addressAdditionalInfo()}
					form={form}
					isDisabled={form.formState.isSubmitting}
				/>
			</div>
			<div className="aspect-square flex h-[250px] items-center justify-center rounded-md bg-background-tertiary border">
				<Icon size="4x" fixedWidth icon={faMapLocationDot} />
			</div>
		</div>
	);
}

export default AddressForm;
