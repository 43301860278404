import Button from "@/components/ui/button/button";
import { ButtonVariant } from "@/components/ui/button/helpers/buttonTypes";
import MenuButton from "@/components/ui/menuButton/menuButton";
import { useNavbarState } from "@/store/navbarState";
import {
	faBell,
	faBellOn,
	faCalendarLines,
} from "@fortawesome/pro-light-svg-icons";
import { Link } from "@tanstack/react-router";
import { AnimatePresence, motion } from "framer-motion";
import AppSidebarMobile from "../../sidebar/sidebarMobile/sidebarMobile";
import {
	mobileNavbarAnimation,
	mobileNavbarTransition,
} from "./helpers/navbarAnimations";

/**
 * Represents the application's mobile navbar.
 *
 * This component is designed to be used in the application layout. It encapsulates
 * the logic and presentation for the mobile navbar.
 *
 * @component
 * @returns {React.ReactElement} The mobile application's navbar.
 */
const NavbarMobile: React.FC = () => {
	// TODO: Implement notification center
	const hasNewNotification = true;
	const isOpen = useNavbarState((state) => state.isOpen);

	return (
		<>
			<div className="flex h-20 border-b px-4 sm:hidden">
				<div className="flex w-full content-center items-center justify-between">
					{/* Menu Button */}
					<div className="flex items-center">
						<MenuButton
							onClick={() => {
								useNavbarState.setState({ isOpen: !isOpen });
							}}
							isOpen={isOpen}
						/>
					</div>
					<div className="flex shrink-0 items-center justify-center">
					<img src="/images/midlead_logo_long_white.svg" alt="logo" className="w-32" />
			</div>
					<div className="flex gap-3.5">
						<Link to="/">
							<Button variant={ButtonVariant.Outline} icon={faCalendarLines} />
						</Link>
						{/*<AvatarSelect />*/}
					</div>
				</div>
			</div>
			{/* Menu */}
			<AnimatePresence>
				{isOpen && (
					<motion.aside
						className="absolute z-10 mt-px flex h-[calc(100vh-80px)] w-full flex-col bg-background px-4 py-6 sm:hidden"
						initial="closed"
						animate="open"
						exit="closed"
						variants={mobileNavbarAnimation}
						transition={mobileNavbarTransition}
					>
						<AppSidebarMobile />
					</motion.aside>
				)}
			</AnimatePresence>
		</>
	);
};

export default NavbarMobile;
