import * as t from "@/i18n/paraglide/messages";

export enum LanguageCode {
	EN = "en",
	DE = "de",
	PL = "pl",
	FR = "fr",
	ES = "es",
	IT = "it",
}

export interface Language {
	name: string;
	locale: LanguageCode;
}

export interface Languages {
	[key: string]: Language;
}

export const appLanguages: Languages = {
	[LanguageCode.EN]: { name: t.english(), locale: LanguageCode.EN },
	[LanguageCode.DE]: { name: t.german(), locale: LanguageCode.DE },
	[LanguageCode.PL]: { name: t.polnish(), locale: LanguageCode.PL },
	[LanguageCode.ES]: { name: t.spanish(), locale: LanguageCode.ES },
	[LanguageCode.IT]: { name: t.italian(), locale: LanguageCode.IT },
	[LanguageCode.FR]: { name: t.french(), locale: LanguageCode.FR },
};
