import { QueryKeys } from "@/lib/enums/enums";
import {
	keepPreviousData,
	queryOptions,
	useQuery,
} from "@tanstack/react-query";
import fetchAllContent from "../api/fetchAllContent";

export const useFetchAllContent = () => {
	return useQuery({
		queryKey: [QueryKeys.Content],
		queryFn: () => fetchAllContent(),
		placeholderData: keepPreviousData,
	});
};

export const queryAllContent = queryOptions({
	queryKey: [QueryKeys.Content],
	queryFn: () => fetchAllContent(),
	placeholderData: keepPreviousData,
});
