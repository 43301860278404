import { useEffect } from "react";
import { useFetchPlaceDetails } from "../api/useFetchPlaceDetails";
import type { FormAddress } from "../helpers/formatAddress";
import useAddressSetters from "./useAddressSetters";

interface IUseGooglePlaceDetails {
	formAddress: FormAddress;
	placeId: string;
	setPlaceId: React.Dispatch<React.SetStateAction<string>>;
}

const useAddressDetails = ({
	formAddress,
	placeId,
	setPlaceId,
}: IUseGooglePlaceDetails) => {
	const { setAddressFieldValues } = useAddressSetters({
		formAddress,
	});

	const {
		data: dataPlaceDetails,
		isSuccess,
		isLoading,
	} = useFetchPlaceDetails(placeId);

	useEffect(() => {
		if (placeId && isSuccess && dataPlaceDetails.data) {
			setAddressFieldValues(dataPlaceDetails.data);
			setPlaceId("");
		}
	}, [dataPlaceDetails, isSuccess, placeId, setPlaceId, setAddressFieldValues]);

	return { isLoading };
};

export default useAddressDetails;
