import apiClient from "@/lib/api";
import { QueryKeys } from "@/lib/enums/enums";
import type { IServerRes, IServerResArray } from "@/lib/responses";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import type { IEventClient } from "shared-types";
import createEvent from "../api/createEvent";

const useCreateEvent = () => {
	const queryClient = useQueryClient();

	return useMutation<IServerRes<IEventClient>, Error, IEventClient>({
		mutationFn: createEvent,
		onMutate: async (values) => {
			await queryClient.cancelQueries({ queryKey: [QueryKeys.Events] });

			const previousEvents = queryClient.getQueryData<
				IServerResArray<IEventClient[]>
			>([QueryKeys.Events]);

			if (previousEvents) {
				queryClient.setQueryData(
					[QueryKeys.Events],
					(old: IServerResArray<IEventClient[]> | undefined) => {
						if (!old) {
							return {
								count: 1,
								data: [{ ...values, _id: "temp" }],
							};
						}

						return {
							...old,
							count: old.count + 1,
							data: [...old.data, { ...values, _id: "temp" }],
						};
					},
				);
			} else {
				queryClient.setQueryData([QueryKeys.Events], {
					count: 1,
					data: [{ ...values, _id: "temp" }],
				});
			}

			return { previousEvents };
		},
		onSuccess: (result) => {
			queryClient.setQueryData(
				[QueryKeys.Events],
				(old: IServerResArray<IEventClient[]>) => {
					const updatedData = old.data.map((event: IEventClient) =>
						event._id === "temp" ? result.data : event,
					);
					return {
						...old,
						data: updatedData,
					};
				},
			);

			toast.success(`Event ${result.data.name} created`, {
				// TODO: Ensure theme handling is aligned with your application's context
				theme: "dark",
			});
		},
		onError: (error, values, context) => {
			const ctx = context as {
				previousEvents?: IServerResArray<IEventClient[]>;
			};

			if (ctx?.previousEvents) {
				const previousEventIds = ctx.previousEvents.data.map(
					(event) => event._id,
				);
				queryClient.setQueryData(
					[QueryKeys.Events],
					(old: IServerResArray<IEventClient[]>) => {
						const updatedData = old.data.filter(
							(event: IEventClient) => !previousEventIds.includes(event._id),
						);
						return {
							...old,
							count: old.count - 1,
							data: updatedData,
						};
					},
				);
			}

			toast.error((error as Error).message, {
				// TODO: Ensure theme handling is aligned with your application's context
				theme: "dark",
			});
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: [QueryKeys.Events] });
		},
	});
};

export default useCreateEvent;
