import { cn } from "@/lib/utils";
import React from "react";

const TableHeader = React.forwardRef<
	HTMLTableSectionElement,
	React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
	<thead
		ref={ref}
		className={cn(
			"[&_tr]:border-b [&_tr]:hover:bg-transparent [&_th:last-child]:rounded-tr-md [&_th:first-child]:rounded-tl-md",
			className,
		)}
		{...props}
	/>
));

TableHeader.displayName = "TableHeader";
export default TableHeader;
