import { z } from "zod";
import IUserInfoSchema from "../../users/data/userFormSchema";
import {
	ContentFile,
	ContentItemStatus,
	ContentItemType,
	IContentItemClient,
	type IContentClient,
} from "shared-types";

const ContentItemFileFormSchema: z.ZodType<ContentFile> = z.object({
	key: z.string(),
	name: z.string(),
	url: z.string(),
	size: z.number(),
	fileExtension: z.string(),
	uploadedAt: z.date(),
	uploadedBy: IUserInfoSchema,
});

const ContentItemFormSchema: z.ZodType<IContentItemClientWithCollapse> = z.lazy(
	() =>
		z.object({
			_id: z.string(),
			name: z.string(),
			type: z.nativeEnum(ContentItemType),
			file: ContentItemFileFormSchema,
			collapsed: z.boolean().optional(),
			items: z.array(z.lazy(() => ContentItemFormSchema)).optional(),
			status: z.nativeEnum(ContentItemStatus),
		}),
);

export const ContentFormSchema: z.ZodType<IContentClient> = z.object({
	_id: z.string(),
	name: z.string().min(1, { message: "Field name is required." }),
	status: z.string().optional(),
	storage: z.object({
		storageTotal: z.number(),
		fileCountTotal: z.number(),
		folders: z.object({
			count: z.number(),
			storage: z.number(),
			percentage: z.number(),
		}),
		images: z.object({
			count: z.number(),
			storage: z.number(),
			percentage: z.number(),
		}),
		videos: z.object({
			count: z.number(),
			storage: z.number(),
			percentage: z.number(),
		}),
		documents: z.object({
			count: z.number(),
			storage: z.number(),
			percentage: z.number(),
		}),
	}),
	items: z.array(ContentItemFormSchema).optional(),
});

export type IContentItemClientWithCollapse = IContentItemClient & {
	collapsed?: boolean;
};
