import ScreenWrapper from "@/components/layout/app/screenWrapper/screenWrapper";
import Button from "@/components/ui/button/button";
import TableErrorSkeleton from "@/components/ui/table/components/tableErrorSkeleton";
import TableLoadingSkeleton from "@/components/ui/table/components/tableLoadingSkeleton";
import Table from "@/components/ui/table/table";
import * as t from "@/i18n/paraglide/messages";
import { useTableActionBarState } from "@/store/tableActionBarState";
import { faUserPlus } from "@fortawesome/pro-light-svg-icons";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { useCallback, useEffect, useState } from "react";
import { type IUserClient, UserRole, UserStatus } from "shared-types";
import UserInviteDialog from "../dialogs/userInviteDialog";
import { useSelectedUserTableRowIds } from "../helpers/selectedUserTableRowIdsStore";
import useDeleteUsers from "../hooks/useDeleteUsers";
import { queryAllUsers } from "../hooks/useFetchAllUsers";
import useGetUserTableColumns from "../hooks/useGetUserTableColumns";import {
	ButtonIconSide,
	ButtonVariant,
} from "@/components/ui/button/helpers/buttonTypes";

const UserListScreen: React.FC = () => {
	const [isOpen, setIsOpen] = useState(false);

	const {
		data: response,
		isLoading,
		isError,
	} = useSuspenseQuery(queryAllUsers);

	const navigate = useNavigate({ from: "/app/users" });
	const columns = useGetUserTableColumns();
	const { clearUserRowIds, userRowIds } = useSelectedUserTableRowIds();

	const { mutate: deleteUsers } = useDeleteUsers(clearUserRowIds);

	useEffect(() => {
		return () => {
			clearUserRowIds();
			useTableActionBarState.setState({ isOpen: false });
		};
	}, [clearUserRowIds]);

	const handleRowClick = useCallback((user: IUserClient) => {
		navigate({
			to: `/app/users/${user._id}`,
		});
	}, []);

	return (
		<>
			<ScreenWrapper
				title={t.users()}
				breadcrumbs={[]}
				actionButtons={[
					<Button
						variant={ButtonVariant.Fill}
						icon={faUserPlus}
						iconSide={ButtonIconSide.Left}
						label={t.invite()}
						onClick={() => setIsOpen(true)}
						isResponsive
					/>,
				]}
			>
				{isLoading && (
					<TableLoadingSkeleton<IUserClient> columns={columns} rowCount={10} />
				)}

				{!isLoading && (isError || !response?.success) && (
					<TableErrorSkeleton<IUserClient> columns={columns} />
				)}

				{!isLoading && !isError && response?.success && (
					<Table<IUserClient>
						columns={columns}
						data={response?.data}
						onRowClick={handleRowClick}
						onDelete={() => deleteUsers({ userIds: userRowIds })}
						onDuplicate={() => {}}
						tableColumnFilters={[
							{
								accessorKey: "status",
								label: t.status(),
								options: [
									{ label: t.active(), value: UserStatus.Active },
									{ label: t.inactive(), value: UserStatus.Inactive },
									{ label: t.invited(), value: UserStatus.Invited },
									{ label: t.unverified(), value: UserStatus.Unverified },
								],
							},
							{
								accessorKey: "role",
								label: t.userRole(),
								options: [
									{ label: t.admin(), value: UserRole.Admin },
									{ label: t.employee(), value: UserRole.Employee },
								],
							},
						]}
					/>
				)}
				<UserInviteDialog isOpen={isOpen} setIsOpen={setIsOpen} />
			</ScreenWrapper>
		</>
	);
};

UserListScreen.displayName = "UserListScreen";
export default UserListScreen;
