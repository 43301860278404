import * as t from "@/i18n/paraglide/messages";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute(
	"/_landingPageLayout/solutions/sales-presentations-and-automation",
)({
	component: () => <div>Hello /_landingPageLayout/privacy-policy!</div>,
	beforeLoad: () => {
		document.title = `${import.meta.env.VITE_APP_NAME} • ${t.salesPresentationsMetaTitle()}`;
	},
});
