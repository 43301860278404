import { create } from "zustand";

interface SelectedContentTableRowIdsState {
	contentRowIds: string[];
	addContentRowId: (id: string) => void;
	removeContentRowId: (id: string) => void;
	addContentRowIds: (ids: string[]) => void;
	removeContentRowIds: (ids: string[]) => void;
	clearContentRowIds: () => void;
}

export const useSelectedContentTableRowIds =
	create<SelectedContentTableRowIdsState>((set) => ({
		contentRowIds: [],
		addContentRowId: (id) =>
			set((state) => ({
				contentRowIds: Array.from(new Set([...state.contentRowIds, id])),
			})),
		removeContentRowId: (id) =>
			set((state) => ({
				contentRowIds: state.contentRowIds.filter((rowId) => rowId !== id),
			})),
		addContentRowIds: (ids) =>
			set((state) => ({
				contentRowIds: Array.from(new Set([...state.contentRowIds, ...ids])),
			})),
		removeContentRowIds: (ids) =>
			set((state) => ({
				contentRowIds: state.contentRowIds.filter(
					(rowId) => !ids.includes(rowId),
				),
			})),
		clearContentRowIds: () => set({ contentRowIds: [] }),
	}));
