import Navbar from "@/components/layout/app/navbar/navbar";
import SidebarDesktop from "@/components/layout/app/sidebar/sidebarDesktop/sidebarDesktop";
import { Outlet, createFileRoute, redirect } from "@tanstack/react-router";
import Cookies from "js-cookie";

export const Route = createFileRoute("/_appLayout")({
	component: LandingPageLayout,
	beforeLoad: async () => {
		const accessToken = Cookies.get("accessToken");

		if (!accessToken) {
			throw redirect({
				to: "/login",
			});
		}
	},
});

function LandingPageLayout() {
	return (
		<div id="app-root" className="flex h-screen">
			<nav>
				<SidebarDesktop />
			</nav>
			<div className="w-screen lg:w-[calc(100vw-207px)] sm:md:w-[calc(100vw-79px)]">
				<div className="flex flex-col">
					<Navbar />
					{/* 	<div className="flex-1 overflow-y-auto">
					
				</div>*/}
					<Outlet />
				</div></div>
		</div>
	);
}
