import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faListTimeline, faSquareInfo } from "@fortawesome/pro-light-svg-icons";

export enum ContentTabIds {
	Details = "details",
	History = "history",
}

export interface ContentTabDataProps {
	id: string;
	name: string;
	Icon?: IconProp;
}

export const ContentTabData: ContentTabDataProps[] = [
	{
		id: ContentTabIds.Details,
		name: "Details",
		Icon: faSquareInfo,
	},
	{
		id: ContentTabIds.History,
		name: "Historie",
		Icon: faListTimeline,
	},
];
