import { Input } from "@/components/ui/input/input";
import { StatusCardStatus } from "@/components/ui/statusCard/helpers/statusCardTypes";
import StatusCard from "@/components/ui/statusCard/statusCard";
import { faMagnifyingGlass } from "@fortawesome/pro-light-svg-icons";
import { getSidebarItems } from "../helpers/sidebarData";
import SidebarItemMobile from "./components/sidebarItemMobile";

/**
 * Represents the desktop version of the application's sidebar.
 *
 * This component is designed to be used in the desktop layout. It encapsulates
 * the logic and presentation for the sidebar specific to desktop views.
 *
 * @component
 * @returns {React.ReactElement} The desktop version of the app sidebar.
 */
const SidebarMobile: React.FC = () => {
	const sidebarItems = getSidebarItems();

	return (
		<nav className="flex h-full flex-col justify-between">
			<div className="flex flex-col gap-y-3.5 text-center text-sm">
				<Input icon={faMagnifyingGlass} placeholder="Suche" className="h-11" />
				{sidebarItems.map((sidebarItem) => (
					<SidebarItemMobile
						key={sidebarItem.page}
						page={sidebarItem.page}
						link={sidebarItem.link}
						Icon={sidebarItem.icon}
					/>
				))}
			</div>
			<StatusCard status={StatusCardStatus.maintenance} className="min-h-11" />
		</nav>
	);
};

export default SidebarMobile;
