import { FadeInWrapper } from "@/components/layout/fadeInWrapper/fadeInWrapper";
import LegalLinks from "@/components/ui/legalLinks/legalLinks";
import * as t from "@/i18n/paraglide/messages";
import { Route } from "@/routes/_landingPageLayout/(auth)/login";
import { type FC, useEffect, useState } from "react";
import VerificationSuccessDialog from "./components/verificationSuccessDialog";
import LoginForm from "./loginForm";

/**
 * LoginScreen component renders the login page with navigation buttons, a login form, and legal links.
 *
 * @component
 * @returns {JSX.Element} The rendered login screen component.
 *
 */
const LoginScreen: FC = () => {
	const { verificationSuccess } = Route.useSearch();
	const [isOpen, setIsOpen] = useState<boolean>(false);

	useEffect(() => {
		if (verificationSuccess) {
			setIsOpen(true);
		}
	}, [verificationSuccess]);

	return (
		<div className="relative flex h-[calc(100vh-5rem)] items-center justify-center py-12 px-6 md:p-0">
			<img
				src="/images/authBackground.svg"
				alt="Background"
				className="absolute m-auto h-[90%] w-full hidden md:block"
			/>
			<FadeInWrapper>
				<div className="relative z-10 mx-auto flex w-full flex-col justify-center gap-4 md:gap-6 sm:w-[450px]">
					<div className="flex flex-col space-y-2 text-center">
						<span className="text-3xl font-bold">{t.loginTitle()}</span>
						<p className="text-muted-foreground">{t.loginSubtitle()}</p>
					</div>
					<div className="flex flex-col gap-2">
						<LoginForm />
						<LegalLinks />
					</div>
				</div>
			</FadeInWrapper>
			<VerificationSuccessDialog isOpen={isOpen} setIsOpen={setIsOpen} />
		</div>
	);
};

LoginScreen.displayName = "LoginScreen";
export default LoginScreen;
