import Button from "@/components/ui/button/button";
import {
	ButtonIconSide,
	ButtonType,
	ButtonVariant,
} from "@/components/ui/button/helpers/buttonTypes";
import FormTextInput from "@/components/ui/form/components/formTextInput/formTextInput";
import { Form } from "@/components/ui/form/form";
import { Separator } from "@/components/ui/separator/separator";
import * as t from "@/i18n/paraglide/messages";
import {
	faArrowRightToBracket,
	faPaperPlane,
} from "@fortawesome/pro-duotone-svg-icons";
import { zodResolver } from "@hookform/resolvers/zod";
import { Link } from "@tanstack/react-router";
import { useForm } from "react-hook-form";
import type * as z from "zod";
import { ResetPasswordFormSchema } from "./data/resetPasswordSchema";

export function ResetPasswordForm() {
	const form = useForm<z.infer<typeof ResetPasswordFormSchema>>({
		resolver: zodResolver(ResetPasswordFormSchema),
		mode: "onBlur",
	});

	const sleep = (ms: number | undefined) =>
		new Promise((resolve) => setTimeout(resolve, ms));

	const onSubmit = async (data: any) => {
		await sleep(2000);
		alert(JSON.stringify(data, null, 2));
	};

	return (
		<Form {...form}>
			<form
				onSubmit={form.handleSubmit(onSubmit)}
				className="w-full flex flex-col"
			>
				<div className="mb-6 space-y-4">
					<FormTextInput
						form={form}
						name="email"
						label={t.email()}
						placeholder={t.email()}
						isRequired
						isDisabled={form.formState.isSubmitting}
					/>
				</div>
				<Button
					type={ButtonType.Submit}
					label={t.resetPasswordAction()}
					iconSide={ButtonIconSide.Right}
					icon={faPaperPlane}
					isDisabled={!form.formState.isValid}
					isLoading={form.formState.isSubmitting}
					className="w-full items-center"
				/>
				<Separator className="w-12 self-center my-5 bg-foreground-muted" />
				<Link to="/login">
					<Button
						icon={faArrowRightToBracket}
						variant={ButtonVariant.Outline}
						label={t.loginAction()}
						iconSide={ButtonIconSide.Right}
						className="w-full"
					/>
				</Link>
			</form>
		</Form>
	);
}
