import { faAngleUp } from "@fortawesome/pro-regular-svg-icons";
import { useWindowScroll } from "@mantine/hooks";
import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import Button from "./ui/button/button";
import { ButtonSize, ButtonVariant } from "./ui/button/helpers/buttonTypes";

const ScrollToTopButton: React.FC = () => {
	const [scroll, scrollTo] = useWindowScroll();
	const controls = useAnimation();

	useEffect(() => {
		controls.start(scroll.y > 50 ? "visible" : "hidden");
	}, [controls, scroll.y]);

	return (
		<motion.div
			className="fixed bottom-12 right-6 md:right-12 z-50"
			initial="hidden"
			animate={controls}
			variants={{
				visible: { opacity: 1, scale: 1 },
				hidden: { opacity: 0, scale: 0 },
			}}
		>
			<Button
				onClick={() => scrollTo({ y: 0 })}
				variant={ButtonVariant.Fill}
				size={ButtonSize.Medium}
				icon={faAngleUp}
			/>
		</motion.div>
	);
};

ScrollToTopButton.displayName = "ScrollToTopButton";
export default ScrollToTopButton;
