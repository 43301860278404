import { create } from "zustand";

interface SelectedUserTableRowIdsState {
	userRowIds: string[];
	addUserRowId: (id: string) => void;
	removeUserRowId: (id: string) => void;
	addUserRowIds: (ids: string[]) => void;
	removeUserRowIds: (ids: string[]) => void;
	clearUserRowIds: () => void;
}

export const useSelectedUserTableRowIds = create<SelectedUserTableRowIdsState>(
	(set) => ({
		userRowIds: [],
		addUserRowId: (id) =>
			set((state) => ({
				userRowIds: Array.from(new Set([...state.userRowIds, id])),
			})),
		removeUserRowId: (id) =>
			set((state) => ({
				userRowIds: state.userRowIds.filter((rowId) => rowId !== id),
			})),
		addUserRowIds: (ids) =>
			set((state) => ({
				userRowIds: Array.from(new Set([...state.userRowIds, ...ids])),
			})),
		removeUserRowIds: (ids) =>
			set((state) => ({
				userRowIds: state.userRowIds.filter((rowId) => !ids.includes(rowId)),
			})),
		clearUserRowIds: () => set({ userRowIds: [] }),
	}),
);
