import { faChevronRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "@tanstack/react-router";
import {
	Breadcrumb as BreadcrumbAria,
	Breadcrumbs as BreadcrumbsAria,
} from "react-aria-components";
import { IconSize } from "../icon/helpers/iconTypes";
import { Skeleton } from "../skeleton/skeleton";
import type { BreadcrumbProps } from "./helpers/breadcrumbsTypes";

const Breadcrumb: React.FC<BreadcrumbProps> = ({
	breadcrumbs,
	page,
	isLoading,
}) => {
	return (
		<BreadcrumbsAria className="flex items-center gap-1">
			<BreadcrumbAria className="flex items-center gap-1">
				<Link
					to={"/app/dashboard"}
					className="opacity-50 duration-300 hover:opacity-80"
				>
					<span className="text-md">Dashboard</span>
				</Link>
				<FontAwesomeIcon
					fixedWidth
					icon={faChevronRight}
					size={IconSize.VerySmall}
				/>
			</BreadcrumbAria>
			{breadcrumbs.map((breadcrumb, index) => {
				return (
					<BreadcrumbAria key={index} className="flex items-center gap-1">
						<Link
							to={breadcrumb.link}
							className="opacity-50 duration-300 hover:opacity-80"
						>
							<span className="text-md">{breadcrumb.name}</span>
						</Link>
						<FontAwesomeIcon
							fixedWidth
							icon={faChevronRight}
							size={IconSize.VerySmall}
						/>
					</BreadcrumbAria>
				);
			})}
			{isLoading ? (
				<BreadcrumbAria className="cursor-default">
					<Skeleton className="h-[24px] w-24" />
				</BreadcrumbAria>
			) : (
				<BreadcrumbAria className="cursor-default">{page.name}</BreadcrumbAria>
			)}
		</BreadcrumbsAria>
	);
};

export default Breadcrumb;
