import Footer from "@/components/layout/landingPage/footer/footer";
import Navbar from "@/components/layout/landingPage/navbar/navbar";
import ScrollToTopButton from "@/components/scrollToTopButton";
import {
	Outlet,
	ScrollRestoration,
	createFileRoute,
} from "@tanstack/react-router";

export const Route = createFileRoute("/_landingPageLayout")({
	component: LandingPageLayout,
});

function LandingPageLayout() {
	return (
		<div className="flex flex-col">
			{/* NotificationBar */}
			{/*<NotificationBar />*}
      {/* Navbar */}
			<Navbar />
			{/* Content */}
			<div className="pt-20">
				<ScrollRestoration />
				<Outlet />
			</div>
			{/* Footer */}
			<Footer />
			{/* Scroll Button */}
			<ScrollToTopButton />
		</div>
	);
}
