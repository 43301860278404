import apiClient from "@/lib/api";
import { QueryKeys } from "@/lib/enums/enums";
import type { IServerRes, IServerResArray } from "@/lib/responses";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import type { IContentClient } from "shared-types";

const useCreateContent = () => {
	const queryClient = useQueryClient();

	return useMutation<IServerRes<IContentClient>, Error, IContentClient>({
		mutationFn: async (
			values: IContentClient,
		): Promise<IServerRes<IContentClient>> => {
			return apiClient
				.post(`content/create`, {
					json: values,
				})
				.json();
		},
		onMutate: async (values) => {
			await queryClient.cancelQueries({ queryKey: [QueryKeys.Content] });

			const previousContents = queryClient.getQueryData<
				IServerResArray<IContentClient[]>
			>([QueryKeys.Content]);

			if (previousContents) {
				queryClient.setQueryData(
					[QueryKeys.Content],
					(old: IServerResArray<IContentClient[]> | undefined) => {
						if (!old) {
							return {
								count: 1,
								data: [{ ...values, _id: "temp" }],
							};
						}

						return {
							...old,
							count: old.count + 1,
							data: [...old.data, { ...values, _id: "temp" }],
						};
					},
				);
			} else {
				queryClient.setQueryData([QueryKeys.Content], {
					count: 1,
					data: [{ ...values, _id: "temp" }],
				});
			}

			return { previousContents };
		},
		onSuccess: (result) => {
			queryClient.setQueryData(
				[QueryKeys.Content],
				(old: IServerResArray<IContentClient[]>) => {
					const updatedData = old.data.map((content: IContentClient) =>
						content._id === "temp" ? result.data : content,
					);
					return {
						...old,
						data: updatedData,
					};
				},
			);

			toast.success(`Content ${result.data.name} created`, {
				// TODO: Ensure theme handling is aligned with your application's context
				theme: "dark",
			});
		},
		onError: (error, values, context) => {
			const ctx = context as {
				previousContents?: IServerResArray<IContentClient[]>;
			};

			if (ctx?.previousContents) {
				const previousContentIds = ctx.previousContents.data.map(
					(content) => content._id,
				);
				queryClient.setQueryData(
					[QueryKeys.Content],
					(old: IServerResArray<IContentClient[]>) => {
						const updatedData = old.data.filter(
							(content: IContentClient) =>
								!previousContentIds.includes(content._id),
						);
						return {
							...old,
							count: old.count - 1,
							data: updatedData,
						};
					},
				);
			}

			toast.error((error as Error).message, {
				// TODO: Ensure theme handling is aligned with your application's context
				theme: "dark",
			});
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: [QueryKeys.Content] });
		},
	});
};

export default useCreateContent;
