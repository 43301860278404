import * as t from "@/i18n/paraglide/messages";
import type { Column } from "@tanstack/react-table";
import type * as React from "react";
import { Badge } from "../../badge/badge";
import Button from "../../button/button";
import { ButtonSize, ButtonVariant } from "../../button/helpers/buttonTypes";
import { Checkbox } from "../../checkbox/checkbox";
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
	CommandSeparator,
} from "../../command/command";
import { Popover, PopoverContent, PopoverTrigger } from "../../popover/popover";
import { Separator } from "../../separator/separator";

interface DataTableFacetedFilterProps<TData, TValue> {
	column?: Column<TData, TValue>;
	label?: string;
	options: {
		label: string;
		value: string;
		icon?: React.ComponentType<{ className?: string }>;
	}[];
}

function TableToolbarFilter<TData, TValue>({
	column,
	label,
	options,
}: DataTableFacetedFilterProps<TData, TValue>) {
	const facets = column?.getFacetedUniqueValues();
	const selectedValues = new Set(column?.getFilterValue() as string[]);

	return (
		<Popover>
			<PopoverTrigger asChild>
				<Button
					size={ButtonSize.Small}
					variant={ButtonVariant.Outline}
					label={label}
				>
					{/* TODO: ICON */}
					{selectedValues?.size > 0 && (
						<>
							<Separator orientation="vertical" className="mx-2 h-4" />
							<Badge
								variant="secondary"
								className="rounded-sm px-1 font-normal lg:hidden"
							>
								{selectedValues.size}
							</Badge>
							<div className="hidden space-x-1 lg:flex">
								{selectedValues.size > 2 ? (
									<Badge
										variant="secondary"
										className="rounded-sm px-1 font-normal"
									>
										{selectedValues.size} selected
									</Badge>
								) : (
									options
										.filter((option) => selectedValues.has(option.value))
										.map((option) => (
											<Badge
												variant="secondary"
												key={option.value}
												className="rounded-sm px-1 font-normal"
											>
												{option.label}
											</Badge>
										))
								)}
							</div>
						</>
					)}
				</Button>
			</PopoverTrigger>
			<PopoverContent className="w-[200px] p-0" align="start">
				<Command>
					<CommandInput isDense placeholder={label} />
					<CommandList>
						<CommandEmpty>{t.noResults()}</CommandEmpty>
						<CommandGroup>
							{options.map((option) => {
								const isSelected = selectedValues.has(option.value);

								const handleSelect = (optionValue: string) => {
									const isSelected = selectedValues.has(optionValue);
									if (isSelected) {
										selectedValues.delete(optionValue);
									} else {
										selectedValues.add(optionValue);
									}
									const filterValues = Array.from(selectedValues);
									column?.setFilterValue(
										filterValues.length ? filterValues : undefined,
									);
								};

								return (
									<CommandItem
										className="my-1"
										key={option.value}
										onSelect={() => handleSelect(option.value)}
									>
										<div className="flex gap-2">
											<Checkbox
												isSelected={isSelected}
												onChange={() => handleSelect(option.value)}
											/>
											{option.icon && (
												<option.icon className="mr-2 h-4 w-4 text-muted-foreground" />
											)}
											<span>{option.label}</span>
										</div>
										{facets?.get(option.value) && (
											<span className="ml-auto flex h-4 w-4 items-center justify-center font-mono text-xs">
												{facets.get(option.value)}
											</span>
										)}
									</CommandItem>
								);
							})}
						</CommandGroup>
						{selectedValues.size > 0 && (
							<>
								<CommandSeparator />
								<CommandGroup>
									<CommandItem
										onSelect={() => column?.setFilterValue(undefined)}
										className="justify-center text-center h-7 group"
									>
										<div className="flex gap-2 items-center text-foreground-muted  transition duration-300 group-hover:text-foreground">
											<span className="text-sm">{t.clearFilters()}</span>
										</div>
									</CommandItem>
								</CommandGroup>
							</>
						)}
					</CommandList>
				</Command>
			</PopoverContent>
		</Popover>
	);
}

TableToolbarFilter.displayName = "TableToolbarFilter";
export default TableToolbarFilter;
