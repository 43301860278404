import apiClient from "@/lib/api";
import type { IServerResArray } from "@/lib/responses";
import type { IEventClient, IUserClient } from "shared-types";

/**
 * @description Fetches all users from the server
 *
 * @returns {Promise<IServerResArray<IEventClient[]>>} A promise that resolves to an array of users.
 *
 * @throws {Error} Throws an error if the fetch request fails or if the response is not valid.
 */
const fetchAllUsers = async (): Promise<IServerResArray<IUserClient[]>> => {
	const response = await apiClient
		.get("users")
		.json<IServerResArray<IEventClient[]>>();

	return response;
};

export default fetchAllUsers;
