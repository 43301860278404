import { QueryKeys } from "@/lib/enums/enums";
import type { IServerRes } from "@/lib/responses";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import type { IUserClient } from "shared-types";
import deleteUsers from "../api/deleteUsers";

/**
 * Custom hook to handle the deletion of users using React Query's `useMutation`.
 *
 * @param clearRowIds - A callback function to clear the selected row IDs after successful deletion.
 *
 * @returns A mutation object from `useMutation` to handle the deletion process.
 *
 * The hook performs the following actions:
 * - Cancels any ongoing queries for users.
 * - Optimistically updates the users list by removing the deleted users.
 * - Reverts the optimistic update if the mutation fails.
 * - Shows a success or error toast notification based on the mutation result.
 * - Invalidates the users query to refetch the updated list of users.
 */
const useDeleteUsers = (clearRowIds: () => void) => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: deleteUsers,
		onMutate: async ({ userIds }: { userIds: Array<IUserClient["id"]> }) => {
			console.log("userIds", userIds);
			await queryClient.cancelQueries({ queryKey: [QueryKeys.Users] });

			const previousUsers = queryClient.getQueryData<
				IServerRes<Array<IUserClient>>
			>([QueryKeys.Users]);

			if (!previousUsers) return;

			queryClient.setQueryData([QueryKeys.Users], {
				...previousUsers,
				data: [
					...(previousUsers.data
						? previousUsers.data.filter((user) => !userIds.includes(user._id))
						: []),
				],
			});
			return { previousUsers };
		},

		onError: (error, values, context) => {
			queryClient.setQueryData([QueryKeys.Users], context?.previousUsers);

			toast.error((error as any).response.data.error, {
				theme: "dark",
			});
		},
		onSuccess: () => {
			clearRowIds();
			toast.success("Users deleted", {
				theme: "dark",
			});
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: [QueryKeys.Users] });
		},
	});
};

export default useDeleteUsers;
