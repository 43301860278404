import Button from "@/components/ui/button/button";
import Dialog from "@/components/ui/dialog/dialog";
import * as t from "@/i18n/paraglide/messages";
import type { FC } from "react";
import { ContentItemType } from "shared-types";
import { countChildrenRecursive } from "../helpers/countContentTreeItems";
import { IContentItemClientWithCollapse } from "../../../data/contentFormSchema";
import { ButtonColor, ButtonIconSide, ButtonVariant } from "@/components/ui/button/helpers/buttonTypes";
import { faTrashAlt } from "@fortawesome/pro-duotone-svg-icons";
import parse from "html-react-parser";

export interface DeleteContentTreeItemDialogProps {
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	contentTreeItem: IContentItemClientWithCollapse;
}

const DeleteContentTreeItemDialog: FC<DeleteContentTreeItemDialogProps> = ({
	isOpen,
	setIsOpen,
	contentTreeItem,
}) => {

	let childCount = 0;

	if (contentTreeItem.type === ContentItemType.Folder) {
		childCount = countChildrenRecursive(contentTreeItem);
	}

	return (
			<Dialog
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				title={`Löschen`}
				actionButtons={[
					<Button
						label={t.remove()}
						variant={ButtonVariant.Outline}
						icon={faTrashAlt}
						iconSide={ButtonIconSide.Left}
						color={ButtonColor.Error}
						onClick={() => {}}
					/>,
				]}
			>
					{contentTreeItem.type === ContentItemType.Folder && childCount > 0 && <span className="font-normal text-base">
					{parse(t.deleteContentItemFolderWithChilds({ contentItemName: contentTreeItem.name, childrenCount: childCount }))}</span>}
					{contentTreeItem.type === ContentItemType.Folder && childCount === 0 && <span className="font-normal text-base">
						{parse(t.deleteContentItemFolder({ contentItemName: contentTreeItem.name }))}</span>}
					{contentTreeItem.type === ContentItemType.Image && <span className="font-normal">
					{parse(t.deleteContentItemImage({ contentItemName: contentTreeItem.name }))}</span>}
					{contentTreeItem.type === ContentItemType.Document && <span className="font-normal">
					{parse(t.deleteContentItemDocument({ contentItemName: contentTreeItem.name }))}</span>}
					{contentTreeItem.type === ContentItemType.Video && <span className="font-normal">
					{parse(t.deleteContentItemVideo({ contentItemName: contentTreeItem.name }))}</span>}
			</Dialog>
	);
};

DeleteContentTreeItemDialog.displayName = "DeleteContentTreeItemDialog";
export default DeleteContentTreeItemDialog;
