import { IconSize } from "@/components/ui/icon/helpers/iconTypes";
import { Icon } from "@/components/ui/icon/icon";
import type { ContentItemType } from "shared-types";
import type { ContentTypeCardDataType } from "../../helpers/contentTreeTypes";
import { getContentTypeCardStyling } from "./helpers/contentTypeCardStyles";

export interface ContentTypeCardProps {
	data: ContentTypeCardDataType;
	setSelectedType: () => void;
	selectedType?: ContentItemType;
}

const ContentTypeCard: React.FC<ContentTypeCardProps> = ({
	data,
	selectedType,
	setSelectedType,
}) => {
	const isSelected = selectedType === data.type;

	return (
		<div
			className={getContentTypeCardStyling({
				isSelected,
				secondaryColor: data.colors.secondary,
				tertiaryColor: data.colors.tertiary,
			})}
			onClick={setSelectedType}
		>
			<div className="flex flex-col gap-3">
				<Icon
					icon={data.Icon}
					size={IconSize.ExtraLarge}
					primaryColor={data.colors.primary}
					secondaryColor={data.colors.secondary}
				/>
				<span className="font-semibold">{data.name}</span>
			</div>
		</div>
	);
};

ContentTypeCard.displayName = "ContentTypeCard";
export default ContentTypeCard;
