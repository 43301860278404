import FileInput from "@/components/ui/fileInput/fileInput";
import useFetchFileUploadUrl from "@/lib/hooks/useFetchFileUploadUrl";
import { type SetStateAction, useState } from "react";
import { Controller } from "react-hook-form";
import type {
	ControllerRenderProps,
	FieldValues,
	Path,
	PathValue,
} from "react-hook-form";
import {
	FormControl,
	FormDescription,
	FormField,
	FormMessage,
} from "../../form";
import FormItem from "../formItem/formItem";
import FormLabel from "../formLabel/formLabel";
import type { FormFileInputProps } from "./helpers/formFileInputTypes";

/**
 * FormFileInput component for handling file uploads within a form.
 *
 * @template T - The type of the form field values.
 * @param {FormFileInputProps<T>} props - The properties for the FormFileInput component.
 * @param {string} props.label - The label for the file input field.
 * @param {Path<T>} props.name - The name of the form field.
 * @param {string | React.ReactNode} [props.description] - The description for the file input field.
 * @param {boolean} [props.isRequired] - Indicates if the file input is required.
 * @param {UseFormReturn<T>} props.form - The form instance from react-hook-form.
 * @param {boolean} [props.isDisabled] - Indicates if the file input is disabled.
 * @returns {JSX.Element} The rendered FormFileInput component.
 */
function FormFileInput<T extends FieldValues>({
	name,
	label,
	description,
	entity,
	entityId,
	isRequired,
	form,
	isDisabled,
}: FormFileInputProps<T>) {
	const { control, setValue } = form;
	const { mutate: uploadFile } = useFetchFileUploadUrl();
	const [fileName, setFileName] = useState<string | undefined>(undefined);
	const [fileSize, setFileSize] = useState<string | undefined>(undefined);
	const [fileTypeLabel, setFileTypeLabel] = useState<string | undefined>(
		undefined,
	);
	const [uploadProgress, setUploadProgress] = useState<number>(0);

	const handleFileChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		field: ControllerRenderProps<T, Path<T>>,
	) => {
		const file = event.target.files?.[0];
		if (file) {
			uploadFile(
				{
					file,
					fileName: file.name,
					entity,
					entityId,
					onProgress: (progress: SetStateAction<number>) => {
						setUploadProgress(progress);
					},
				},
				{
					onSuccess: (url) => {
						field.onChange(event);

						console.log("form222", form.getValues());

						setValue("file" as Path<T>, {
							url,
							name: file.name,
							size: file.size,
							fileExtension: file.type,
						} as PathValue<T, Path<T>>);
						
						setFileName(file.name);
						setFileSize(`${(file.size / 1024 / 1024).toFixed(2)} MB`);
						setFileTypeLabel(file.type);
						setUploadProgress(0);
						form.trigger();
						console.log("validation", form.formState.errors);
						console.log("formValid", form.formState.isValid);
					},
					onError: () => {
						handleRemove();
					},
				},
			);
		}
	};

	const handleRemove = () => {
		setFileName(undefined);
		setFileSize(undefined);
		setFileTypeLabel(undefined);
		setValue(name, "" as PathValue<T, Path<T>>);
		setUploadProgress(0);
	};

	return (
		<FormField
			control={control}
			name={name}
			render={({ field, fieldState }) => (
				<FormItem className="space-y-3">
					<div className="flex flex-col space-y-1">
						<FormLabel>
							{label}
							{isRequired && (
								<span
									className={fieldState.error ? "text-error" : "text-warning"}
								>
									*
								</span>
							)}
						</FormLabel>
						{description &&
							(typeof description === "string" ? (
								<FormDescription>{description}</FormDescription>
							) : (
								<span className="text-sm text-muted-foreground ml-2">
									{description}
								</span>
							))}
					</div>
					<FormControl>
						<Controller
							control={control}
							name={name}
							render={({ field }) => (
								<FileInput
									name={name}
									fileName={fileName}
									fileSize={fileSize}
									fileType={fileTypeLabel}
									uploadProgress={uploadProgress}
									allowedFileTypes={[]}
									onChange={(e) => handleFileChange(e, field)}
									onRemove={handleRemove}
									className={fieldState.error ? "border-error" : ""}
									disabled={isDisabled}
								/>
							)}
						/>
					</FormControl>
					<FormMessage />
				</FormItem>
			)}
		/>
	);
}

FormFileInput.displayName = "FormFileInput";
export default FormFileInput;
