import LegalNotice from "@/components/screens/landingPage/legal/legalNotice/legalNotice";
import * as t from "@/i18n/paraglide/messages";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute(
	"/_landingPageLayout/(legal)/legal-notice",
)({
	component: LegalNotice,
	beforeLoad: () => {
		document.title = `${import.meta.env.VITE_APP_NAME} • ${t.legalNoticeMetaTitle()}`;
	},
});
