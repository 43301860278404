import { ContentItemType } from "shared-types";
import * as t from "@/i18n/paraglide/messages";


export const getContentItemTypeLabel = (type: ContentItemType) => {
  switch(type) {
    case ContentItemType.Folder:
      return t.folder();
    case ContentItemType.Image:
      return t.image();
    case ContentItemType.Document:
      return t.document();
    case ContentItemType.Video:
      return t.video();
    default:
      return t.unknown();
  }
}
