import api from "@/lib/api";
import { QueryKeys } from "@/lib/enums/enums";
import type { IServerRes } from "@/lib/responses";
import type { IContentClient } from "shared-types";

/**
 * @description Delete multiple contents by their IDs.
 *
 * @param {Object} params - The parameters for the delete request.
 * @param {string} params.contentIds - Array of content IDs to be deleted.
 *
 * @returns {Promise<IServerRes>} A promise that resolves to a server response.
 *
 * @throws {Error} Throws an error if the delete request fails.
 */
export const deleteContent = async ({
	contentIds,
}: { contentIds: Array<IContentClient["_id"]> }): Promise<IServerRes> => {
	const response: IServerRes = await api
		.delete(`content`, {
			json: contentIds,
		})
		.json<IServerRes>();
	return response;
};

export default deleteContent;
