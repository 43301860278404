import { useFieldArray, useFormContext } from "react-hook-form";
import { ContentFile, ContentItemType } from "shared-types";

export type AddContentItemParams = {
  name: string;
  type: ContentItemType;
  file: Omit<ContentFile, "key" | "uploadedAt" | "uploadedBy">;
  depth?: number;
  path?: string;
}; 

export type AddContentItemFunctionType = (params: AddContentItemParams) => void;

function useContentFormField(prefix: string) {
	const { control, register, getValues, setValue } = useFormContext();

	const nameInputPath = `${prefix}name` as "name";
	const itemsArrayInputPath = `${prefix}items` as "items";

	const { fields, append, remove } = useFieldArray({
		control,
		name: itemsArrayInputPath,
	});

	const addItem: AddContentItemFunctionType = ({
		name,
		type,
		file,
		depth = 0,
		path = "",
	}) => {

		console.log("addItem", name, type, file, depth, path);

		const newItem = {
			id: "",
			name: name,
			type: type,
			file: file,
			depth: depth + 1,
			collapsed: type === ContentItemType.Folder ? false : undefined,
			// items: type === ContentItemType.Folder ? false : undefined
			items: [],
		};


		if (path) {
			const itemsPath = path
				.split(".")
				.reduce((acc, cur) => {
					const index = cur.match(/\d+/);
					if (index) {
						acc.push(`[${index[0]}]`);
					} else {
						acc.push(cur);
					}
					return acc;
				}, [] as string[])
				.join(".");

			const currentItems = getValues(itemsPath) || [];
			currentItems.push(newItem);
			setValue(itemsPath, currentItems);
		} else {
			append(newItem);
		}
	};

	const removeItem = (index: number) => {
		console.log("removeItem", index, prefix);
		remove(index);
	};

	return {
		fields,
		register,
		addItem,
		removeItem,
		nameInputPath,
		setValue,
	};
}

export default useContentFormField;
