import api from "@/lib/api";
import { QueryKeys } from "@/lib/enums/enums";
import type { IServerRes } from "@/lib/responses";
import type { IContentClient } from "shared-types";

/**
 * @description Fetches a single content by its ID.
 *
 * @param {Object} params - The parameters for the fetch request.
 * @param {string} params.contentId - The ID of the content to fetch.
 *
 * @returns {Promise<IContentClient>} A promise that resolves to the content data.
 *
 * @throws {Error} Throws an error if the fetch request fails.
 */
export const fetchOneContent = async ({
	contentId,
}: { contentId: IContentClient["_id"] }): Promise<
	IServerRes<IContentClient>
> => {
	const response = await api
		.get(`${QueryKeys.Content}/${contentId}`)
		.json<IServerRes<IContentClient>>();

	return response;
};

export default fetchOneContent;
