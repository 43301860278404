import SectionWrapper from "@/components/layout/sectionWrapper/sectionWrapper";
import SpinningLogos from "./components/SpinningLogos/components/spinningLogos";

const ConnectivityFeature: React.FC = () => {
	return (
		<SectionWrapper title={"Connectivity"} subtitle={"Subtitle"}>
			<div className="grid place-content-center overflow-hidden px-4 py-36">
				<SpinningLogos />
			</div>
		</SectionWrapper>
	);
};

ConnectivityFeature.displayName = "ConnectivityFeature";
export default ConnectivityFeature;
