import { ButtonType } from "@/components/ui/button/helpers/buttonTypes";
import { IconSize } from "@/components/ui/icon/helpers/iconTypes";
import { faAngleDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "@tanstack/react-router";
import clsx from "clsx";
import {
	MenuItemType,
	type NavbarDesktopMenuProps,
} from "../../helpers/navbarTypes";

const NavbarDesktopMenu: React.FC<NavbarDesktopMenuProps> = ({
	title,
	selectedMenuItem,
	setSelectedMenuItem,
	menuItem,
}) => {
	const content = (
		<button
			type={ButtonType.Button}
			id={`shift-tab-${menuItem.id}`}
			onMouseEnter={() => setSelectedMenuItem(menuItem)}
			onClick={() => setSelectedMenuItem(menuItem)}
			className={clsx(
				"flex group gap-1 lg:gap-2 h-10 w-max items-center justify-center px-4 py-2  disabled:pointer-events-none disabled:opacity-50",
				{
					"text-accent-foreground": selectedMenuItem?.id === menuItem.id,
				},
			)}
		>
			<span
				className={clsx(
					"text-md font-semibold transition-colors hover:text-accent-foreground ",
					{
						"text-accent-foreground": selectedMenuItem?.id === menuItem.id,
					},
				)}
			>
				{title}
			</span>
			{menuItem.menuItems && (
				<FontAwesomeIcon
					className={
						"relative size-3 transition-all duration-300 ease-in-out group-hover:3d-rotate-180"
					}
					size={IconSize.VerySmall}
					icon={faAngleDown}
					aria-hidden="true"
				/>
			)}
		</button>
	);

	return (
		<>
			{menuItem.type === MenuItemType.Link ? (
				<Link to={menuItem.href} target="_self">
					{content}
				</Link>
			) : (
				<>{content}</>
			)}
		</>
	);
};

NavbarDesktopMenu.displayName = "NavbarDesktopMenu";
export default NavbarDesktopMenu;
