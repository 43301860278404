import { cn } from "@/lib/utils";
import { type HTMLAttributes, forwardRef, useId } from "react";
import FormItemContext from "./context/formItemContext";

const FormItem = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
	({ className, ...props }, ref) => {
		const id = useId();

		return (
			<FormItemContext.Provider value={{ id }}>
				<div ref={ref} className={cn("space-y-1.5", className)} {...props} />
			</FormItemContext.Provider>
		);
	},
);

FormItem.displayName = "FormItem";
export default FormItem;
