import Faq from "./components/faq/faq";
import Features from "./components/features/features";
import Hero from "./components/hero/hero";
import Introduction from "./components/introduction/introduction";
import Newsletter from "./components/newsletter/newsletter";
import Pricing from "./components/pricing/pricing";
import Usage from "./components/usage/usage";

const HomeScreen: React.FC = () => {
	return (
		<div className="flex flex-col">
			{/* Hero */}
			<Hero />
			{/* Introduction */}
			<Introduction />
			{/* Features */}
			<Features />
			{/* UseCases */}
			<Usage />
			{/* Pricing */}
			<Pricing />
			{/* FAQ */}
			<Faq />
			{/* Newsletter */}
			<Newsletter />
		</div>
	);
};

HomeScreen.displayName = "HomeScreen";
export default HomeScreen;
