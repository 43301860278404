import { QueryKeys } from "@/lib/enums/enums";
import type { IServerRes } from "@/lib/responses";
import {
	useMutation,
	useQueryClient,
} from "@tanstack/react-query";
import type { IContentClient } from "shared-types";
import updateOneContent from "../api/updateOneContent";

export const useUpdateContent = () => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: updateOneContent,
		onMutate: async (values: Partial<IContentClient>) => {
			await queryClient.cancelQueries({ queryKey: [QueryKeys.Content] });
			
			const previousData = queryClient.getQueryData([QueryKeys.Content]);

			if (!previousData) {
				return;
			}

			queryClient.setQueryData(
				[QueryKeys.Content],
				(old?: IServerRes<IContentClient>) => {
					if (!old || !old.data) return old;
					return {
						...old,
						data: {
							...old.data,
							...values,
						},
					};
				},
			);

			return { previousData };
		},
		onError: (err, user, context) => {
			queryClient.setQueryData([QueryKeys.Content], context?.previousData);
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: [QueryKeys.Content] });
		},
	});
};
