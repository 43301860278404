import { cn } from "@/lib/utils";
import { faCaretDown } from "@fortawesome/pro-light-svg-icons";
import * as SelectPrimitive from "@radix-ui/react-select";
import { forwardRef } from "react";
import Button from "../../button/button";
import {
	ButtonIconSide,
	ButtonVariant,
} from "../../button/helpers/buttonTypes";

export enum SelectTriggerStyle {
	Button = "button",
	Form = "form",
}

export interface SelectTriggerProps {
	triggerStyle?: SelectTriggerStyle;
}

const SelectTrigger = forwardRef<
	React.ElementRef<typeof SelectPrimitive.Trigger>,
	React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger> &
		SelectTriggerProps
>(
	(
		{ className, children, triggerStyle = SelectTriggerStyle.Button, ...props },
		ref,
	) => (
		<SelectPrimitive.Trigger ref={ref} {...props}>
			{triggerStyle === SelectTriggerStyle.Form && (
				<Button
					variant={ButtonVariant.Input}
					disabled={false}
					icon={faCaretDown}
					iconSide={ButtonIconSide.Right}
					label="Select"
					className={cn("flex w-full justify-start text-foreground", className)}
				/>
			)}

			{triggerStyle === SelectTriggerStyle.Button && (
				<Button
					variant={ButtonVariant.Input}
					disabled={false}
					icon={faCaretDown}
					iconSide={ButtonIconSide.Right}
					label="Select"
					className={cn("flex w-full justify-start text-foreground", className)}
				/>
			)}
		</SelectPrimitive.Trigger>
	),
);

SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;
export default SelectTrigger;
