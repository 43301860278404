import * as t from "@/i18n/paraglide/messages";
import {
	faFilterCircleXmark,
	faFilterList,
	faXmark,
} from "@fortawesome/pro-light-svg-icons";
import Button from "../../button/button";
import {
	ButtonColor,
	ButtonIconSide,
	ButtonSize,
	ButtonVariant,
} from "../../button/helpers/buttonTypes";
import TextInput from "../../textInput/textInput";
import type { TableToolbarProps } from "../helpers/tableTypes";
import TableToolbarFilter from "./tableToolbarFilter";

function TableToolbar<TData>({
	table,
	tableColumnFilters,
}: TableToolbarProps<TData>) {
	const isFiltered = table.getState().columnFilters.length > 0;
	return (
		<div className="flex items-center justify-between">
			<div className="flex items-center gap-2.5">
				<TextInput
					placeholder={t.filter()}
					icon={faFilterList}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						table.setGlobalFilter(String(event.target.value));
					}}
					className="w-full sm:w-[220px] h-9"
				/>
				{tableColumnFilters?.map((filter) => {
					const column = table.getColumn(filter.accessorKey);

					return (
						<TableToolbarFilter
							key={filter.accessorKey}
							column={column}
							label={filter.label}
							options={filter.options}
						/>
					);
				})}
				{isFiltered && (
					<Button
						variant={ButtonVariant.Ghost}
						color={ButtonColor.Warning}
						size={ButtonSize.ExtraSmall}
						icon={faXmark}
						iconSide={ButtonIconSide.Left}
						onClick={() => table.resetColumnFilters()}
						label={t.clearFilters()}
					/>
				)}
			</div>
			{/*<TableColumnFilter table={table} />*/}
		</div>
	);
}

TableToolbar.displayName = "TableToolbar";
export default TableToolbar;
