import ScreenWrapper from "@/components/layout/app/screenWrapper/screenWrapper";
import Button from "@/components/ui/button/button";
import Tabs from "@/components/ui/tabs/tabs";
import * as t from "@/i18n/paraglide/messages";
import { QueryKeys } from "@/lib/enums/enums";
import { Route } from "@/routes/_appLayout/app/content/$contentId";
import { useContentItemStore } from "@/store/contentItemStore";
import { faSave, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { DevTool } from "@hookform/devtools";
import { zodResolver } from "@hookform/resolvers/zod";
import { TabsContent } from "@radix-ui/react-tabs";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import type { IContentClient } from "shared-types";
import { ContentFormSchema } from "../data/contentFormSchema";
import { queryOneContent } from "../hooks/useFetchOneContent";
import { useUpdateContent } from "../hooks/useUpdateContent";
import ContentDetailDataTab from "./tabs/contentDetailDataTab";
import { getInitialContentValues } from "../data/contentFormValues";
import {
	ButtonColor,
	ButtonIconSide,
	ButtonVariant,
} from "@/components/ui/button/helpers/buttonTypes";
import {
	ContentTabData,
	type ContentTabDataProps,
	ContentTabIds,
} from "./helpers/contentTabData";

const ContentDetailScreen: React.FC = () => {
	const [activeTab, setActiveTab] = useState<ContentTabDataProps>(
		ContentTabData[0],
	);

	const queryClient = useQueryClient();

	const { mutateAsync: updateContent, isPending: updatePending } =
		useUpdateContent();

	const { contentId } = Route.useParams();
	const { data: response, isLoading } = useSuspenseQuery(
		queryOneContent({ contentId }),
	);

	useEffect(() => {
		if (response?.success && response?.data) {
			form.reset(getInitialContentValues(response.data));
			form.trigger();
		}
	}, [response]);

	const form = useForm<IContentClient>({
		resolver: zodResolver(ContentFormSchema),
		defaultValues: getInitialContentValues(),
		mode: "onBlur",
	});

	console.log("form!!!", form.getValues());

	const getTabContent = (tab: ContentTabDataProps) => {
		switch (tab.id) {
			case ContentTabIds.Details:
				return <ContentDetailDataTab content={response.data} />;
			case ContentTabIds.History:
				return; //<EventDetailHistoryTab />;
			default:
				return <ContentDetailDataTab content={response.data} />;
		}
	};

	const nameValue = form.watch("name");

	const handleSave = async () => {
		toast.promise(
			updateContent(
				{ data: form.getValues() },
				{
					onSuccess: () => {
						queryClient.invalidateQueries({
							queryKey: [QueryKeys.Content, contentId],
						});
					},
					onError: (error) => {},
					onSettled: () => {
						form.reset(form.getValues());
					},
				},
			),
			{
				pending: t.toastSavePending({ entityName: t.content() }),
				success: t.toastSaveSuccess({ entityName: t.content() }),
				error: t.toastSaveError({ entityName: t.content() }),
			},
		);
	};

	return (
		<ScreenWrapper
			isLoading={!response}
			title={nameValue}
			breadcrumbs={[
				{
					name: "Content",
					link: "/app/content",
				},
			]}
			actionButtons={[
				<Button
					key={1}
					variant={ButtonVariant.Outline}
					icon={faTrashAlt}
					color={ButtonColor.Error}
					iconSide={ButtonIconSide.Left}
					label={t.remove()}
					isResponsive
				/>,
				<Button
					key={1}
					variant={ButtonVariant.Fill}
					icon={faSave}
					disabled={!form.formState.isDirty || updatePending}
					iconSide={ButtonIconSide.Left}
					onClick={() => handleSave()}
					label={t.save()}
					isResponsive
				/>,
			]}
		>
			<div className="flex">
				<Tabs
					isLoading={!response.data && isLoading}
					tabs={ContentTabData}
					activeTab={activeTab}
					handleClick={(tab) => {
						setActiveTab(tab);
					}}
				>
					{ContentTabData.map((tab) => (
						<TabsContent key={tab.id} value={tab.id}>
							<AnimatePresence mode="wait">
								<motion.div
									key={tab.id}
									initial={{ y: 10, opacity: 0 }}
									animate={{ y: 0, opacity: 1 }}
									exit={{ y: -10, opacity: 0 }}
									transition={{ duration: 0.2 }}
								>
									<FormProvider {...form}>
										<div className="mt-6">
											{response.data && !isLoading && getTabContent(tab)}
										</div>
									</FormProvider>
								</motion.div>
							</AnimatePresence>
						</TabsContent>
					))}
				</Tabs>
			</div>
			{import.meta.env.DEV && <DevTool control={form.control} />}
		</ScreenWrapper>
	);
};

ContentDetailScreen.displayName = "ContentDetailScreen";
export default ContentDetailScreen;
