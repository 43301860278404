import { QueryKeys } from "@/lib/enums/enums";
import {
	keepPreviousData,
	queryOptions,
	useQuery,
} from "@tanstack/react-query";
import fetchAllEvents from "../api/fetchAllEvents";

export const useFetchAllEvents = () => {
	return useQuery({
		queryKey: [QueryKeys.Events],
		queryFn: () => fetchAllEvents(),
		placeholderData: keepPreviousData,
	});
};

export const queryAllEvents = queryOptions({
	queryKey: [QueryKeys.Events],
	queryFn: () => fetchAllEvents(),
	placeholderData: keepPreviousData,
});
