import * as t from "@/i18n/paraglide/messages";
import { Link } from "@tanstack/react-router";
import { Separator } from "../separator/separator";

export const LegalLinks: React.FC = () => {
	return (
		<div className="mt-2 flex h-3 items-center justify-center space-x-4 text-[0.8rem] text-foreground-muted">
			<Link className="link" to={"/terms-of-service"}>
				{t.termsOfService()}
			</Link>
			<Separator className={"bg-slate-500"} orientation="vertical" />
			<Link className="link" to={"/legal-notice"}>
				{t.legalNotice()}
			</Link>
			<Separator className={"bg-slate-500"} orientation="vertical" />
			<Link className="link" to={"/privacy-policy"}>
				{t.privacyPolicy()}
			</Link>
		</div>
	);
};

export default LegalLinks;
