import PulseLogo from "@/components/ui/pulseLogo/pulseLogo";
import { Link } from "@tanstack/react-router";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { SpinningLogoData } from "../helpers/spinningLogosData";
import {
	degreesToRadians,
	useWindowSize,
} from "../helpers/spinningLogosFunctions";
import {
	SpinningLogosBreakpoint,
	SpinningLogosFontSize,
	SpinningLogosIconCenter,
	SpinningLogosIconWrapper,
	SpinningLogosPadding,
	SpinningLogosTransition,
} from "../helpers/spinningLogosTypes";

export const SpinningLogos = () => {
	const { width } = useWindowSize();

	const [sizes, setSizes] = useState({
		radiusToCenterOfIcons: SpinningLogosIconCenter.lg,
		iconWrapperWidth: SpinningLogosIconWrapper.lg,
		ringPadding: SpinningLogosPadding.lg,
		logoFontSize: SpinningLogosFontSize.lg,
	});

	useEffect(() => {
		if (!width) return;

		if (width < SpinningLogosBreakpoint.sm) {
			setSizes({
				radiusToCenterOfIcons: SpinningLogosIconCenter.sm,
				iconWrapperWidth: SpinningLogosIconWrapper.sm,
				ringPadding: SpinningLogosPadding.sm,
				logoFontSize: SpinningLogosFontSize.sm,
			});
		} else if (width < SpinningLogosBreakpoint.md) {
			setSizes({
				radiusToCenterOfIcons: SpinningLogosIconCenter.md,
				iconWrapperWidth: SpinningLogosIconWrapper.md,
				ringPadding: SpinningLogosPadding.md,
				logoFontSize: SpinningLogosFontSize.md,
			});
		} else {
			setSizes({
				radiusToCenterOfIcons: SpinningLogosIconCenter.lg,
				iconWrapperWidth: SpinningLogosIconWrapper.lg,
				ringPadding: SpinningLogosPadding.lg,
				logoFontSize: SpinningLogosFontSize.lg,
			});
		}
	}, [width]);

	return (
		<div
			style={{
				width:
					sizes.radiusToCenterOfIcons +
					sizes.iconWrapperWidth +
					sizes.ringPadding,
				height:
					sizes.radiusToCenterOfIcons +
					sizes.iconWrapperWidth +
					sizes.ringPadding,
			}}
			className="grid place-content-center rounded-full"
		>
			<motion.div
				initial={{ rotate: 0 }}
				animate={{ rotate: 360 }}
				transition={SpinningLogosTransition}
				style={{
					width:
						sizes.radiusToCenterOfIcons -
						sizes.iconWrapperWidth -
						sizes.ringPadding,
					height:
						sizes.radiusToCenterOfIcons -
						sizes.iconWrapperWidth -
						sizes.ringPadding,
				}}
				className="relative grid place-items-center rounded-full"
			>
				<motion.div
					initial={{ rotate: 0 }}
					animate={{ rotate: -360 }}
					transition={SpinningLogosTransition}
				>
					<PulseLogo />
				</motion.div>
				{SpinningLogoData.map((icon, idx) => {
					const degrees = (360 / SpinningLogoData.length) * idx;
					return (
						<motion.div
							key={idx}
							style={{
								marginTop:
									sizes.radiusToCenterOfIcons *
									Math.sin(degreesToRadians(degrees)),
								marginLeft:
									sizes.radiusToCenterOfIcons *
									Math.cos(degreesToRadians(degrees)),
								width: sizes.iconWrapperWidth,
								height: sizes.iconWrapperWidth,
							}}
							initial={{ rotate: 0 }}
							animate={{ rotate: -360 }}
							transition={SpinningLogosTransition}
							className={`absolute grid place-content-center rounded-full ${icon.className}`}
						>
							<Link to={icon.link}>
								<img src={icon.image} alt={icon.link} />
							</Link>
						</motion.div>
					);
				})}
			</motion.div>
		</div>
	);
};

SpinningLogos.displayName = "SpinningLogos";
export default SpinningLogos;
