import SectionWrapper from "@/components/layout/sectionWrapper/sectionWrapper";
import * as t from "@/i18n/paraglide/messages";
import FormComponentGrid from "./components/formFeatureComponentGrid/formFeatureComponentGrid";
import FormFeatureTabs from "./components/formFeatureTabs/formFeatureTabs";

const FormFeature: React.FC = () => {
	return (
		<SectionWrapper title={t.forms()} subtitle={t.formsDescriptionShort()}>
			<div className="flex flex-col gap-10">
				<FormFeatureTabs />
				<div>
					<div className="flex flex-col p-4 gap-2">
						<span className="text-2xl font-semibold">Komponenten</span>
						<span className="text-lg text-justify">
							Hier finden Sie eine Übersicht der Komponenten welche Sie in
							unserem Formular Baukasten verwenden können. Mit der Zeit werden
							wir weitere Komponenten {t.add()}. Sollten Sie eine bestimmte
							Komponente vermissen oder eine individuelle Komponente benötigen
							könenn Sie uns jederzeit kontaktieren.
						</span>
					</div>
					<FormComponentGrid />
				</div>
			</div>
		</SectionWrapper>
	);
};

FormFeature.displayName = "FormFeature";
export default FormFeature;
