import Button from "@/components/ui/button/button";
import { ButtonType } from "@/components/ui/button/helpers/buttonTypes";
import Dialog from "@/components/ui/dialog/dialog";
import FormTextInput from "@/components/ui/form/components/formTextInput/formTextInput";
import { Form } from "@/components/ui/form/form";
import { selectItemListDemoData } from "@/components/ui/select/helper/selectTypes";
import Select from "@/components/ui/select/select";
import * as t from "@/i18n/paraglide/messages";
import { zodResolver } from "@hookform/resolvers/zod";
import type { FC } from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import type { IInviteUserDto } from "shared-types";
import { z } from "zod";
import useInviteUser from "../hooks/useInviteUser";

export interface InviteUserDialogProps {
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
}

const UserInviteDialog: FC<InviteUserDialogProps> = ({ isOpen, setIsOpen }) => {
	const { mutate: inviteUser } = useInviteUser();

	const UserInviteSchema = z.object({
		email: z
			.string({ message: t.validationRequired({ fieldName: t.email() }) })
			.email({
				message: t.validationEmail(),
			}),
		profile: z.object({
			firstName: z.string({
				message: t.validationRequired({ fieldName: t.firstName() }),
			}),
			lastName: z.string({
				message: t.validationRequired({ fieldName: t.lastName() }),
			}),
		}),
		role: z.string({
			message: t.validationRequired({ fieldName: t.userRole() }),
		}),
		language: z.string({
			message: t.validationRequired({ fieldName: t.userlanguage() }),
		}),
	});

	const form = useForm<z.infer<typeof UserInviteSchema>>({
		resolver: zodResolver(UserInviteSchema),
		mode: "onBlur",
		shouldFocusError: true,
	});

	const onSubmit: SubmitHandler<IInviteUserDto> = async (values) => {
		inviteUser(values, {
			onSuccess: () => {
				setIsOpen(false);
			},
		});
	};

	return (
		<Form {...form}>
			<Dialog
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				title={t.inviteUserDialogTitle()}
				actionButtons={[
					<Button
						isLoading={form.formState.isSubmitting}
						disabled={!form.formState.isValid}
						label={t.add()}
						type={ButtonType.Submit}
						onClick={() => form.handleSubmit(onSubmit)()}
					/>,
				]}
			>
				<div className="flex flex-col gap-4">
					<span>Wie möchten Sie die neue Kollektion nennen?</span>
					<div className="flex flex-col gap-4">
						<div className="w-full">
							<FormTextInput
								form={form}
								name="email"
								placeholder={t.email()}
								label={t.email()}
								isRequired
							/>
						</div>
						<div className="flex flex-grow gap-3">
							<div className="w-1/2">
								<FormTextInput
									form={form}
									name="profile.firstName"
									label={t.firstName()}
									placeholder={t.firstName()}
									isRequired
									isDisabled={form.formState.isSubmitting}
								/>
							</div>
							<div className="w-1/2">
								<FormTextInput
									form={form}
									name="profile.lastName"
									label={t.lastName()}
									placeholder={t.lastName()}
									isRequired
									isDisabled={form.formState.isSubmitting}
								/>
							</div>
						</div>
						<div className="flex flex-grow gap-3">
							<div className="w-1/2">language</div>
							<div>
								<Select itemList={selectItemListDemoData} />
							</div>
						</div>
					</div>
				</div>
			</Dialog>
		</Form>
	);
};

UserInviteDialog.displayName = "UserInviteDialog";
export default UserInviteDialog;
