import { cn } from "@/lib/utils";
import * as SelectPrimitive from "@radix-ui/react-select";
import React from "react";

const SelectLabel = React.forwardRef<
	React.ElementRef<typeof SelectPrimitive.Label>,
	React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({ className, ...props }, ref) => {
	return (
		<div className={cn("flex gap-2 items-center")}>
			<div className="w-4 h-px bg-border self-center" />
			<SelectPrimitive.Label
				ref={ref}
				className={cn(
					"pt-2 pb-1 pr-2 text-sm font-semibold items-center",
					className,
				)}
				{...props}
			/>
		</div>
	);
});

SelectLabel.displayName = SelectPrimitive.Label.displayName;
export default SelectLabel;
