import RegistrationScreen from "@/components/screens/landingPage/auth/register/registrationScreen";
import * as t from "@/i18n/paraglide/messages";
import { createFileRoute } from "@tanstack/react-router";
import { zodSearchValidator } from "@tanstack/router-zod-adapter";
import { z } from "zod";

const registrationScreenSchema = z.object({
	verificationSuccess: z.coerce.boolean().optional(),
});

export const Route = createFileRoute("/_landingPageLayout/(auth)/registration")(
	{
		component: RegistrationScreen,
		validateSearch: zodSearchValidator(registrationScreenSchema),
		beforeLoad: () => {
			document.title = `${import.meta.env.VITE_APP_NAME} • ${t.registrationMetaTitle()}`;
		},
	},
);
