import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import type { FileEntity } from "shared-types";
import apiClient from "../api";
import type { IServerRes } from "../responses";

const useFetchFileUploadUrl = () => {
	return useMutation<
		string,
		Error,
		{
			file: File;
			fileName: string;
			entity?: FileEntity;
			entityId?: string;
			onProgress: (progress: number) => void;
		}
	>({
		mutationFn: async ({
			file,
			fileName,
			entity,
			entityId,
			onProgress,
		}): Promise<string> => {
			const response = (await apiClient
				.get(`files`, {
					searchParams: {
						fileExtension: file.type.split("/")[1],
						fileName,
						...(entity ? { parsedEntity: entity.toString() } : {}),
						...(entityId ? { entityId } : {}),
					},
				})
				.json()) as IServerRes<string>;

			if (!response.data) {
				throw new Error("No URL from server");
			}

			const xhr = new XMLHttpRequest();
			xhr.open("PUT", response.data);

			xhr.upload.onprogress = (event) => {
				if (event.lengthComputable) {
					const progress = (event.loaded / event.total) * 100;
					onProgress(progress);
				}
			};

			return new Promise<string>((resolve, reject) => {
				xhr.onload = () => {
					if (xhr.status === 200) {
						resolve(response.data!.split("?")[0]);
					} else {
						reject(new Error("Upload failed"));
					}
				};

				xhr.onerror = () => {
					reject(new Error("Upload failed"));
				};

				xhr.setRequestHeader("Content-Type", file.type);
				xhr.send(file);
			});
		},
		onError: (error) => {
			toast.error((error as Error).message, {
				theme: "dark",
			});
		},
	});
};

export default useFetchFileUploadUrl;
