import type { UniqueIdentifier } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { Dispatch, SetStateAction } from "react";
import { ContentItemType } from "shared-types";
import type {
	ContentItem,
	FlattenedContentItem,
	HandleDragEndProps,
} from "./contentTreeTypes";

export function handleDragEnd({ event, setItems }: HandleDragEndProps) {
	const { active, over } = event;

	if (active.id !== over?.id) {
		setItems((items) => {
			const oldIndex = items
				.map((item) => item.id as UniqueIdentifier)
				.indexOf(active.id);
			const newIndex = items
				.map((item) => item.id as UniqueIdentifier)
				.indexOf(over?.id as UniqueIdentifier);

			return arrayMove(items, oldIndex, newIndex);
		});
	}
}

export function flatten(
	items: Array<ContentItem>,
	parentId: UniqueIdentifier | null = null,
	depth = 0,
): Array<FlattenedContentItem> {
	return items.reduce<Array<FlattenedContentItem>>((acc, item, index) => {
		return [
			...acc,
			{
				...item,
				parentId,
				depth,
				index,
				collapsed: item.type === ContentItemType.Folder ? false : undefined,
			} as FlattenedContentItem,
			...(item.items ? flatten(item.items, item.id, depth + 1) : []),
		];
	}, []);
}

export function removeChildrenOf(
	items: Array<FlattenedContentItem>,
	ids: Array<UniqueIdentifier>,
) {
	const excludeParentIds = [...ids];

	return items.filter((item) => {
		if (item.parentId && excludeParentIds.includes(item.parentId)) {
			if (item.items?.length) {
				excludeParentIds.push(item.id);
			}
			return false;
		}
		return true;
	});
}

function getDragDepth(offset: number, indentationWidth: number) {
	return Math.round(offset / indentationWidth);
}

function getMaxDepth({ previousItem }: { previousItem: FlattenedContentItem }) {
	if (previousItem) {
		return previousItem.depth + 1;
	}
	return 0;
}

function getMinDepth({ nextItem }: { nextItem: FlattenedContentItem }) {
	if (nextItem) {
		return nextItem.depth;
	}
	return 0;
}

export function getProjection(
	items: Array<FlattenedContentItem>,
	activeId: UniqueIdentifier,
	overId: UniqueIdentifier,
	dragOffset: number,
	indentationWidth: number,
) {
	const overItemIndex = items.findIndex(({ id }) => id === overId);
	const activeItemIndex = items.findIndex(({ id }) => id === activeId);
	const activeItem = items[activeItemIndex];
	const newItems = arrayMove(items, activeItemIndex, overItemIndex);
	const previousItem = newItems[overItemIndex - 1];
	const nextItem = newItems[overItemIndex + 1];
	const dragDepth = getDragDepth(dragOffset, indentationWidth);
	const projectedDepth = activeItem.depth + dragDepth;
	const maxDepth = getMaxDepth({
		previousItem,
	});
	const minDepth = getMinDepth({ nextItem });
	let depth = projectedDepth;

	if (projectedDepth >= maxDepth) {
		depth = maxDepth;
	} else if (projectedDepth < minDepth) {
		depth = minDepth;
	}

	return { depth, maxDepth, minDepth, parentId: getParentId() };

	function getParentId() {
		if (depth === 0 || !previousItem) {
			return null;
		}

		if (depth === previousItem.depth) {
			return previousItem.parentId;
		}

		if (depth > previousItem.depth) {
			return previousItem.id;
		}

		const newParent = newItems
			.slice(0, overItemIndex)
			.reverse()
			.find((item) => item.depth === depth)?.parentId;

		return newParent ?? null;
	}
}

export function setProperty<T extends keyof FlattenedContentItem>(
	items: Array<FlattenedContentItem>,
	id: UniqueIdentifier,
	property: T,
	setter: (value: FlattenedContentItem[T]) => FlattenedContentItem[T],
) {
	for (const item of items) {
		if (item.id === id) {
			item[property] = setter(item[property]);
			continue;
		}

		if (item.items?.length) {
			item.items = setProperty(item.items, id, property, setter);
		}
	}
	return [...items];
}

export function handleCollapse(
	id: UniqueIdentifier,
	flattenedItems: Array<FlattenedContentItem>,
) {
	setProperty(flattenedItems, id, "collapsed", (value) => {
		return !value;
	});
}
