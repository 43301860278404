import { QueryKeys } from "@/lib/enums/enums";
import type { IServerRes } from "@/lib/responses";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import type { IContent, IContentClient } from "shared-types";
import deleteContent from "../api/deleteContent";

/**
 * Custom hook to handle the deletion of content using React Query's `useMutation`.
 *
 * @param clearRowIds - A callback function to clear the selected row IDs after successful deletion.
 *
 * @returns A mutation object from `useMutation` to handle the deletion process.
 *
 * The hook performs the following actions:
 * - Cancels any ongoing queries for content.
 * - Optimistically updates the content list by removing the deleted content.
 * - Reverts the optimistic update if the mutation fails.
 * - Shows a success or error toast notification based on the mutation result.
 * - Invalidates the content query to refetch the updated list of content.
 */
const useDeleteContent = (clearRowIds: () => void) => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: deleteContent,
		onMutate: async ({ contentIds }: { contentIds: Array<IContentClient["id"]> }) => {
			await queryClient.cancelQueries({ queryKey: [QueryKeys.Content] });

			const previousContent = queryClient.getQueryData<
				IServerRes<Array<IContentClient>>
			>([QueryKeys.Content]);

			if (!previousContent) return;

			queryClient.setQueryData([QueryKeys.Content], {
				...previousContent,
				data: [
					...(previousContent.data
						? previousContent.data.filter(
								(content) => !contentIds.includes(content._id),
							)
						: []),
				],
			});
			return { previousContent };
		},

		onError: (error, values, context) => {
			queryClient.setQueryData([QueryKeys.Content], context?.previousContent);

			toast.error((error as any).response.data.error, {
				theme: "dark",
			});
		},
		onSuccess: () => {
			clearRowIds();
			toast.success("Content deleted", {
				theme: "dark",
			});
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: [QueryKeys.Content] });
		},
	});
};

export default useDeleteContent;
