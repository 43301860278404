import * as t from "@/i18n/paraglide/messages";
import {
	faFileLines,
	faFolders,
	faImage,
	faVideo,
} from "@fortawesome/pro-duotone-svg-icons";
import { ContentItemType } from "shared-types";
import type { ContentTypeCardDataType } from "./contentTreeTypes";

export const ContentTypeCardData: ContentTypeCardDataType[] = [
	{
		id: 1,
		name: t.folder(),
		type: ContentItemType.Folder,
		Icon: faFolders,
		colors: {
			primary: "sea-green-9",
			secondary: "sea-green-6",
			tertiary: "sea-green-1",
		},
	},
	{
		id: 2,
		name: t.image(),
		type: ContentItemType.Image,
		Icon: faImage,
		colors: {
			primary: "cerise-pink",
			secondary: "cerise-pink-6",
			tertiary: "cerise-pink-1",
		},
	},
	{
		id: 3,
		name: t.document(),
		type: ContentItemType.Document,
		Icon: faFileLines,
		colors: {
			primary: "coral-9",
			secondary: "coral-6",
			tertiary: "coral-1",
		},
	},
	{
		id: 4,
		name: t.video(),
		type: ContentItemType.Video,
		Icon: faVideo,
		colors: {
			primary: "khaki-9",
			secondary: "khaki-6",
			tertiary: "khaki-1",
		},
	},
];
