import api from "@/lib/api";
import { QueryKeys } from "@/lib/enums/enums";
import type { IServerRes } from "@/lib/responses";
import type { IUserClient } from "shared-types";

/**
 * @description Delete multiple users by their IDs.
 *
 * @param {Object} params - The parameters for the delete request.
 * @param {string} params.userIds - Array of user IDs to be deleted.
 *
 * @returns {Promise<IServerRes>} A promise that resolves to a server response.
 *
 * @throws {Error} Throws an error if the delete request fails.
 */
export const deleteUsers = async ({
	userIds,
}: { userIds: Array<IUserClient["_id"]> }): Promise<IServerRes> => {
	const response: IServerRes = await api
		.post(`${QueryKeys.Users}/delete`, {
			json: userIds,
		})
		.json<IServerRes>();
	return response;
};

export default deleteUsers;
