import Button from "@/components/ui/button/button";
import {
	ButtonIconSide,
	ButtonSize,
	ButtonVariant,
} from "@/components/ui/button/helpers/buttonTypes";
import {
	DndContext,
	KeyboardSensor,
	PointerSensor,
	closestCenter,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import {
	SortableContext,
	sortableKeyboardCoordinates,
	verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
	faArrowsFromLine,
	faArrowsToLine,
	faPlus,
} from "@fortawesome/pro-light-svg-icons";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { IContentItemClient } from "shared-types";
import type { IContentItemClientWithCollapse } from "../../data/contentFormSchema";
import useContentFormField from "../../hooks/useContentItemFormField";
import { ContentTreeItem } from "./components/contentTreeItem";
import CreateContentTreeItemDialog from "./components/createContentTreeItemDialog";
import { handleDragEnd } from "./helpers/contentTreeUtils";

interface ContentTreeProps {
	indentationWidth?: number;
	control: any;
}

export const ContentTree: React.FC<ContentTreeProps> = ({
	control,
	indentationWidth = 15,
}) => {
	const { fields, addItem, removeItem, setValue } = useContentFormField("");

	const { watch } = useFormContext();
	const watchedItems = watch("items");

	const [isOpen, setIsOpen] = useState(false);

	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		}),
	);

	const moveItem = (fromIndex: number, toIndex: number) => {
		//move(fromIndex, toIndex);
	};

	const toggleCollapse = (path: string) => {
		const item = getItemByPath(watchedItems, path);
		if (item) {
			setValue(`${path}.collapsed`, !item.collapsed);
		}
	};

	console.log("watched", watchedItems);

	const getItemByPath = (
		items: IContentItemClientWithCollapse[],
		path: string,
	) => {
		return path.split(".").reduce((acc: any, key) => {
			return acc && acc[key] !== undefined ? acc[key] : null;
		}, items);
	};

	const renderTree = (items: any, depth = 0, parentPath = "items") => {
		return items.map((item: IContentItemClientWithCollapse, index: number) => {
			const currentPath = `${parentPath}.${index}`;

			return (
				<div
					key={item._id}
					className="gap-2.5 flex flex-col"
				>
					<div className="flex items-center" style={{ marginLeft: depth * indentationWidth }}>
						<ContentTreeItem
							data={item}
							depth={depth}
							index={index}
							removeItem={removeItem}
							addItem={addItem}
							prefix={currentPath}
							collapsed={item.collapsed}
							onCollapse={() => toggleCollapse(currentPath)}
						/>
					</div>
					{item.items && item.items.length > 0 && !item.collapsed && (
						<div className="flex flex-col gap-2.5">
							{renderTree(item.items, depth + 1, `${currentPath}.items`)}
						</div>
					)}
				</div>
			);
		});
	};

	return (
		<>
			<div className="flex flex-col gap-6 w-full">
				<div className="flex gap-2.5 rounded-t-md border-b px-6 py-3.5">
					<Button
						size={ButtonSize.Small}
						variant={ButtonVariant.Outline}
						icon={faPlus}
						onClick={() => setIsOpen(true)}
						className="w-fit px-2"
					/>
					<Button
						size={ButtonSize.Small}
						variant={ButtonVariant.Outline}
						icon={faArrowsFromLine}
						className="w-fit px-2"
					/>
					<Button
						size={ButtonSize.Small}
						variant={ButtonVariant.Outline}
						icon={faArrowsToLine}
						className="w-fit px-2"
					/>
				</div>
				<div className="px-3">
				<div className="scroll-shadow">
				<div className="overflow-scroll pb-6 pl-3">
					<DndContext
						sensors={sensors}
						collisionDetection={closestCenter}
						onDragEnd={(event) =>
							handleDragEnd({ event, setItems: () => moveItem })
						}
					>
						<SortableContext
							items={fields}
							strategy={verticalListSortingStrategy}
						>
							<div className="flex flex-col gap-2.5">
								{watchedItems.length === 0 ? (
									<div className="flex items-center justify-center">
										<Button
											label="Add new item"
											size={ButtonSize.Small}
											variant={ButtonVariant.Fill}
											icon={faPlus}
											iconSide={ButtonIconSide.Left}
											onClick={() => setIsOpen(true)}
										/>
									</div>
								) : (
									renderTree(watchedItems)
								)}
							</div>
						</SortableContext>
					</DndContext>
				</div>
				</div>
				</div>
			</div>
			<CreateContentTreeItemDialog
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				addItem={addItem}
			/>
		</>
	);
};
