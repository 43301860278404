import { QueryKeys } from "@/lib/enums/enums";
import {
	keepPreviousData,
	queryOptions,
	useQuery,
} from "@tanstack/react-query";
import fetchAllUsers from "../api/fetchAllUsers";

export const useFetchAllUsers = () => {
	return useQuery({
		queryKey: [QueryKeys.Users],
		queryFn: () => fetchAllUsers(),
		placeholderData: keepPreviousData,
	});
};

export const queryAllUsers = queryOptions({
	queryKey: [QueryKeys.Users],
	queryFn: () => fetchAllUsers(),
	placeholderData: keepPreviousData,
});
