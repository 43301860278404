import { useTableActionBarState } from "@/store/tableActionBarState";
import {
	faCopy,
	faListCheck,
	faTrashCan,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Button from "../../button/button";
import {
	ButtonColor,
	ButtonIconSide,
	ButtonSize,
	ButtonVariant,
} from "../../button/helpers/buttonTypes";
import { IconSize } from "../../icon/helpers/iconTypes";
import type { TableActionBarProps } from "../helpers/tableTypes";

function TableActionBar<T>({
	selectedRows,
	onDelete,
	onDuplicate,
	rowsSelected,
}: TableActionBarProps<T>) {
	const [mounted, setMounted] = useState(false);
	const isOpen = useTableActionBarState((state) => state.isOpen);

	const handleDelete = () => {
		onDelete();
		rowsSelected();
	};

	useEffect(() => {
		setMounted(true);
		return () => setMounted(false);
	}, []);

	if (!mounted) return null;

	const appRoot = document.getElementById("app-root");
	if (!appRoot) return null;

	return ReactDOM.createPortal(
		<>
			{isOpen && (
				<div className="fixed bottom-[55px] left-[calc(50%-168px)] m-auto flex items-center gap-6 rounded-md border bg-background-tertiary px-8 py-4 sm:bottom-[55px] sm:left-[calc(50%-120px)] md:bottom-[50px] md:left-[calc(calc(50%-207px)+165px)]">
					<div className="flex items-center gap-2">
						<span className="text-sm">{selectedRows?.length}</span>
						<FontAwesomeIcon icon={faListCheck} size={IconSize.Medium} />
					</div>

					<div className="flex gap-4">
						<Button
							label="Löschen"
							variant={ButtonVariant.Outline}
							size={ButtonSize.ExtraSmall}
							color={ButtonColor.Error}
							iconSide={ButtonIconSide.Left}
							icon={faTrashCan}
							className="text-error"
							onClick={handleDelete}
						/>
						<Button
							label="Kopieren"
							variant={ButtonVariant.Outline}
							size={ButtonSize.ExtraSmall}
							color={ButtonColor.Warning}
							iconSide={ButtonIconSide.Left}
							icon={faCopy}
							className="text-warning"
							onClick={() => onDuplicate()}
						/>
					</div>
				</div>
			)}
		</>,
		document.getElementById("app-root") as Element,
	);
}

TableActionBar.displayName = "TableActionBar";
export default TableActionBar;
