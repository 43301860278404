import * as t from "@/i18n/paraglide/messages";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute(
	"/_landingPageLayout/(legal)/terms-of-service",
)({
	component: () => <div>Hello /_landingPageLayout/terms-of-use!</div>,
	beforeLoad: () => {
		document.title = `${import.meta.env.VITE_APP_NAME} • ${t.termsOfServiceMetaTitle()}`;
	},
});
