
export enum OrganisationStatus {
	Active = "active",
	Inactive = "inactive",
	Unverified = "unverified",
}

export enum PaymentPlan {
	Basic = "basic",
	Pro = "pro",
  Enterprise = "enterprise",
}