import SectionWrapper from "@/components/layout/sectionWrapper/sectionWrapper";
import * as t from "@/i18n/paraglide/messages";
import { useState } from "react";
import FaqQuestions from "./components/faqQuestions";
import FaqTabs from "./components/faqTabs";
import { FaqTabData } from "./helpers/faqData";

const Faq: React.FC = () => {
	const [selectedTab, setSelectedTab] = useState(FaqTabData[0].key);

	return (
		<SectionWrapper title={t.faq()} subtitle={t.faqDescription()}>
			<div className="flex flex-col gap-0 md:gap-4">
				<FaqTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
				<FaqQuestions selectedTab={selectedTab} />
			</div>
		</SectionWrapper>
	);
};

Faq.displayName = "Faq";
export default Faq;
