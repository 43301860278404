import { QueryKeys } from "@/lib/enums/enums";
import {
	keepPreviousData,
	queryOptions,
	useQuery,
} from "@tanstack/react-query";
import type { IContentClient } from "shared-types";
import { fetchOneContent } from "../api/fetchOneContent";

export const useFetchOneContent = ({
	contentId,
}: { contentId: IContentClient["_id"] }) => {
	return useQuery({
		queryKey: [QueryKeys.Content, contentId],
		queryFn: () => fetchOneContent({ contentId }),
		placeholderData: keepPreviousData,
		enabled: !!contentId,
	});
};

export const queryOneContent = ({
	contentId,
}: { contentId: IContentClient["_id"] }) =>
	queryOptions({
		queryKey: [QueryKeys.Content, contentId],
		queryFn: () => fetchOneContent({ contentId }),
		placeholderData: keepPreviousData,
		enabled: !!contentId,
	});
