export enum ContentStatus {
	Active = "active",
	Inactive = "inactive",
}

export enum ContentItemType {
	Folder = "folder",
	Image = "image",
	Document = "document",
	Video = "video",
}

export enum ContentItemStatus {
	Visible = "visible",
	Invisible = "invisible",
}