import type { DataCardProps } from "./helpers/dataCardTypes";

const DataCard: React.FC<DataCardProps> = ({ children, className }) => {
	return (
		<div
			className={`flex h-min flex-col gap-5 rounded-md border bg-[#2B3540] ${className} p-6`}
		>
			{children}
		</div>
	);
};

DataCard.displayName = "DataCard";
export default DataCard;
