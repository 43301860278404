import { cn } from "@/lib/utils";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { forwardRef } from "react";
import { IconSize } from "../icon/helpers/iconTypes";
import { Icon } from "../icon/icon";
import { getButtonIconStyling, getButtonStyling } from "./helpers/buttonStyles";
import {
	ButtonIconSide,
	type ButtonProps,
	ButtonSize,
	ButtonType,
	ButtonVariant,
} from "./helpers/buttonTypes";

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
	(
		{
			label,
			ariaLabel,
			className,
			variant = ButtonVariant.Fill,
			size = ButtonSize.Medium,
			color,
			icon,
			iconSide,
			isLoading = false,
			isDisabled = false,
			onClick,
			isCustomButton = false,
			hasNotification = false,
			hasAvatar = false,
			avatar,
			children,
			type = ButtonType.Button,
			isResponsive = false,
			duotoneIconColors,
			...props
		},
		ref,
	) => {
		const buttonClassNames = cn(
			getButtonStyling({ variant, size, color, hasNotification }),
			className,
		);

		const iconClassNames = getButtonIconStyling({
			color,
			iconSide,
			isResponsive,
			variant,
		});

		return (
			<button
				ref={ref}
				type={type}
				className={buttonClassNames}
				aria-label={ariaLabel}
				onClick={onClick}
				disabled={isLoading || isDisabled}
				{...props}
			>
				{isLoading ? (
					<Icon
						fixedWidth
						size={ButtonSize.Large}
						className="animate-spin"
						icon={faSpinnerThird}
					/>
				) : isCustomButton ? (
					<>{children}</>
				) : (
					<>
						{label ? (
							<div className="flex items-center">
								{icon && iconSide === ButtonIconSide.Left && (
									<>
										{icon && duotoneIconColors ? (
											<Icon
												fixedWidth
												icon={icon}
												className={iconClassNames}
												primaryColor={duotoneIconColors.primary}
												secondaryColor={duotoneIconColors.secondary}
											/>
										) : (
											<FontAwesomeIcon
												fixedWidth
												size={IconSize.Medium}
												className={iconClassNames}
												icon={icon}
											/>
										)}
									</>
								)}
								<span className={`${isResponsive ? "hidden sm:block" : ""}`}>
									{label}
								</span>
								{icon && iconSide === ButtonIconSide.Right && (
									<FontAwesomeIcon
										fixedWidth
										size={IconSize.Medium}
										className={iconClassNames}
										icon={icon}
									/>
								)}
							</div>
						) : (
							<>
								{icon && (
									<>
										<FontAwesomeIcon
											fixedWidth
											size={
												size === ButtonSize.ExtraSmall
														? IconSize.Small
														: size === ButtonSize.Small
																? IconSize.Medium
																: IconSize.Large
										}
											className={iconClassNames}
											icon={icon}
										/>
										{hasNotification && (
											<span className="absolute inset-x-0 -bottom-px block h-px w-full bg-gradient-to-r from-transparent via-amber-500 to-transparent" />
										)}
									</>
								)}
							</>
						)}
					</>
				)}
			</button>
		);
	},
);

Button.displayName = "Button";
export default Button;
