import Button from "@/components/ui/button/button";
import { ButtonType } from "@/components/ui/button/helpers/buttonTypes";
import Dialog from "@/components/ui/dialog/dialog";
import FormTextInput from "@/components/ui/form/components/formTextInput/formTextInput";
import { Form } from "@/components/ui/form/form";
import * as t from "@/i18n/paraglide/messages";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "@tanstack/react-router";
import type { FC } from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import { ContentStatus } from "shared-types";
import { z } from "zod";
import useCreateContent from "../hooks/useCreateContent";

export interface CreateContent {
	name: string;
	status: ContentStatus;
}

export interface CreateContentDialogProps {
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
}

const ContentCreateDialog: FC<CreateContentDialogProps> = ({
	isOpen,
	setIsOpen,
}) => {
	const { mutate: createContent } = useCreateContent();
	const navigator = useNavigate({ from: "/app/content" });

	const CreateContentSchema = z.object({
		name: z.string({ message: t.validationRequired({ fieldName: t.name() }) }),
		status: z.nativeEnum(ContentStatus).default(ContentStatus.Active),
	});

	const form = useForm<z.infer<typeof CreateContentSchema>>({
		resolver: zodResolver(CreateContentSchema),
		defaultValues: {
			status: ContentStatus.Active,
		},
		mode: "onBlur",
		shouldFocusError: true,
	});

	const onSubmit: SubmitHandler<CreateContent> = async (values) => {
		createContent(values, {
			onSuccess: (data) => {
				navigator({
					to: `/app/content/${data.data._id}`,
				});
			},
		});
	};

	return (
		<Form {...form}>
			<Dialog
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				title={t.newContentCollection()}
				actionButtons={[
					<Button
						isLoading={form.formState.isSubmitting}
						disabled={!form.formState.isValid}
						label={t.add()}
						type={ButtonType.Submit}
						onClick={() => form.handleSubmit(onSubmit)()}
					/>,
				]}
			>
				<div className="flex flex-col gap-4">
					<span>Wie möchten Sie die neue Kollektion nennen?</span>
					<div className="flex flex-col gap-4">
						<div className="w-full">
							<FormTextInput
								form={form}
								name="name"
								placeholder="Name"
								label="Name"
								isRequired
							/>
						</div>
					</div>
				</div>
			</Dialog>
		</Form>
	);
};

ContentCreateDialog.displayName = "ContentCreateDialog";
export default ContentCreateDialog;
