import { FadeInWrapper } from "@/components/layout/fadeInWrapper/fadeInWrapper";
import LegalLinks from "@/components/ui/legalLinks/legalLinks";
import * as t from "@/i18n/paraglide/messages";
import { ResetPasswordForm } from "./resetPasswordForm";

const ResetPasswordScreen: React.FC = () => {
	return (
		<div className="relative flex h-[calc(100vh-5rem)] items-center justify-center py-12 px-6 md:p-0">
			<img
				src="/images/authBackground.svg"
				alt="Background"
				className="absolute m-auto size-[90%] z-0  hidden md:block"
			/>
			<div className="mx-auto flex w-full flex-col justify-center sm:w-[450px]">
				<FadeInWrapper>
					<div className="flex flex-col gap-4 md:gap-6">
						<div className="flex flex-col space-y-2 text-center">
							<span className="text-3xl font-bold">
								{t.resetPasswordTitle()}
							</span>
							<p className="text-sm text-muted-foreground">
								{t.resetPasswordSubtitle()}
							</p>
						</div>
						<div className="flex flex-col gap-2">
							<ResetPasswordForm />
							<LegalLinks />
						</div>
					</div>
				</FadeInWrapper>
			</div>
		</div>
	);
};

ResetPasswordScreen.displayName = "ResetPasswordScreen";
export default ResetPasswordScreen;
