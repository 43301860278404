import { QueryKeys } from "@/lib/enums/enums";
import type { IServerRes } from "@/lib/responses";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import type { IEventClient } from "shared-types";
import deleteEvents from "../api/deleteEvents";

/**
 * Custom hook to handle the deletion of events using React Query's `useMutation`.
 *
 * @param clearRowIds - A callback function to clear the selected row IDs after successful deletion.
 *
 * @returns A mutation object from `useMutation` to handle the deletion process.
 *
 * The hook performs the following actions:
 * - Cancels any ongoing queries for events.
 * - Optimistically updates the events list by removing the deleted events.
 * - Reverts the optimistic update if the mutation fails.
 * - Shows a success or error toast notification based on the mutation result.
 * - Invalidates the events query to refetch the updated list of events.
 */
const useDeleteEvents = (clearRowIds: () => void) => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: deleteEvents,
		onMutate: async ({ eventIds }: { eventIds: Array<IEventClient["id"]> }) => {
			await queryClient.cancelQueries({ queryKey: [QueryKeys.Events] });

			const previousEvents = queryClient.getQueryData<
				IServerRes<Array<IEventClient>>
			>([QueryKeys.Events]);

			if (!previousEvents) return;

			queryClient.setQueryData([QueryKeys.Events], {
				...previousEvents,
				data: [
					...(previousEvents.data
						? previousEvents.data.filter(
								(event) => !eventIds.includes(event._id),
							)
						: []),
				],
			});
			return { previousEvents };
		},

		onError: (error, values, context) => {
			queryClient.setQueryData([QueryKeys.Events], context?.previousEvents);

			toast.error((error as any).response.data.error, {
				theme: "dark",
			});
		},
		onSuccess: () => {
			clearRowIds();
			toast.success("Events deleted", {
				theme: "dark",
			});
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: [QueryKeys.Events] });
		},
	});
};

export default useDeleteEvents;
