import { cn } from "@/lib/utils";
import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
	faArrowDown,
	faArrowUp,
	faEyeSlash,
	faSort,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { Column } from "@tanstack/react-table";
import { Button } from "react-aria-components";
import { ButtonSize, ButtonVariant } from "../../button/helpers/buttonTypes";

interface DataTableColumnHeaderProps<TData, TValue>
	extends React.HTMLAttributes<HTMLDivElement> {
	column: Column<TData, TValue>;
	title: string;
	Icon?: IconProp;
}

export function DataTableColumnHeader<TData, TValue>({
	column,
	title,
	className,
	Icon,
}: DataTableColumnHeaderProps<TData, TValue>) {
	if (!column.getCanSort()) {
		return (
			<div className="flex items-center gap-2">
				{Icon && (
					<span>
						{" "}
						<FontAwesomeIcon icon={Icon} />
					</span>
				)}
				<span className={cn(className)}>{title}</span>
			</div>
		);
	}

	return (
		<div className={cn("flex items-center space-x-2", className)}>
			{/* TODO: Refacot dropdown
		<DropdownMenu>
				<DropdownMenuTrigger asChild>
					<Button
						variant={ButtonVariant.Ghost}
						size={ButtonSize.Small}
						className="-ml-3 h-8 data-[state=open]:bg-accent"
					>
						{Icon && <FontAwesomeIcon icon={Icon} className="mr-2" />}
						<span>{title}</span>
						{column.getIsSorted() === "desc" ? (
							<FontAwesomeIcon icon={faArrowUp} className="ml-2 size-4" />
						) : column.getIsSorted() === "asc" ? (
							<FontAwesomeIcon icon={faArrowDown} className="ml-2 size-4" />
						) : (
							<FontAwesomeIcon icon={faSort} className="ml-2 size-4" />
						)}
					</Button>
				</DropdownMenuTrigger>
				<DropdownMenuContent align="start">
					<DropdownMenuItem onClick={() => column.toggleSorting(false)}>
						<FontAwesomeIcon
							icon={faArrowUp}
							className="text-muted-foreground/70 mr-2 size-3.5"
						/>
						Asc
					</DropdownMenuItem>
					<DropdownMenuItem onClick={() => column.toggleSorting(true)}>
						<FontAwesomeIcon
							icon={faArrowDown}
							className="text-muted-foreground/70 mr-2 size-3.5"
						/>
						Desc
					</DropdownMenuItem>
					<DropdownMenuSeparator />
					<DropdownMenuItem onClick={() => column.toggleVisibility(false)}>
						<FontAwesomeIcon
							icon={faEyeSlash}
							className="text-muted-foreground/70 mr-2 size-3.5"
						/>
						Hide
					</DropdownMenuItem>
				</DropdownMenuContent>
			</DropdownMenu>*/}
		</div>
	);
}
