export interface UsageCardType {
	id: number;
	image: string;
	futureFeature: boolean;
	title: string;
	description: string;
}

export const usageCardHeight = 350;
export const usageCardWidth = 350;
export const usageCardMargin = 20;
export const usageCardSize = usageCardWidth + usageCardMargin;
