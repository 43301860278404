import * as t from "@/i18n/paraglide/messages";
import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
	faEnvelopeOpenDollar,
	faSquareInfo,
} from "@fortawesome/pro-light-svg-icons";

export enum BillingTabIds {
	Details = "details",
	Invoices = "invoices",
}

export interface BillingTabDataProps {
	id: string;
	name: string;
	Icon?: IconProp;
}

export const BillingTabData: BillingTabDataProps[] = [
	{
		id: BillingTabIds.Details,
		name: t.details(),
		Icon: faSquareInfo,
	},
	{
		id: BillingTabIds.Invoices,
		name: t.invoices(),
		Icon: faEnvelopeOpenDollar,
	},
];
