import DataCard from "@/components/ui/dataCard/dataCard";
import FormItem from "@/components/ui/form/components/formItem/formItem";
import FormLabel from "@/components/ui/form/components/formLabel/formLabel";
import FormTextInput from "@/components/ui/form/components/formTextInput/formTextInput";
import { FormControl, FormField } from "@/components/ui/form/form";
import StatusSelect from "@/components/ui/statusSelect/statusSelect";
import * as t from "@/i18n/paraglide/messages";
import { useContentItemStore } from "@/store/contentItemStore";
import { useFormContext } from "react-hook-form";
import { ContentTree } from "../../components/contentTree/contentTree";
import FileChart from "../../components/fileChart/fileChart";
import StorageChart from "../../components/storageChart/storageChart";
import type { ContentDetailDataTabProps } from "../../helpers/contentTypes";
import ContentTypeDisplayField from "../../components/contentTree/components/contentTypeDisplayField";
import { ContentItemStatus, ContentStorage } from "shared-types";
import { formatFileSize } from "@/lib/formatFileSize";

const ContentDetailDataTab: React.FC<ContentDetailDataTabProps> = () => {
	const form = useFormContext();
	const storage: ContentStorage = form.getValues("storage");

	const contentItemPath = useContentItemStore((state) => state.contentItemPath);
	const contentItem = useContentItemStore((state) => state.contentItem);

	console.log("test", contentItemPath);
	console.log("test22", form.getValues(contentItemPath));

	return (
		<div className="flex flex-col gap-4 lg:gap-8">
			<div className="flex w-full flex-col gap-4 lg:gap-8 lg:flex-row">
				<div className="flex flex-col lg:w-2/5">
					<DataCard>
						<div className="flex flex-col gap-5 sm:flex-row">
							{/* Name */}
							<div className="w-full sm:w-3/4">
								<FormTextInput
									form={form}
									name="name"
									placeholder="Name"
									label="Name"
									isRequired
								/>
							</div>
							{/* Status */}
							<div className="w-full sm:w-1/4">
								<FormField
									name={"status"}
									control={form.control}
									render={(control) => (
										<FormItem className="flex flex-col gap-1">
											<FormLabel>{t.status()}</FormLabel>
											<FormControl>
												<StatusSelect
													selectedStatus={control.field.value}
													statusOptions={[
														{
															label: t.active(),
															value: "active",
															primaryColor: "success",
															secondaryColor: "success",
														},
														{
															label: t.inactive(),
															value: "inactive",
															primaryColor: "warning",
															secondaryColor: "warning",
														},
													]}
													onSelect={control.field.onChange}
												/>
											</FormControl>
										</FormItem>
									)}
								/>
							</div>
						</div>
						<div className="flex flex-col gap-5 sm:flex-row">
							{/* Language */}
							<FormTextInput
								form={form}
								name="language"
								placeholder="Language"
								label="Language"
								isRequired
							/>
						</div>
					</DataCard>
				</div>
				<div className="flex flex-col lg:w-3/5">
					<div className="flex flex-col gap-4 lg:gap-8 lg:flex-row items-stretch">
						<DataCard className="lg:w-1/3 px-0 py-0 flex-grow">
							<StorageChart
								totalStorage={storage.storageTotal}
								chartData={[
									{
										documents: storage.documents.storage,
										images: storage.images.storage,
										videos: storage.videos.storage,
										folders: storage.folders.storage,
									},
								]}
							/>
						</DataCard>
						<DataCard className="lg:w-1/3 px-0 py-0 flex-grow">
							<FileChart
								totalFiles={storage.fileCountTotal}
								chartData={[
									{
										documents: storage.documents.count,
										images: storage.images.count,
										videos: storage.videos.count,
										folders: storage.folders.count,
									},
								]}
							/>
						</DataCard>
					</div>
				</div>
			</div>
			<div className="flex w-full flex-col gap-4 lg:gap-8 lg:flex-row">
				<DataCard className="lg:w-2/5 px-0 py-0">
					<div className="flex gap-5">
						<ContentTree control={form.control} />
					</div>
				</DataCard>
				<DataCard className="lg:w-3/5">
				{!contentItemPath ? (
					<div>Select Item</div>
				) : (
					<div className="flex flex-col gap-5">
						<div className="flex gap-5 w-full">
							<div className="w-[55%]">
							<FormTextInput
									form={form}
									key={contentItemPath}
									name={`${contentItemPath}.name`}
									placeholder="Name"
									label="Name"
									isRequired
								/>
								</div>
								<div className="w-[22.5%]">
								<ContentTypeDisplayField contentType={form.getValues(`${contentItemPath}.type`)} />
								</div>
								<div className="w-[22.5%]">
								<FormField
									name={"status"}
									control={form.control}
									render={(control) => (
										<FormItem className="flex flex-col gap-1">
											<FormLabel>{t.status()}</FormLabel>
											<FormControl>
												<StatusSelect
													selectedStatus={control.field.value}
													statusOptions={[
														{
															label: t.visible(),
															value: ContentItemStatus.Visible,
															primaryColor: "success",
															secondaryColor: "success",
														},
														{
															label: t.invisible(),
															value: ContentItemStatus.Invisible,
															primaryColor: "warning",
															secondaryColor: "warning",
														},
													]}
													onSelect={control.field.onChange}
												/>
											</FormControl>
										</FormItem>
									)}
								/>
							</div>
							</div>
							<div className="flex gap-5">
							</div>
							{form.getValues(contentItemPath)?.file && (
								<>
																	<img
																	src={form.getValues(contentItemPath).file.url}
																	alt={"test"}
																	onLoad={() => console.log("loaded")}
																	onError={() => console.log("error")}
																	className="size-60 rounded-md"
																/>
																</>
							)}
					</div>
					)}
				</DataCard>
			</div>
		</div>
	);
};

ContentDetailDataTab.displayName = "ContentDetailDataTab";
export default ContentDetailDataTab;
