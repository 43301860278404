import React from "react";
import Breadcrumb from "../../../ui/breabcrumb/breadcrumb";
import { Skeleton } from "../../../ui/skeleton/skeleton";
import type { ScreenWrapperProps } from "./helpers/screenWrapperTypes";

const ScreenWrapper: React.FC<ScreenWrapperProps> = ({
	breadcrumbs,
	title,
	actionButtons,
	children,
	isLoading = false,
}) => {
	return (
		<main className="flex flex-col gap-5 p-4 md:gap-8 md:p-10 lg:gap-6">
			<div className="flex flex-col gap-2">
				<Breadcrumb
					isLoading={isLoading}
					breadcrumbs={breadcrumbs}
					page={{
						name: title,
					}}
				/>
				<div>
					<div className="flex justify-between">
						<div className="title-wrap">
							{isLoading || !title ? (
								<Skeleton className="h-9 w-32" />
							) : (
								<h1 className="text-3xl">{title}</h1>
							)}
						</div>
						<div className="flex gap-3">
							{actionButtons?.map((button, index) => (
								<React.Fragment key={index}>{button}</React.Fragment>
							))}
						</div>
					</div>
				</div>
			</div>
			{children}
		</main>
	);
};

ScreenWrapper.displayName = "ScreenWrapper";
export default ScreenWrapper;
