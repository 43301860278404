import type { AvailableLanguageTag } from "@/i18n/paraglide/runtime";
import apiClient from "@/lib/api";
import type { IServerRes } from "@/lib/responses";
import type { RegistrationFormValues } from "../data/registrationFormSchema";

const registration = async ({
	registrationFormValues,
}: {
	registrationFormValues: RegistrationFormValues & {
		language: AvailableLanguageTag;
	};
}): Promise<IServerRes> => {
	const response: IServerRes = await apiClient
		.post(`auth/register`, {
			json: registrationFormValues,
		})
		.json<IServerRes>();
	return response;
};

export default registration;
