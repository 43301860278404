import {
	type ChartConfig,
	ChartContainer,
	ChartLegend,
	ChartLegendContent,
	ChartLegendManualContent,
	ChartTooltip,
	ChartTooltipContent,
} from "@/components/ui/chart/chart";
import * as t from "@/i18n/paraglide/messages";
import type { FC } from "react";
import { Label, PolarRadiusAxis, RadialBar, RadialBarChart } from "recharts";

interface FileChartProps {
	totalFiles: number;
	chartData: [
		{
			documents: number;
			images: number;
			videos: number;
			folders: number;
		},
	];
}

const chartConfig = {
	documents: {
		label: t.documents(),
		color: "var(--coral-9)",
	},
	folders: {
		label: t.folder(),
		color: "var(--sea-green-9)",
	},
	videos: {
		label: t.videos(),
		color: "var(--khaki-9)",
	},
	images: {
		label: t.images(),
		color: "var(--cerise-pink)",
	},
} satisfies ChartConfig;

const FileChart: FC<FileChartProps> = ({ totalFiles, chartData }) => {
	return (
		<ChartContainer
			config={chartConfig}
			className="mx-auto w-full h-full max-h-[222px]"
		>
			<RadialBarChart
				data={chartData}
				endAngle={180}
				innerRadius={80}
				outerRadius={130}
			>
				<ChartTooltip
					cursor={false}
					content={<ChartTooltipContent hideLabel />}
				/>
				<PolarRadiusAxis tick={false} tickLine={false} axisLine={false}>
					<Label
						content={({ viewBox }) => {
							if (viewBox && "cx" in viewBox && "cy" in viewBox) {
								return (
									<text x={viewBox.cx} y={viewBox.cy} textAnchor="middle">
										<tspan
											x={viewBox.cx}
											y={(viewBox.cy || 0) - 16}
											className="fill-foreground-primary text-xl font-bold"
										>
											{totalFiles}
										</tspan>
										<tspan
											x={viewBox.cx}
											y={(viewBox.cy || 0) + 4}
											className="text-[14.5px] fill-foreground-secondary"
										>
											{t.files()}
										</tspan>
									</text>
								);
							}
						}}
					/>
				</PolarRadiusAxis>
				<RadialBar
					dataKey="images"
					fill="var(--color-images)"
					stackId="a"
					cornerRadius={5}
					className="stroke-transparent stroke-2"
				/>
				<RadialBar
					dataKey="folders"
					fill="var(--color-folders)"
					stackId="a"
					cornerRadius={5}
					className="stroke-transparent stroke-2"
				/>
				<RadialBar
					dataKey="documents"
					stackId="a"
					cornerRadius={5}
					fill="var(--color-documents)"
					className="stroke-transparent stroke-2"
				/>
				<RadialBar
					dataKey="videos"
					fill="var(--color-videos)"
					stackId="a"
					cornerRadius={5}
					className="stroke-transparent stroke-2"
				/>
				<ChartLegend
					content={
						<ChartLegendManualContent
							data={[
								{
									dataKey: "documents",
									color: "var(--color-documents)",
									value: `${t.documents()} (${chartData[0]?.documents || 0})`,
								},
								{
									dataKey: "images",
									color: "var(--color-images)",
									value: `${t.images()} (${chartData[0]?.images || 0})`,
								},
								{
									dataKey: "folders",
									color: "var(--color-folders)",
									value: `${t.folder()} (${chartData[0]?.folders || 0})`,
								},
								{
									dataKey: "videos",
									color: "var(--color-videos)",
									value: `${t.videos()} (${chartData[0]?.videos || 0})`,
								},
							]}
						/>
					}
					className="-translate-y-2 flex-wrap gap-2 [&>*]:basis-1/4 [&>*]:justify-center"
				/>
			</RadialBarChart>
		</ChartContainer>
	);
};

FileChart.displayName = "FileChart";
export default FileChart;
