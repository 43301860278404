import { cn } from "@/lib/utils";
import {
	faChevronLeft,
	faChevronRight,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { ComponentProps } from "react";
import { DayPicker } from "react-day-picker";

/**
 * Calendar component that wraps the `DayPicker` component from `react-day-picker`
 * and customizes its appearance and behavior using Tailwind CSS classes and FontAwesome icons.
 *
 * @param {string} className - Additional class names to apply to the calendar container.
 * @param {object} classNames - Custom class names for various parts of the calendar.
 * @param {boolean} [showOutsideDays=true] - Whether to show days from the previous and next months.
 * @param {CalendarProps} props - Additional props to pass to the `DayPicker` component.
 *
 * @returns {JSX.Element} The customized calendar component.
 */
function Calendar({
	className,
	classNames,
	showOutsideDays = true,
	...props
}: ComponentProps<typeof DayPicker>) {
	return (
		<DayPicker
			showOutsideDays={showOutsideDays}
			className={cn("p-3", className)}
			classNames={{
				months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
				month: "space-y-4",
				caption: "flex justify-center pt-1 relative items-center",
				caption_label: "text-sm font-medium",
				nav: "space-x-1 flex items-center",
				nav_button:
					"size-7 inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none ring-offset-background transition duration-300 hover:bg-background-tertiary border-input border",
				nav_button_previous: "absolute left-1",
				nav_button_next: "absolute right-1",
				table: "w-full border-collapse space-y-1",
				head_row: "flex",
				head_cell:
					"text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]",
				row: "flex w-full mt-2",
				cell: "h-9 w-9 text-center text-sm p-0 relative first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
				day: "h-9 w-9 p-0 font-normal aria-selected:opacity-100 hover:bg-primary hover:text-accent-foreground inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors disabled:pointer-events-none disabled:opacity-50",
				day_selected:
					"bg-primary hover:bg-primary focus:bg-primary focus:text-foreground",
				day_today: "border border-primary text-accent-foreground",
				day_outside: "text-muted-foreground opacity-50",
				day_disabled: "text-muted-foreground opacity-50",
				day_range_middle:
					"aria-selected:bg-primary aria-selected:text-accent-foreground",
				day_hidden: "invisible",
				...classNames,
			}}
			components={{
				IconLeft: () => (
					<FontAwesomeIcon icon={faChevronLeft} fixedWidth className="size-4" />
				),
				IconRight: () => (
					<FontAwesomeIcon
						icon={faChevronRight}
						fixedWidth
						className="size-4"
					/>
				),
			}}
			{...props}
		/>
	);
}

Calendar.displayName = "Calendar";
export default Calendar;
