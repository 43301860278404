import * as t from "@/i18n/paraglide/messages";
import {
	type ColumnFiltersState,
	type RowSelectionState,
	type SortingState,
	type VisibilityState,
	flexRender,
	getCoreRowModel,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table";
import { useEffect, useState } from "react";
import TableActionBar from "./components/tableActionBar";
import TableBody from "./components/tableBody";
import TableCell from "./components/tableCell";
import TableHead from "./components/tableHead";
import TableHeader from "./components/tableHeader";
import TablePagination from "./components/tablePagination";
import TableRow from "./components/tableRow";
import TableToolbar from "./components/tableToolbar";
import TableWrapper from "./components/tableWrapper";
import type { TableProps } from "./helpers/tableTypes";

function Table<T>({
	data,
	columns,
	searchValue,
	onRowClick,
	initialSelectedRowIds,
	onDelete,
	onDuplicate,
	tableColumnFilters,
}: TableProps<T>) {
	const [sorting, setSorting] = useState<SortingState>([]);
	const [globalFilter, setGlobalFilter] = useState("");
	const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
	const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
	const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

	const table = useReactTable({
		data,
		columns,
		state: {
			sorting,
			columnVisibility,
			rowSelection,
			columnFilters,
			globalFilter,
		},
		enableRowSelection: true,
		enableMultiRowSelection: true,
		enableSubRowSelection: true,
		enableHiding: true,
		onRowSelectionChange: setRowSelection,
		onSortingChange: setSorting,
		onColumnFiltersChange: setColumnFilters,
		onColumnVisibilityChange: setColumnVisibility,
		getCoreRowModel: getCoreRowModel(),
		onGlobalFilterChange: setGlobalFilter,
		getFilteredRowModel: getFilteredRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
	});

	useEffect(() => {
		setRowSelection(initialSelectedRowIds ?? {});
	}, [initialSelectedRowIds]);

	return (
		<div className="space-y-4">
			<TableToolbar table={table} tableColumnFilters={tableColumnFilters} />
			<div className="rounded-md border">
				<TableWrapper>
					<TableHeader>
						{table.getHeaderGroups().map((headerGroup) => (
							<TableRow key={headerGroup.id}>
								{headerGroup.headers.map((header) => {
									const columnSize =
										header.column.columnDef.size !== 9999
											? `${header.column.columnDef.size}px`
											: "100%";
									return (
										<TableHead
											key={header.id}
											style={{
												width: columnSize,
												minWidth:
													header.column.columnDef.size !== 9999
														? columnSize
														: "",
											}}
										>
											{header.isPlaceholder
												? null
												: flexRender(
														header.column.columnDef.header,
														header.getContext(),
													)}
										</TableHead>
									);
								})}
							</TableRow>
						))}
					</TableHeader>
					<TableBody>
						{table.getRowModel().rows?.length ? (
							table.getRowModel().rows.map((row) => (
								<TableRow
									onClick={() => {
										onRowClick?.(row.original);
									}}
									key={row.id}
									data-state={row.getIsSelected() && "selected"}
								>
									{row.getVisibleCells().map((cell) => {
										const columnSize =
											cell.column.columnDef.size !== 9999
												? `${cell.column.columnDef.size}px`
												: "100%";
										return (
											<TableCell
												key={cell.id}
												style={{
													width: columnSize,
													minWidth:
														cell.column.columnDef.size !== 9999
															? columnSize
															: "",
												}}
											>
												{flexRender(
													cell.column.columnDef.cell,
													cell.getContext(),
												)}
											</TableCell>
										);
									})}
								</TableRow>
							))
						) : (
							<TableRow className="pointer-events-none">
								<TableCell
									colSpan={columns.length}
									className="h-[52.5px] text-center"
								>
									{t.tableNoData()}
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</TableWrapper>
			</div>
			<TablePagination table={table} />
			<TableActionBar
				selectedRows={table.getSelectedRowModel().rows}
				onDelete={onDelete}
				onDuplicate={onDuplicate}
				rowsSelected={() => table.toggleAllRowsSelected(false)}
			/>
		</div>
	);
}

Table.displayName = "Table";
export default Table;
