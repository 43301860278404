import type { IAddress } from "./addressFormTypes";

export const getInitialAddressFormValues = (address?: IAddress) => {
	return {
		formattedAddress: address?.formattedAddress ?? "",
		street: address?.street ?? "",
		streetNumber: address?.streetNumber ?? "",
		postalCode: address?.postalCode ?? "",
		city: address?.city ?? "",
		addition: address?.addition ?? "",
		country: {
			name: address?.country.name ?? "",
			code: address?.country.code ?? "",
		},
		lat: address?.lat ?? 0,
		lng: address?.lng ?? 0,
		location: {
			type: address?.location?.type ?? "Point",
			coordinates: [0, 0] as [number, number],
		},
	};
};
