import { FadeInWrapper } from "@/components/layout/fadeInWrapper/fadeInWrapper";
import LegalLinks from "@/components/ui/legalLinks/legalLinks";
import * as t from "@/i18n/paraglide/messages";
import { Route } from "@/routes/_landingPageLayout/(auth)/registration";
import { useEffect, useState } from "react";
import VerificationErrorDialog from "./components/verificationErrorDialog";
import { RegisterForm } from "./registrationForm";

const RegistrationScreen: React.FC = () => {
	const [registrationCompleted, setRegistrationCompleted] = useState(false);
	const { verificationSuccess } = Route.useSearch();
	const [isOpen, setIsOpen] = useState<boolean>(false);

	useEffect(() => {
		if (verificationSuccess === false) {
			setIsOpen(true);
		}
	}, [verificationSuccess]);

	return (
		<div className="py-12 px-6 md:p-0 relative flex lg:h-[calc(100vh-5rem)] items-center justify-cente">
			<img
				src="/images/authBackground.svg"
				alt="Background"
				className="absolute m-auto h-[90%] w-full hidden md:block"
			/>
			<div className="mx-auto flex w-full flex-col justify-center sm:w-[450px] z-10">
				<FadeInWrapper>
					{!registrationCompleted ? (
						<div className="flex flex-col gap-4 md:gap-6">
							<div className="flex flex-col space-y-2 text-center">
								<span className="text-3xl font-bold">{t.registerTitle()}</span>
								<p className="text-muted-foreground">{t.registerSubtitle()}</p>
							</div>
							<div className="flex flex-col gap-2">
								<RegisterForm
									setRegistrationCompleted={setRegistrationCompleted}
								/>
								<LegalLinks />
							</div>
						</div>
					) : (
						<div className="flex flex-col text-center gap-4">
							<h1 className="text-3xl font-bold tracking-tight">
								{t.registerSuccessTitle()}
							</h1>
							<p className="text-md">{t.registerSuccessSubtitle()}</p>
							<div className="swal-icon swal-icon--success">
								<span className="swal-icon--success__line swal-icon--success__line--long"></span>
								<span className="swal-icon--success__line swal-icon--success__line--tip"></span>
								<div className="swal-icon--success__ring"></div>
								<div className="swal-icon--success__hide-corners"></div>
							</div>
						</div>
					)}
				</FadeInWrapper>
			</div>
			<VerificationErrorDialog isOpen={isOpen} setIsOpen={setIsOpen} />
		</div>
	);
};

RegistrationScreen.displayName = "RegistrationScreen";
export default RegistrationScreen;
