/* eslint-disable */
import { languageTag } from "../../runtime.js"
import * as de from "../de/deleteContentItemFolder.js"
import * as en from "../en/deleteContentItemFolder.js"
import * as pl from "../pl/deleteContentItemFolder.js"
import * as fr from "../fr/deleteContentItemFolder.js"
import * as es from "../es/deleteContentItemFolder.js"
import * as it from "../it/deleteContentItemFolder.js"


/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ contentItemName: NonNullable<unknown> }} params
 * @param {{ languageTag?: "de" | "en" | "pl" | "fr" | "es" | "it" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const deleteContentItemFolder = (params , options = {}) => {
	return {
		de: de.deleteContentItemFolder,
		en: en.deleteContentItemFolder,
		es: es.deleteContentItemFolder,
		fr: fr.deleteContentItemFolder,
		it: it.deleteContentItemFolder,
		pl: pl.deleteContentItemFolder
	}[options.languageTag ?? languageTag()](params)
}

