import { faUpRightFromSquare } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type React from "react";
import { IconSize } from "../icon/helpers/iconTypes";
import { Icon } from "../icon/icon";
import {
	getIconColor,
	getStatusCardBorderStyles,
	getStatusCardParentStyles,
	getStatusIcon,
} from "./helpers/statusCardStyles";
import {
	type StatusCardProps,
	StatusCardStatus,
	StatusCardVariant,
} from "./helpers/statusCardTypes";

const StatusCard: React.FC<StatusCardProps> = ({
	status = StatusCardStatus.unknown,
	variant = StatusCardVariant.card,
	className = "",
}) => {
	return (
		<a href={import.meta.env.VITE_APP_STATUS_URL}>
			<div
				className={getStatusCardParentStyles({ status, variant, className })}
			>
				<div className="flex items-center gap-2.5">
					<Icon
						fixedWidth
						icon={getStatusIcon(status)}
						size={IconSize.Large}
						primaryColor={getIconColor({ status, isPrimaryColor: true })}
						secondaryColor={getIconColor({ status, isPrimaryColor: false })}
						className="opacity-80 duration-300 group-hover/card:opacity-100"
					/>
					{variant === StatusCardVariant.card && (
						<div className="flex flex-col">
							<span className="text-[0.8rem] tracking-wide opacity-80 duration-300 group-hover/card:opacity-100">
								{status}
							</span>
						</div>
					)}
				</div>
				{variant === StatusCardVariant.card && (
					<FontAwesomeIcon
						fixedWidth
						icon={faUpRightFromSquare}
						size={IconSize.ExtraSmall}
						className="opacity-80 duration-300 group-hover/card:opacity-100"
					/>
				)}
				<span className={getStatusCardBorderStyles({ status })} />
			</div>
		</a>
	);
};

StatusCard.displayName = "StatusCard";
export default StatusCard;
