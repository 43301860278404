import { Checkbox } from "@/components/ui/checkbox/checkbox";
import { Icon } from "@/components/ui/icon/icon";
import { DataTableColumnHeader } from "@/components/ui/table/components/tableColumnHeader";
import * as t from "@/i18n/paraglide/messages";
import { useTableActionBarState } from "@/store/tableActionBarState";
import { faCircleDot } from "@fortawesome/pro-duotone-svg-icons";
import type { ColumnDef } from "@tanstack/react-table";
import clsx from "clsx";
import { useEffect, useMemo } from "react";
import type { IUserClient } from "shared-types";
import { UserRole, UserStatus } from "shared-types/src/models/User/UserTypes";
import { useSelectedUserTableRowIds } from "../helpers/selectedUserTableRowIdsStore";

const useGetUserTableColumns = () => {
	const { addUserRowId, removeUserRowId, addUserRowIds, removeUserRowIds } =
		useSelectedUserTableRowIds();

	const TableColumnSize = {
		checkbox: 50,
		firstName: 150,
		lastName: 150,
		email: 9999,
		role: 200,
		lastActive: 200,
		status: 150,
	};

	const statusLabel = {
		[UserStatus.Active]: t.active(),
		[UserStatus.Inactive]: t.inactive(),
		[UserStatus.Invited]: t.invited(),
		[UserStatus.Deleted]: t.deleted(),
	} as const;

	useEffect(() => {
		const handleSelectedRowsChange = () => {
			const { userRowIds } = useSelectedUserTableRowIds.getState();
			const isOpen = userRowIds.length > 0;
			useTableActionBarState.setState({ isOpen });
		};
		const unsubscribe = useSelectedUserTableRowIds.subscribe(
			handleSelectedRowsChange,
		);

		return () => {
			unsubscribe();
		};
	}, []);

	const roleLabel = {
		[UserRole.Admin]: t.admin(),
		[UserRole.Employee]: t.employee(),
	} as const;

	const getIconColor = (status: UserStatus): string => {
		switch (status) {
			case UserStatus.Active:
				return "success";
			case UserStatus.Inactive:
				return "warning";
			case UserStatus.Invited:
				return "info";
			case UserStatus.Deleted:
				return "danger";
			default:
				return "info";
		}
	};

	const columns: ColumnDef<IUserClient>[] = useMemo(
		() => [
			{
				id: "select",
				header: ({ table }) => (
					<>
						<Checkbox
							isSelected={table.getIsAllPageRowsSelected()}
							onChange={(value) => {
								table.toggleAllPageRowsSelected();
								const currentPageIds = table
									.getRowModel()
									.rows.map((row) => row.original._id);

								if (value) {
									addUserRowIds(currentPageIds);
								} else {
									removeUserRowIds(currentPageIds);
								}
							}}
						/>
					</>
				),
				cell: ({ row }) => (
					<Checkbox
						isSelected={row.getIsSelected()}
						onChange={(value) => {
							row.toggleSelected();

							if (value) {
								addUserRowId(row.original._id);
							} else {
								removeUserRowId(row.original._id);
							}
						}}
					/>
				),
				enableSorting: false,
				enableHiding: false,
				size: TableColumnSize.checkbox,
			},
			{
				accessorKey: "profile.firstName",
				header: ({ column }) => (
					<DataTableColumnHeader column={column} title={t.firstName()} />
				),
				cell: ({ row }) => <div>{row.original.profile.firstName}</div>,
				enableSorting: false,
				enableHiding: true,
				size: TableColumnSize.firstName,
			},
			{
				accessorKey: "profile.lastName",
				header: ({ column }) => (
					<DataTableColumnHeader column={column} title={t.lastName()} />
				),
				cell: ({ row }) => <div>{row.original.profile.lastName}</div>,
				enableSorting: false,
				enableHiding: true,
				size: TableColumnSize.lastName,
			},
			{
				accessorKey: "email",
				header: ({ column }) => (
					<DataTableColumnHeader column={column} title={t.email()} />
				),
				cell: ({ row }) => <div>{row.original.email}</div>,
				enableSorting: false,
				enableHiding: false,
				size: TableColumnSize.email,
			},
			{
				accessorKey: "role",
				header: ({ column }) => (
					<DataTableColumnHeader column={column} title="Rolle" />
				),
				cell: ({ row }) => {
					return (
						<div
							className={clsx("flex py-1 px-2 items-center rounded-md w-min", {
								"bg-fuchsia-red-3":
									(row.original.role as UserRole) === UserRole.Admin,
								"bg-blue-chill-3":
									(row.original.role as UserRole) === UserRole.Employee,
							})}
						>
							<span
								className={clsx({
									"text-fuchsia-red-200":
										(row.original.role as UserRole) === UserRole.Admin,
									"text-blue-chill-200":
										(row.original.role as UserRole) === UserRole.Employee,
								})}
							>
								{roleLabel[row.original.role as keyof typeof roleLabel]}
							</span>
						</div>
					);
				},
				enableSorting: false,
				enableHiding: false,
				size: TableColumnSize.role,
			},
			{
				accessorKey: "lastActive",
				header: ({ column }) => (
					<DataTableColumnHeader column={column} title={t.lastActive()} />
				),
				cell: ({ row }) => <div>Vor 2 Tagen</div>,
				enableSorting: false,
				enableHiding: false,
				size: TableColumnSize.lastActive,
			},
			{
				accessorKey: "status",
				header: ({ column }) => (
					<DataTableColumnHeader column={column} title="Status" />
				),
				cell: ({ row }) => {
					return (
						<div className={clsx("flex gap-2 items-center rounded-md w-min")}>
							<Icon
								icon={faCircleDot}
								primaryColor={getIconColor(row.original.status as UserStatus)}
								secondaryColor={getIconColor(row.original.status as UserStatus)}
							/>
							<span>
								{statusLabel[row.original.status as keyof typeof statusLabel]}
							</span>
						</div>
					);
				},
				enableSorting: false,
				enableHiding: false,
				size: TableColumnSize.status,
			},
		],
		[addUserRowId, addUserRowIds, removeUserRowId, removeUserRowIds],
	);

	return columns;
};

export default useGetUserTableColumns;
