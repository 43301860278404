import { cn } from "@/lib/utils";
import * as LabelPrimitive from "@radix-ui/react-label";
import * as React from "react";

/**
 * `Label` is a React forward reference component that wraps the `LabelPrimitive.Root` component from `@radix-ui/react-label`.
 * It applies additional styling and supports all props of `LabelPrimitive.Root`.
 *
 * @param className - Additional class names to apply to the label.
 * @param props - All other props are passed through to the `LabelPrimitive.Root` component.
 * @param ref - A ref to be forwarded to the `LabelPrimitive.Root` component.
 *
 * @returns A styled label component that integrates with Radix UI's label primitive.
 */
const Label = React.forwardRef<
	React.ElementRef<typeof LabelPrimitive.Root>,
	React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>
>(({ className, ...props }, ref) => (
	<LabelPrimitive.Root
		ref={ref}
		className={cn(
			"text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70",
			className,
		)}
		{...props}
	/>
));

Label.displayName = "Label";
export default Label;
