export const getContentTypeCardStyling = ({
	isSelected,
	secondaryColor,
	tertiaryColor,
}: {
	isSelected: boolean;
	secondaryColor: string;
	tertiaryColor: string;
}): string => {
	const base = `flex items-center justify-center py-4 rounded-md hover:bg-background-secondary transition duration-300 cursor-pointer  hover:bg-${tertiaryColor} opacity-80 hover:opacity-100 border`;

	const selectedClass = isSelected
		? `bg-${tertiaryColor} border-${secondaryColor} hover:border-${secondaryColor}`
		: `hover:border-${tertiaryColor}`;

	return `${base} ${selectedClass}`.trim();
};
