import FlagDe from "@/components/ui/flags/flagDe";
import FlagEn from "@/components/ui/flags/flagEn";
import FlagEs from "@/components/ui/flags/flagEs";
import FlagFR from "@/components/ui/flags/flagFr";
import FlagIt from "@/components/ui/flags/flagIt";
import FlagPl from "@/components/ui/flags/flagPl";
import { LanguageCode } from "./languages";

export const getFlag = (languageCode: LanguageCode, flagHeight: string) => {
	switch (languageCode) {
		case LanguageCode.DE:
			return <FlagDe size={flagHeight} />;
		case LanguageCode.EN:
			return <FlagEn size={flagHeight} />;
		case LanguageCode.ES:
			return <FlagEs size={flagHeight} />;
		case LanguageCode.FR:
			return <FlagFR size={flagHeight} />;
		case LanguageCode.IT:
			return <FlagIt size={flagHeight} />;
		case LanguageCode.PL:
			return <FlagPl size={flagHeight} />;
	}
};
