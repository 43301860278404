import { create } from "zustand";

interface SelectedEventTableRowIdsState {
	eventRowIds: string[];
	addEventRowId: (id: string) => void;
	removeEventRowId: (id: string) => void;
	addEventRowIds: (ids: string[]) => void;
	removeEventRowIds: (ids: string[]) => void;
	clearEventRowIds: () => void;
}

export const useSelectedEventTableRowIds =
	create<SelectedEventTableRowIdsState>((set) => ({
		eventRowIds: [],
		addEventRowId: (id) =>
			set((state) => ({
				eventRowIds: Array.from(new Set([...state.eventRowIds, id])),
			})),
		removeEventRowId: (id) =>
			set((state) => ({
				eventRowIds: state.eventRowIds.filter((rowId) => rowId !== id),
			})),
		addEventRowIds: (ids) =>
			set((state) => ({
				eventRowIds: Array.from(new Set([...state.eventRowIds, ...ids])),
			})),
		removeEventRowIds: (ids) =>
			set((state) => ({
				eventRowIds: state.eventRowIds.filter((rowId) => !ids.includes(rowId)),
			})),
		clearEventRowIds: () => set({ eventRowIds: [] }),
	}));
