import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCarrot } from "@fortawesome/pro-light-svg-icons";
import type * as SelectPrimitive from "@radix-ui/react-select";

export interface SelectProps extends SelectPrimitive.SelectProps {
	label?: string;
	itemList: SelectItemListType;
	placeholder?: string;
	className?: string;
	isDisabled?: boolean;
}

export interface SelectItemType {
	value: string;
	label: string;
	icon?: IconProp;
	duotoneIconColors?: { primary: string; secondary: string };
}

export interface SelectGroupType {
	label?: string;
	items: SelectItemType[];
}

export interface SelectItemListType {
	groups: SelectGroupType[];
}

export const selectItemListDemoData: SelectItemListType = {
	groups: [
		{
			label: "Fruits",
			items: [
				{
					value: "apple",
					label: "Apple",
					duotoneIconColors: { primary: "#FF4C4C", secondary: "#FF9E9E" },
				},
				{
					value: "banana",
					label: "Banana",
					duotoneIconColors: { primary: "#FFD700", secondary: "#FFEE93" },
				},
			],
		},
		{
			label: "Vegetables",
			items: [
				{
					value: "carrot",
					label: "Carrot",
					icon: faCarrot,
					duotoneIconColors: { primary: "#FFA500", secondary: "#FFCA85" },
				},
				{
					value: "broccoli",
					label: "Broccoli",
					icon: faCarrot,
					duotoneIconColors: { primary: "#228B22", secondary: "#90EE90" },
				},
			],
		},
		{
			label: "Beverages",
			items: [
				{
					value: "coffee",
					label: "Coffee",
					duotoneIconColors: { primary: "#6F4E37", secondary: "#A67B5B" },
				},
				{
					value: "tea",
					label: "Tea",
					duotoneIconColors: { primary: "#D0F0C0", secondary: "#8FBC8F" },
				},
			],
		},
	],
};
