import apiClient from "@/lib/api";
import type { IServerResArray } from "@/lib/responses";
import type { IEventClient } from "shared-types";

/**
 * @description Fetches all events from the server
 *
 * @returns {Promise<IServerResArray<IEventClient[]>>} A promise that resolves to an array of events.
 *
 * @throws {Error} Throws an error if the fetch request fails or if the response is not valid.
 */
const fetchAllEvents = async (): Promise<IServerResArray<IEventClient[]>> => {
	const response = await apiClient
		.get("events")
		.json<IServerResArray<IEventClient[]>>();

	return response;
};

export default fetchAllEvents;
