import DigitalLeadManagementScreen from "@/components/screens/landingPage/solutions/digitalLeadManagementScreen";
import * as t from "@/i18n/paraglide/messages";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute(
	"/_landingPageLayout/solutions/digital-lead-management",
)({
	component: DigitalLeadManagementScreen,
	beforeLoad: () => {
		document.title = `${import.meta.env.VITE_APP_NAME} • ${t.salesPresentationsMetaTitle()}`;
	},
});
