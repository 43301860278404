import { getInitialAddressFormValues } from "@/components/ui/addressForm/helpers/initialAddressFormValues";
import { EventStatus, type IEventClient } from "shared-types";

export const getInitialEventValues = (event?: IEventClient) => {
	return {
		id: event?.id || null,
		name: event?.name || null,
		//startDate: event?.startDate || new Date(),
		//endDate: event?.endDate ||  new Date(),
		address: getInitialAddressFormValues(event?.address),
		status: event?.status || EventStatus.Active,
	};
};
