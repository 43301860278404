const Introduction: React.FC = () => {
	return (
		<div className="flex flex-col md:flex-row max-w-7xl w-full md:rounded-md mx-auto bg-background-tertiary px-6 py-8 md:p-12 mb-8 md:mb-10 md:border gap-4">
			<div className="flex w-2/5">Titel & Text</div>
			<video
				className="rounded-md w-full md:w-3/5 h-auto relative"
				controls
				poster="/images/placeholderVideo.webp"
			>
				<source src="/videos/testvideo.mp4" type="video/mp4" />
				Your browser does not support the video tag.
			</video>
		</div>
	);
};
Introduction.displayName = "Introduction";
export default Introduction;
