import type { IContentClient } from "shared-types";

export const getInitialContentValues = (data?: IContentClient) => {
	return {
		_id: data?._id || "",
		name: data?.name || "",
		status: data?.status || "active",
		storage: data?.storage || {
			storageTotal: 0,
			folders: {
				count: 0,
				storage: 0,
				percentage: 0,
			},
			images: {
				count: 0,
				storage: 0,
				percentage: 0,
			},
			videos: {
				count: 0,
				storage: 0,
				percentage: 0,
			},
			documents: {
				count: 0,
				storage: 0,
				percentage: 0,
			},
		},
		items: data?.items || [],
	};
};
