import {
	StatusCardStatus,
	StatusCardVariant,
} from "@/components/ui/statusCard/helpers/statusCardTypes";
import StatusCard from "@/components/ui/statusCard/statusCard";
import { useUserStore } from "@/store/userStore";
import { getSidebarItems } from "../helpers/sidebarData";
import SidebarItem from "./components/sidebarItemDesktop/sidebarItemDesktop";

/**
 * Represents the desktop version of the application's sidebar.
 *
 * This component is designed to be used in the desktop layout. It encapsulates
 * the logic and presentation for the sidebar specific to desktop views.
 *
 * @component
 * @returns {React.ReactElement} The desktop version of the app sidebar.
 */
const SidebarDesktop: React.FC = () => {
	const sidebarItems = getSidebarItems();
	const userRole = useUserStore.getState().user?.role;

	return (
		<div className="border-green hidden h-screen w-20 grow flex-col overflow-y-auto border-r pb-6 sm:flex md:w-52">
			<div className="flex h-20 shrink-0 items-center justify-center pt-1.5">
			<img src="/images/midlead_logo_long_white.svg" alt="logo" className="w-28 hidden md:flex" />
			<img src="/images/midlead_logo_short.svg" alt="logo" className="size-10 md:hidden" />
			</div>
			<nav className="flex flex-1 flex-col pt-4 sm:px-4">
				<div className="flex flex-1 flex-col gap-y-4 text-center text-sm">
					{sidebarItems.map((sidebarItem) => (
						<>
							{sidebarItem.authorizedUserRoles.includes(userRole) && (
								<SidebarItem
									key={sidebarItem.page}
									page={sidebarItem.page}
									label={sidebarItem.label}
									link={sidebarItem.link}
									Icon={sidebarItem.icon}
								/>
							)}
						</>
					))}
				</div>
				<StatusCard
					status={StatusCardStatus.operational}
					className="flex h-11 sm:hidden md:flex"
				/>
				<StatusCard
					status={StatusCardStatus.operational}
					variant={StatusCardVariant.tile}
					className="hidden h-11 sm:flex md:hidden"
				/>
			</nav>
		</div>
	);
};

export default SidebarDesktop;
