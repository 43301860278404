import {
	FadeInStaggerWrapper,
	FadeInWrapper,
} from "@/components/layout/fadeInWrapper/fadeInWrapper";
import { motion, useScroll, useTransform } from "framer-motion";
import { useRef } from "react";

const FeaturesHeader: React.FC = () => {
	const backgroundRef = useRef<HTMLDivElement>(null);

	const { scrollYProgress: backgroundScrollYProgress } = useScroll({
		target: backgroundRef,
		offset: ["-170px start", "end start"],
	});

	// Define the scale transformation from 1 to 0.85
	const scale = useTransform(backgroundScrollYProgress, [0, 1], [1, 0.85]);
	const opacity = useTransform(backgroundScrollYProgress, [1, 0], [1, 0.35]);

	return (
		<div className="py-20">
			<div className="relative h-96">
				<motion.div
					ref={backgroundRef}
					style={{
						scale,
					}}
					className="sticky z-0 h-96 overflow-hidden rounded-md"
				>
					<img
						src="/images/placeholder.webp"
						alt="Feature Background"
						className="z-0 object-cover"
					/>
					<motion.div
						className="absolute inset-0 bg-black"
						style={{
							opacity,
						}}
					/>
				</motion.div>
				<FadeInStaggerWrapper className="absolute left-0 top-0 flex h-96 w-full flex-col items-center justify-center gap-4">
					<FadeInWrapper>
						<span className="text-center text-4xl font-bold md:text-4xl">
							Funktionen
						</span>
					</FadeInWrapper>
					<FadeInWrapper>
						<span className="mb-2 text-center text-xl md:mb-4 md:text-2xl">
							Hier finden Sie eine Übersicht der Funktionen unserer Software
						</span>
					</FadeInWrapper>
				</FadeInStaggerWrapper>
			</div>
		</div>
	);
};

FeaturesHeader.displayName = "FeaturesHeader";
export default FeaturesHeader;
