import type { IAddress } from "@/components/ui/addressForm/helpers/addressFormTypes";
import { Checkbox } from "@/components/ui/checkbox/checkbox";
import { Icon } from "@/components/ui/icon/icon";
import { DataTableColumnHeader } from "@/components/ui/table/components/tableColumnHeader";
import { useLanguage } from "@/providers/languageProvider";
import { useTableActionBarState } from "@/store/tableActionBarState";
import { faCircleDot } from "@fortawesome/pro-duotone-svg-icons";
import type { ColumnDef } from "@tanstack/react-table";
import clsx from "clsx";
import { useEffect, useMemo } from "react";
import type { IEventClient } from "shared-types";
import { UserStatus } from "shared-types/src/models/User/UserTypes";
import { useSelectedEventTableRowIds } from "../helpers/selectedEventTableRowIdsStore";
import {
	faCalendarLines,
	faLocationDot,
} from "@fortawesome/pro-light-svg-icons";

const useGetEventTableColumns = () => {
	const { locale } = useLanguage();
	const { addEventRowId, removeEventRowId, addEventRowIds, removeEventRowIds } =
		useSelectedEventTableRowIds();

	const TableColumnSize = {
		checkbox: 50,
		name: 300,
		date: 300,
		address: 9999,
		status: 150,
	};

	useEffect(() => {
		const handleSelectedRowsChange = () => {
			const { eventRowIds } = useSelectedEventTableRowIds.getState();
			const isOpen = eventRowIds.length > 0;
			useTableActionBarState.setState({ isOpen });
		};
		const unsubscribe = useSelectedEventTableRowIds.subscribe(
			handleSelectedRowsChange,
		);

		return () => {
			unsubscribe();
		};
	}, []);

	const columns: ColumnDef<IEventClient>[] = useMemo(
		() => [
			{
				id: "select",
				header: ({ table }) => (
					<>
						<Checkbox
							isSelected={table.getIsAllPageRowsSelected()}
							onChange={(value) => {
								table.toggleAllPageRowsSelected();
								const currentPageIds = table
									.getRowModel()
									.rows.map((row) => row.original._id);

								if (value) {
									addEventRowIds(currentPageIds);
								} else {
									removeEventRowIds(currentPageIds);
								}
							}}
						/>
					</>
				),
				cell: ({ row }) => (
					<Checkbox
						isSelected={row.getIsSelected()}
						onChange={(value) => {
							row.toggleSelected();

							if (value) {
								addEventRowId(row.original._id);
							} else {
								removeEventRowId(row.original._id);
							}
						}}
					/>
				),
				enableSorting: false,
				enableHiding: false,
				size: TableColumnSize.checkbox,
			},
			{
				accessorKey: "name",
				header: ({ column }) => (
					<DataTableColumnHeader column={column} title="Name" />
				),
				cell: ({ row }) => <div>{row.getValue("name")}</div>,
				enableSorting: false,
				enableHiding: false,
				size: TableColumnSize.name,
			},
			{
				accessorKey: "date",
				header: ({ column }) => (
					<DataTableColumnHeader
						Icon={faCalendarLines}
						column={column}
						title="Datum"
					/>
				),
				cell: ({ row }) => {
					const startDate = row.original.startDate
						? new Date(row.original.startDate)
						: null;
					const endDate = row.original.endDate
						? new Date(row.original.endDate)
						: null;

					return (
						<div className="flex gap-3">
							<span className="min-w-24">
								{startDate
									? startDate.toLocaleDateString(locale, {
											day: "numeric",
											month: "long",
											year: "numeric",
										})
									: ""}
							</span>
							{(startDate || endDate) && <span>-</span>}
							<span>
								{" "}
								{endDate
									? endDate.toLocaleDateString(locale, {
											day: "numeric",
											month: "long",
											year: "numeric",
										})
									: ""}
							</span>
						</div>
					);
				},
				enableSorting: false,
				enableHiding: true,
				size: TableColumnSize.date,
			},
			{
				accessorKey: "address",
				header: ({ column }) => (
					<DataTableColumnHeader
						Icon={faLocationDot}
						column={column}
						title="Adresse"
					/>
				),
				cell: ({ row }) => {
					const address: IAddress = row.original.address
						? row.original.address
						: null;

					return <div>{address?.formattedAddress}</div>;
				},
				enableSorting: false,
				enableHiding: true,
				size: TableColumnSize.address,
			},
			{
				accessorKey: "status",
				header: ({ column }) => (
					<DataTableColumnHeader column={column} title="Status" />
				),
				cell: ({ row }) => {
					return (
						<div
							className={clsx(
								"flex gap-2 items-center rounded-md w-min",
								row.getValue("status") === UserStatus.Active
									? "bg-success-hover-bg"
									: "bg-warning-hover-bg",
							)}
						>
							<Icon
								icon={faCircleDot}
								primaryColor={
									row.getValue("status") === UserStatus.Active
										? "success"
										: "warning"
								}
								secondaryColor={
									row.getValue("status") === UserStatus.Active
										? "success"
										: "warning"
								}
							/>
							<span className="opacity-90">
								{row.getValue("status") === UserStatus.Active
									? "Aktiv"
									: "Inaktiv"}
							</span>
						</div>
					);
				},
				enableSorting: false,
				enableHiding: false,
				size: TableColumnSize.status,
			},
		],
		[addEventRowId, addEventRowIds, removeEventRowId, removeEventRowIds],
	);

	return columns;
};

export default useGetEventTableColumns;
