import {
	faChevronLeft,
	faChevronRight,
	faChevronsLeft,
	faChevronsRight,
} from "@fortawesome/pro-light-svg-icons";
import Button from "../../button/button";
import { ButtonSize, ButtonVariant } from "../../button/helpers/buttonTypes";
import { Skeleton } from "../../skeleton/skeleton";
import type { TablePaginationProps } from "../helpers/tableTypes";

function TablePagination<TData>({
	table,
	isLoading,
}: TablePaginationProps<TData>) {
	return (
		<div className="flex items-center justify-between space-x-2 ">
			<div
				style={{ minWidth: "55px" }}
				className="flex h-[32px] min-w-14 items-center gap-1 rounded-md border px-2 text-sm text-foreground opacity-80"
			>
				{isLoading ? (
					<Skeleton className="h-[16px] w-full" />
				) : (
					<>
						<span>{table.getState().pagination.pageIndex + 1}</span>
						<div className="pr-px text-[10px] opacity-60">/</div>
						<span>{table.getPageCount()}</span>
					</>
				)}
			</div>

			<div className="flex items-center space-x-2">
				<Button
					variant={ButtonVariant.Outline}
					size={ButtonSize.ExtraSmall}
					onClick={() => table.setPageIndex(0)}
					disabled={!table.getCanPreviousPage() || isLoading}
					icon={faChevronsLeft}
				/>
				<Button
					variant={ButtonVariant.Outline}
					size={ButtonSize.ExtraSmall}
					onClick={() => table.previousPage()}
					disabled={!table.getCanPreviousPage() || isLoading}
					icon={faChevronLeft}
				/>
				<Button
					variant={ButtonVariant.Outline}
					size={ButtonSize.ExtraSmall}
					onClick={() => table.nextPage()}
					disabled={!table.getCanNextPage() || isLoading}
					icon={faChevronRight}
				/>{" "}
				<Button
					variant={ButtonVariant.Outline}
					size={ButtonSize.ExtraSmall}
					onClick={() => table.setPageIndex(table.getPageCount() - 1)}
					disabled={!table.getCanNextPage() || isLoading}
					icon={faChevronsRight}
				/>
			</div>
		</div>
	);
}
TablePagination.displayName = "TablePagination";
export default TablePagination;
