import { IconSize } from "@/components/ui/icon/helpers/iconTypes";
import { Separator } from "@/components/ui/separator/separator";
import { useContentItemStore } from "@/store/contentItemStore";
import { useSortable } from "@dnd-kit/sortable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, DialogTrigger } from "@radix-ui/react-dialog";
import clsx from "clsx";
import { useState } from "react";
import { ContentItemType } from "shared-types";
import CreateContentTreeItemDialog from "./createContentTreeItemDialog";
import DeleteContentTreeItemDialog from "../dialogs/deleteContentTreeItemDialog";
import { getContentItemTypeIcon } from "./contentTypeCard/helpers/getContentItemTypeIcon";
import type {
	ContentTreeItemProps,
} from "../helpers/contentTreeTypes";
import {
	faAngleDown,
	faAngleRight,
	faGripDotsVertical,
	faPlus,
	faTrashAlt,
} from "@fortawesome/pro-light-svg-icons";


export function ContentTreeItem({
	data,
	collapsed,
	depth,
	onCollapse,
	prefix,
	index,
	removeItem,
	addItem,
}: ContentTreeItemProps) {
	const [isOpenCreateDialog, setIsOpenCreateDialog] = useState(false);
	const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);

	const { attributes, listeners, setNodeRef, transform, transition } =
		useSortable({ id: data._id });

	const style = {
		transition,
		marginLeft: `${25 * depth}px`,
	};

	/*const handleAddItem = (
		name: string,
		type: ContentItemType,
		file: Omit<ContentFile, "key" | "uploadedAt" | "uploadedBy">,
	) => {
		addItem({ name, type, file, depth, path: `${prefix}.items` });
	};*/

	const handleRemoveItem = (index: number) => {
		if(data._id === useContentItemStore.getState().contentItem?._id){
			useContentItemStore.getState().setContentItemPath("");
			useContentItemStore.getState().removeContentItem();
		}
		removeItem(index);
	};

	return (
		<>
			<div
				key={data._id}
				style={style}
				ref={setNodeRef}
				className={clsx(`flex w-[19.5rem] h-10 cursor-pointer items-center rounded-md border bg-background-tertiary transition duration-300`, {
					"border-primary": useContentItemStore.getState().contentItem?._id === data._id,
				})}
			>
				<Dialog open={isOpenCreateDialog} onOpenChange={setIsOpenCreateDialog}>
					<div
						{...attributes}
						{...listeners}
						className="w-8 h-full cursor-grab rounded-l-md text-center transition-colors duration-300 hover:bg-background-quaternary content-center"
					>
						<FontAwesomeIcon
							fixedWidth
							icon={faGripDotsVertical}
							size={IconSize.Medium}
							className="opacity-80"
						/>
					</div>
					<Separator orientation="vertical" className="bg-border" />
					{data.type === ContentItemType.Folder && (
						<>
							<div className="w-8 h-full content-center cursor-pointer text-center transition-colors duration-300 hover:bg-background-quaternary">
								<FontAwesomeIcon
									fixedWidth
									icon={collapsed ? faAngleDown : faAngleRight}
									size={IconSize.Small}
									onClick={onCollapse}
								/>
							</div>
							<Separator orientation="vertical" className="bg-border" />
						</>
					)}
					<div className="flex flex-grow justify-between gap-1 px-2 h-full hover:bg-background-quaternary" onClick={() => {
					useContentItemStore.getState().setContentItemPath(prefix);
					useContentItemStore.getState().setContentItem(data);
				}}>
						<div className="flex items-center gap-2">
							{getContentItemTypeIcon(data.type)}
							<p className="w-32 truncate text-[0.9rem] leading-8">
								{data.name}
							</p>
						</div>
					</div>
					<Separator orientation="vertical" className="bg-border" />
					<div
						onClick={() => setIsOpenDeleteDialog(true)}
						className={clsx(
							"group w-8 h-full hover:border-error-border hover:bg-background-quaternary content-center cursor-pointer text-center transition-colors duration-300",
							{
								"rounded-r-md": data.type !== ContentItemType.Folder,
							},
						)}
					>
						<FontAwesomeIcon
							fixedWidth
							icon={faTrashAlt}
							size={IconSize.Small}
							className="transition-colors duration-300 group-hover:text-error"
						/>
					</div>
					{data.type === ContentItemType.Folder && (
						<>
							<Separator orientation="vertical" className="bg-border" />
							<DialogTrigger asChild>
								<div className="group w-8 h-full hover:bg-background-quaternary content-center cursor-pointer text-center transition-colors duration-300 rounded-r-md">
									<FontAwesomeIcon
										fixedWidth
										icon={faPlus}
										size={IconSize.Small}
										className="transition-colors duration-300 group-hover:text-primary"
									/>
								</div>
							</DialogTrigger>
						</>
					)}
					<CreateContentTreeItemDialog
						addItem={addItem}
						setIsOpen={setIsOpenCreateDialog}
						isOpen={isOpenCreateDialog}
					/>
				</Dialog>
			</div>
			<DeleteContentTreeItemDialog isOpen={isOpenDeleteDialog} setIsOpen={setIsOpenDeleteDialog} contentTreeItem={data} />
		</>
	);
}

