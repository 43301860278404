import * as t from "@/i18n/paraglide/messages";
import { faCircleDot } from "@fortawesome/pro-duotone-svg-icons";
import { UserStatus } from "shared-types";
import Button from "../button/button";
import { ButtonIconSide, ButtonVariant } from "../button/helpers/buttonTypes";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "../dropdown/dropdownMenu";
import { Icon } from "../icon/icon";
import type { StatusSelectProps } from "./helpers/statusSelectTypes";

const StatusSelect: React.FC<StatusSelectProps> = ({
	selectedStatus,
	statusOptions,
	onSelect,
}) => {
	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button
					variant={ButtonVariant.Input}
					label={
						selectedStatus === UserStatus.Active ? t.active() : t.inactive()
					}
					icon={faCircleDot}
					iconSide={ButtonIconSide.Left}
					className="flex w-full justify-start text-foreground"
					duotoneIconColors={
						selectedStatus === UserStatus.Active
							? { primary: "success", secondary: "success" }
							: { primary: "warning", secondary: "warning" }
					}
				/>
			</DropdownMenuTrigger>
			<DropdownMenuContent className="w-40">
				{statusOptions.map((statusOption) => (
					<DropdownMenuItem
						onClick={() => onSelect(statusOption.value)}
						key={statusOption.value}
					>
						<Icon
							fixedWidth
							icon={faCircleDot}
							className="mr-2 size-4"
							primaryColor={statusOption.primaryColor}
							secondaryColor={statusOption.secondaryColor}
						/>
						<span>{statusOption.label}</span>
					</DropdownMenuItem>
				))}
			</DropdownMenuContent>
		</DropdownMenu>
	);
};

StatusSelect.displayName = "StatusSelect";
export default StatusSelect;
