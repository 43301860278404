import { cn } from "@/lib/utils";
import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { motion } from "framer-motion";
import * as React from "react";
import { useRef, type FC } from "react";
import { IconSize } from "../icon/helpers/iconTypes";
import { Icon } from "../icon/icon";

export interface DialogProps extends DialogPrimitive.DialogProps {
	icon?: IconProp;
	duotoneIconColors?: {
		primary: string;
		secondary: string;
	};
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	title: string;
	actionButtons?: React.ReactNode[];
	children: React.ReactNode;
	isModal?: boolean;
}

const Dialog: FC<DialogProps> = ({
	icon,
	duotoneIconColors,
	isOpen,
	setIsOpen,
	title,
	actionButtons,
	children,
	isModal = false,
}) => {

	const overlayRef = React.useRef<HTMLDivElement>(null);
	const contentRef = React.useRef<HTMLDivElement>(null);

	return(
	<DialogPrimitive.Root open={isOpen} onOpenChange={setIsOpen} modal={isModal}>
		<DialogPrimitive.Portal>
			<div className="fixed inset-0 z-50 flex items-start justify-center sm:items-center">
			<DialogPrimitive.Overlay
					ref={overlayRef}
					className="fixed inset-0 z-50 bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0"
				/>
				<DialogPrimitive.Content asChild>
					<motion.div
						initial={{ opacity: 0, scale: 0.9 }}
						animate={{ opacity: 1, scale: 1 }}
						exit={{ opacity: 0, scale: 0.9 }}
						transition={{ duration: 0.2 }}
						className={cn(
							"fixed z-50 grid w-full gap-8 rounded-b-lg border bg-background p-6 shadow-lg sm:max-w-lg lg:max-w-xl sm:rounded-md",
						)}
					>
						<div className="flex flex-col gap-5 text-center sm:text-left">
							<DialogPrimitive.Title
								className={cn(
									"text-xl font-semibold leading-none tracking-tight",
								)}
							>
								<div className="flex gap-2">
									{icon && (
										<>
											{icon && duotoneIconColors ? (
												<Icon
													fixedWidth
													icon={icon}
													primaryColor={duotoneIconColors.primary}
													secondaryColor={duotoneIconColors.secondary}
												/>
											) : (
												<FontAwesomeIcon
													fixedWidth
													size={IconSize.Medium}
													icon={icon}
												/>
											)}
										</>
									)}
									{title}
								</div>
							</DialogPrimitive.Title>
							<div>{children}</div>
						</div>
						<div className="flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2">
							{actionButtons?.map((button, index) => (
								<React.Fragment key={index}>{button}</React.Fragment>
							))}
						</div>
					</motion.div>
				</DialogPrimitive.Content>
			</div>
		</DialogPrimitive.Portal>
	</DialogPrimitive.Root>
);
}

export default Dialog;
