import ResetPasswordScreen from "@/components/screens/landingPage/auth/resetPassword/resetPasswordScreen";
import * as t from "@/i18n/paraglide/messages";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute(
	"/_landingPageLayout/(auth)/reset-password",
)({
	component: ResetPasswordScreen,
	beforeLoad: () => {
		document.title = `${import.meta.env.VITE_APP_NAME} • ${t.resetPasswordMetaTitle()}`;
	},
});
