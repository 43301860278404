import { ButtonType } from "@/components/ui/button/helpers/buttonTypes";
import { IconSize } from "@/components/ui/icon/helpers/iconTypes";
import { Icon } from "@/components/ui/icon/icon";
import clsx from "clsx";
import { motion } from "framer-motion";
import type { FormFeatureTabsCardProps } from "../helpers/formFeatureTabsTypes";

export const FormFeatureTabsCard: React.FC<FormFeatureTabsCardProps> = ({
	selected,
	icon,
	title,
	setSelected,
	tabNum,
	isFirst,
	isLast,
}) => {
	return (
		<div className="relative w-full">
			<button
				type={ButtonType.Button}
				onClick={() => setSelected(tabNum)}
				className={clsx(
					"border-b-3 relative z-0 flex w-full flex-row items-center justify-center gap-4 rounded-md border-background-secondary p-6 transition duration-300 hover:bg-background-tertiary md:flex-col",
					{
						"bg-background-tertiary": selected,
						"rounded-bl-md": isFirst,
						"rounded-br-md": isLast,
					},
				)}
			>
				<span
					className={`rounded-md bg-primary p-2 text-2xl transition-all duration-300 ${
						selected ? "scale-100 opacity-100" : "scale-90 opacity-50"
					}`}
				>
					<Icon fixedWidth icon={icon} size={IconSize.Medium} />
				</span>
				<span
					className={`min-w-[150px] max-w-[200px] text-start text-lg transition-opacity md:text-center ${
						selected ? "opacity-100" : "opacity-50"
					}`}
				>
					{title}
				</span>
			</button>
			{selected && (
				<motion.span
					layoutId="tabs-features-underline"
					className="absolute inset-x-0 bottom-0 z-10 h-1 rounded-b-md bg-primary"
				/>
			)}
		</div>
	);
};

FormFeatureTabsCard.displayName = "FormFeatureTabsCard";
export default FormFeatureTabsCard;
