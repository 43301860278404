import { faCheck } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as SelectPrimitive from "@radix-ui/react-select";
import type React from "react";
import { IconSize } from "../icon/helpers/iconTypes";
import { Icon } from "../icon/icon";
import { SelectContent, SelectGroup } from "./components/selectComponents";
import SelectGroupLabel from "./components/selectGroupLabel";
import SelectTrigger from "./components/selectTrigger";
import type { SelectGroupType, SelectProps } from "./helper/selectTypes";

const Select: React.FC<SelectProps> = ({
	label,
	itemList,
	placeholder,
	className,
	isDisabled,
	...props
}) => (
	<SelectPrimitive.Root {...props}>
		<SelectTrigger />
		<SelectContent>
			{itemList.groups.map((itemGroup: SelectGroupType, groupIdx) => (
				<SelectGroup key={groupIdx}>
					{itemGroup.label && (
						<SelectGroupLabel>{itemGroup.label}</SelectGroupLabel>
					)}
					{itemGroup.items.map((item, itemIdx) => (
						<SelectPrimitive.Item
							key={itemIdx}
							value={item.value}
							className="relative flex w-full hover:cursor-pointer justify-between select-none items-center rounded-md py-1.5 px-2 text-sm outline-none focus:bg-background-tertiary hover:bg-background-quaternary data-[disabled]:pointer-events-none data-[disabled]:opacity-50"
						>
							<div className="flex items-center">
								{item.icon && (
									<span className="mr-2">
										{item.duotoneIconColors ? (
											<Icon
												fixedWidth
												icon={item.icon}
												size={IconSize.Medium}
												primaryColor={item.duotoneIconColors.primary}
												secondaryColor={item.duotoneIconColors.secondary}
											/>
										) : (
											<FontAwesomeIcon
												fixedWidth
												size={IconSize.Medium}
												icon={item.icon}
											/>
										)}
									</span>
								)}
								<SelectPrimitive.ItemText>
									{item.label}
								</SelectPrimitive.ItemText>
							</div>
							<span className="flex h-3.5 w-3.5 items-center justify-center">
								<SelectPrimitive.ItemIndicator>
									<FontAwesomeIcon icon={faCheck} />
								</SelectPrimitive.ItemIndicator>
							</span>
						</SelectPrimitive.Item>
					))}
				</SelectGroup>
			))}
		</SelectContent>
	</SelectPrimitive.Root>
);

Select.displayName = "Select";
export default Select;
