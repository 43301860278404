import { type MotionValue, motion, useTransform } from "framer-motion";

export const ControllingFeatureImages = ({
	scrollYProgress,
}: {
	scrollYProgress: MotionValue<number>;
}) => {
	const scale = useTransform(scrollYProgress, [0, 1], [0.5, 1]);

	const image1Offset = useTransform(scrollYProgress, [0, 1], ["-35%", "0%"]);

	const image2OffsetX = useTransform(scrollYProgress, [0, 1], ["30%", "0%"]);
	const image2OffsetY = useTransform(scrollYProgress, [0, 1], ["-30%", "0%"]);

	const image3OffsetX = useTransform(scrollYProgress, [0, 1], ["-25%", "0%"]);
	const image3OffsetY = useTransform(scrollYProgress, [0, 1], ["25%", "0%"]);

	const image4OffsetX = useTransform(scrollYProgress, [0, 1], ["25%", "0%"]);
	const image4OffsetY = useTransform(scrollYProgress, [0, 1], ["-145%", "0%"]);

	const image5OffsetX = useTransform(scrollYProgress, [0, 1], ["-25%", "0%"]);
	const image5OffsetY = useTransform(scrollYProgress, [0, 1], ["25%", "0%"]);

	const image6OffsetX = useTransform(scrollYProgress, [0, 1], ["25%", "0%"]);
	const image6OffsetY = useTransform(scrollYProgress, [0, 1], ["25%", "0%"]);

	return (
		<>
			<motion.div
				className="col-span-2 relative z-10 rounded-md"
				style={{
					backgroundImage:
						"url(https://images.unsplash.com/photo-1511447333015-45b65e60f6d5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1855&q=80)",
					backgroundSize: "cover",
					backgroundPosition: "center",
					scale,
					x: image1Offset,
					y: image1Offset,
				}}
			/>
			<motion.div
				className="row-span-2 relative z-10 rounded-md"
				style={{
					backgroundImage:
						"url(https://images.unsplash.com/photo-1517504734587-2890819debab?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=607&q=80)",
					backgroundSize: "cover",
					backgroundPosition: "center",
					scale,
					x: image2OffsetX,
					y: image2OffsetY,
				}}
			/>

			<motion.div
				className="row-span-2 relative z-10 rounded-md"
				style={{
					backgroundImage:
						"url(https://images.unsplash.com/photo-1602294525148-c3d202338aa3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80)",
					backgroundSize: "cover",
					backgroundPosition: "center",
					scale,
					x: image3OffsetX,
					y: image3OffsetY,
				}}
			/>
			<motion.div
				className="relative z-10 rounded-md"
				style={{
					backgroundImage:
						"url(https://plus.unsplash.com/premium_photo-1671751033625-46175f2eb03d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1624&q=80)",
					backgroundSize: "cover",
					backgroundPosition: "center",
					scale,
					x: image4OffsetX,
					y: image4OffsetY,
				}}
			/>

			<motion.div
				className="relative z-10 rounded-md"
				style={{
					backgroundImage:
						"url(https://images.unsplash.com/photo-1511800453077-8c0afa94175f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80)",
					backgroundSize: "cover",
					backgroundPosition: "center",
					scale,
					x: image5OffsetX,
					y: image5OffsetY,
				}}
			/>
			<motion.div
				className="relative z-10 rounded-md"
				style={{
					backgroundImage:
						"url(https://plus.unsplash.com/premium_photo-1668790459004-780996a6404c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80)",
					backgroundSize: "cover",
					backgroundPosition: "center",
					scale,
					x: image6OffsetX,
					y: image6OffsetY,
				}}
			/>
		</>
	);
};

export default ControllingFeatureImages;
