import { Outlet, createRootRouteWithContext } from "@tanstack/react-router";

import "unfonts.css";
import "@/styles/globals.css";
import "react-toastify/dist/ReactToastify.css";
import Providers from "@/providers/providers";
import type { QueryClient } from "@tanstack/react-query";

export const Route = createRootRouteWithContext<{
	queryClient: QueryClient;
}>()({
	component: RootComponent,
});

function RootComponent() {
	return (
		<Providers>
			<Outlet />
		</Providers>
	);
}
