import * as t from "@/i18n/paraglide/messages";
import {
	flexRender,
	getCoreRowModel,
	useReactTable,
} from "@tanstack/react-table";
import { Checkbox } from "../../checkbox/checkbox";
import type { TableErrorSkeletonProps } from "../helpers/tableTypes";
import TableHead from "./tableHead";
import TableHeader from "./tableHeader";
import TableRow from "./tableRow";
import TableToolbar from "./tableToolbar";
import TableWrapper from "./tableWrapper";

function TableErrorSkeleton<T>({ columns }: TableErrorSkeletonProps<T>) {
	const table = useReactTable({
		columns,
		data: [],
		getCoreRowModel: getCoreRowModel(),
	});

	return (
		<div className="space-y-4">
			<TableToolbar table={table} />
			<div className="rounded-md border">
				<TableWrapper>
					<TableHeader>
						{table.getHeaderGroups().map((headerGroup, key) => (
							<TableRow key={key}>
								{headerGroup.headers.map((header) => {
									const columnSize =
										header.column.columnDef.size !== 9999
											? `${header.column.columnDef.size}px`
											: "100%";
									return (
										<TableHead
											key={header.id}
											style={{
												width: columnSize,
												minWidth:
													header.column.columnDef.size !== 9999
														? columnSize
														: "",
											}}
										>
											{header.id === "select" ? (
												<Checkbox isDisabled />
											) : header.isPlaceholder ? null : (
												flexRender(
													header.column.columnDef.header,
													header.getContext(),
												)
											)}
										</TableHead>
									);
								})}
							</TableRow>
						))}
					</TableHeader>
				</TableWrapper>
				<div className="bg-[#2B3540] flex items-center justify-center h-36">
					{t.tableError()}
				</div>
			</div>
		</div>
	);
}

TableErrorSkeleton.displayName = "TableErrorSkeleton";
export default TableErrorSkeleton;
