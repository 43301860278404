import { Checkbox } from "@/components/ui/checkbox/checkbox";
import { Icon } from "@/components/ui/icon/icon";
import { Separator } from "@/components/ui/separator/separator";
import { DataTableColumnHeader } from "@/components/ui/table/components/tableColumnHeader";
import * as t from "@/i18n/paraglide/messages";
import { formatFileSize } from "@/lib/formatFileSize";
import { useTableActionBarState } from "@/store/tableActionBarState";
import { faCircleDot } from "@fortawesome/pro-duotone-svg-icons";
import type { ColumnDef } from "@tanstack/react-table";
import clsx from "clsx";
import { useEffect, useMemo } from "react";
import { ContentStatus, type IContentClient } from "shared-types";
import { useSelectedContentTableRowIds } from "../helpers/selectedContentTableRowIdsStore";

const useGetContentTableColumns = () => {
	const {
		addContentRowId,
		removeContentRowId,
		addContentRowIds,
		removeContentRowIds,
	} = useSelectedContentTableRowIds();

	const TableColumnSize = {
		checkbox: 50,
		name: 9999,
		storage: 150,
		folders: 180,
		images: 180,
		videos: 180,
		documents: 190,
		status: 150,
	};

	useEffect(() => {
		const handleSelectedRowsChange = () => {
			const { contentRowIds } = useSelectedContentTableRowIds.getState();
			const isOpen = contentRowIds.length > 0;
			useTableActionBarState.setState({ isOpen });
		};
		const unsubscribe = useSelectedContentTableRowIds.subscribe(
			handleSelectedRowsChange,
		);

		return () => {
			unsubscribe();
		};
	}, []);

	const statusLabel = {
		[ContentStatus.Active]: t.active(),
		[ContentStatus.Inactive]: t.inactive(),
	} as const;

	const getIconColor = (status: ContentStatus): string => {
		switch (status) {
			case ContentStatus.Active:
				return "success";
			case ContentStatus.Inactive:
				return "warning";
			default:
				return "info";
		}
	};

	const columns: ColumnDef<IContentClient>[] = useMemo(
		() => [
			{
				id: "select",
				header: ({ table }) => (
					<>
						<Checkbox
							isSelected={table.getIsAllPageRowsSelected()}
							onChange={(value) => {
								table.toggleAllPageRowsSelected();
								const currentPageIds = table
									.getRowModel()
									.rows.map((row) => row.original._id);

								if (value) {
									addContentRowIds(currentPageIds);
								} else {
									removeContentRowIds(currentPageIds);
								}
							}}
						/>
					</>
				),
				cell: ({ row }) => (
					<Checkbox
						isSelected={row.getIsSelected()}
						onChange={(value) => {
							row.toggleSelected();

							if (value) {
								addContentRowId(row.original._id);
							} else {
								removeContentRowId(row.original._id);
							}
						}}
					/>
				),
				enableSorting: false,
				enableHiding: false,
				size: TableColumnSize.checkbox,
			},
			{
				accessorKey: "name",
				header: ({ column }) => (
					<DataTableColumnHeader column={column} title="Name" />
				),
				cell: ({ row }) => <div>{row.getValue("name")}</div>,
				enableSorting: false,
				enableHiding: false,
				size: TableColumnSize.name,
			},
			{
				accessorKey: "storageTotal",
				header: ({ column }) => (
					<DataTableColumnHeader column={column} title="Insgesamt" />
				),
				cell: ({ row }) => {
					const displayStorage = formatFileSize(
						row.original.storage.storageTotal,
					);

					return (
						<div className="flex gap-0.5 items-center">
							<span>{displayStorage.formattedSize}</span>
							<span className="text-xs text-foreground-muted">
								{displayStorage.unit}
							</span>
						</div>
					);
				},
				enableSorting: false,
				enableHiding: false,
				size: TableColumnSize.storage,
			},
			{
				accessorKey: "folders",
				header: ({ column }) => (
					<DataTableColumnHeader column={column} title="Ordner" />
				),
				cell: ({ row }) => {
					const displayStorage = formatFileSize(
						row.original.storage.folders.storage,
					);

					return (
						<div className="flex items-center gap-2">
							{row.original.storage.folders.count}
							<Separator orientation="vertical" className="h-2" />
							<div className="flex gap-0.5 items-center">
								<span>{displayStorage.formattedSize}</span>
								<span className="text-xs text-foreground-muted">
									{displayStorage.unit}
								</span>
							</div>
							<Separator orientation="vertical" className="h-2" />
							<div className="flex gap-0.5 items-center">
								<span>{row.original.storage.folders.percentage}</span>
								<span className="text-xs text-foreground-muted">%</span>
							</div>
						</div>
					);
				},
				enableSorting: false,
				enableHiding: false,
				size: TableColumnSize.folders,
			},
			{
				accessorKey: "images",
				header: ({ column }) => (
					<DataTableColumnHeader column={column} title="Bilder" />
				),
				cell: ({ row }) => {
					const displayStorage = formatFileSize(
						row.original.storage.images.storage,
					);

					return (
						<div className="flex items-center gap-2">
							{row.original.storage.images.count}
							<Separator orientation="vertical" className="h-2" />
							<div className="flex gap-0.5 items-center">
								<span>{displayStorage.formattedSize}</span>
								<span className="text-xs text-foreground-muted">
									{displayStorage.unit}
								</span>
							</div>
							<Separator orientation="vertical" className="h-2" />
							<div className="flex gap-0.5 items-center">
								<span>{row.original.storage.images.percentage}</span>
								<span className="text-xs text-foreground-muted">%</span>
							</div>
						</div>
					);
				},
				enableSorting: false,
				enableHiding: false,
				size: TableColumnSize.images,
			},
			{
				accessorKey: "videos",
				header: ({ column }) => (
					<DataTableColumnHeader column={column} title="Videos" />
				),
				cell: ({ row }) => {
					const displayStorage = formatFileSize(
						row.original.storage.videos.storage,
					);

					return (
						<div className="flex items-center gap-2">
							{row.original.storage.videos.count}
							<Separator orientation="vertical" className="h-2" />
							<div className="flex gap-0.5 items-center">
								<span>{displayStorage.formattedSize}</span>
								<span className="text-xs text-foreground-muted">
									{displayStorage.unit}
								</span>
							</div>
							<Separator orientation="vertical" className="h-2" />
							<div className="flex gap-0.5 items-center">
								<span>{row.original.storage.videos.percentage}</span>
								<span className="text-xs text-foreground-muted">%</span>
							</div>
						</div>
					);
				},
				enableSorting: false,
				enableHiding: false,
				size: TableColumnSize.videos,
			},
			{
				accessorKey: "documents",
				header: ({ column }) => (
					<DataTableColumnHeader column={column} title="Dateien" />
				),
				cell: ({ row }) => {
					const displayStorage = formatFileSize(
						row.original.storage.documents.storage,
					);

					return (
						<div className="flex items-center gap-2">
							{row.original.storage.documents.count}
							<Separator orientation="vertical" className="h-2" />
							<div className="flex gap-0.5 items-center">
								<span>{displayStorage.formattedSize}</span>
								<span className="text-xs text-foreground-muted">
									{displayStorage.unit}
								</span>
							</div>
							<Separator orientation="vertical" className="h-2" />
							<div className="flex gap-0.5 items-center">
								<span>{row.original.storage.documents.percentage}</span>
								<span className="text-xs text-foreground-muted">%</span>
							</div>
						</div>
					);
				},
				enableSorting: false,
				enableHiding: false,
				size: TableColumnSize.documents,
			},
			{
				accessorKey: "status",
				header: ({ column }) => (
					<DataTableColumnHeader column={column} title="Status" />
				),
				cell: ({ row }) => {
					return (
						<div className={clsx("flex gap-2 items-center rounded-md w-min")}>
							<Icon
								icon={faCircleDot}
								primaryColor={getIconColor(
									row.original.status as ContentStatus,
								)}
								secondaryColor={getIconColor(
									row.original.status as ContentStatus,
								)}
							/>
							<span>
								{statusLabel[row.original.status as keyof typeof statusLabel]}
							</span>
						</div>
					);
				},
				enableSorting: false,
				enableHiding: false,
				size: TableColumnSize.status,
			},
		],
		[
			addContentRowId,
			addContentRowIds,
			removeContentRowId,
			removeContentRowIds,
		],
	);

	return columns;
};

export default useGetContentTableColumns;
