import SectionWrapper from "@/components/layout/sectionWrapper/sectionWrapper";
import * as t from "@/i18n/paraglide/messages";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import FeatureCard from "./components/featureCard";
import { FeatureData } from "./helpers/featureData";

const Features: React.FC = () => {
	const [open, setOpen] = useState(FeatureData[0].id);
	const feature = FeatureData.find((s) => s.id === open);

	return (
		<SectionWrapper title={t.features()} subtitle={t.featuresSubtitle()}>
			<div className="mx-auto grid w-full max-w-7xl grid-cols-1 gap-8 lg:grid-cols-[1fr_800px]">
				<div>
					<div className="flex flex-col gap-4">
						{FeatureData.map((feature) => {
							return (
								<FeatureCard
									feature={feature}
									key={feature.id}
									open={open}
									setOpen={setOpen}
									index={feature.id}
								/>
							);
						})}
					</div>
				</div>
				<AnimatePresence mode="wait">
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						key={feature?.id}
						className="lg:aspect-auto aspect-[4/3] rounded-md"
					>
						<img
							className="rounded-md"
							src={`/images/${feature!.image}`}
							alt={feature!.title}
						/>
					</motion.div>
				</AnimatePresence>
			</div>
		</SectionWrapper>
	);
};

Features.displayName = "Features";
export default Features;
