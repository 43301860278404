import EventListScreen from "@/components/screens/app/events/eventList/eventListScreen";
import { queryAllEvents } from "@/components/screens/app/events/hooks/useFetchAllEvents";
import * as t from "@/i18n/paraglide/messages";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_appLayout/app/events/")({
	component: EventListScreen,
	loader: ({ context: { queryClient } }) =>
		queryClient.ensureQueryData(queryAllEvents),
	beforeLoad: () => {
		document.title = `${import.meta.env.VITE_APP_NAME} • ${t.eventMetaTitle()}`;
	},
});
