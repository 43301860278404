import { faFolders, faImage, faFileLines, faClapperboardPlay, faQuestionCircle, IconDefinition } from "@fortawesome/pro-duotone-svg-icons";
import { ContentItemType } from "shared-types";

export const getContentItemTypeIconObject = (type: ContentItemType): IconDefinition => {
  switch (type) {
    case ContentItemType.Folder:
      return faFolders;
    case ContentItemType.Image:
      return faImage;
    case ContentItemType.Document:
      return faFileLines;
    case ContentItemType.Video:
      return faClapperboardPlay;
    default:
      return faQuestionCircle;
  }
}