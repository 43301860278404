import ScreenWrapper from "@/components/layout/app/screenWrapper/screenWrapper";
import AddressForm from "@/components/ui/addressForm/addressForm";
import Button from "@/components/ui/button/button";
import {
	ButtonIconSide,
	ButtonVariant,
} from "@/components/ui/button/helpers/buttonTypes";
import DataCard from "@/components/ui/dataCard/dataCard";
import FormDatePicker from "@/components/ui/form/components/formDatePicker/formDatePicker";
import FormTextInput from "@/components/ui/form/components/formTextInput/formTextInput";
import { Form } from "@/components/ui/form/form";
import * as t from "@/i18n/paraglide/messages";
import { faCheck } from "@fortawesome/pro-duotone-svg-icons";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "@tanstack/react-router";
import { type SubmitHandler, useForm } from "react-hook-form";
import type { IEventClient } from "shared-types";
import type { z } from "zod";
import { EventSchema } from "../data/eventFormSchema";
import { getInitialEventValues } from "../data/eventFormValues";
import useCreateEvent from "../hooks/useCreateEvent";

const EventCreateScreen: React.FC = () => {
	const form = useForm<z.infer<typeof EventSchema>>({
		resolver: zodResolver(EventSchema),
		defaultValues: getInitialEventValues(),
		mode: "onBlur",
		shouldFocusError: true,
	});

	const nameValue = form.watch("name");
	const navigate = useNavigate({ from: "/app/events/create" });
	const { mutate: createEvent } = useCreateEvent();
	const onSubmit: SubmitHandler<IEventClient> = (values) => {
		console.log("type", typeof values);
		createEvent(values, {
			onSuccess: () => {
				navigate({
					to: "/app/events",
				});
			},
		});
	};

	return (
		<ScreenWrapper
			title={nameValue || t.createEventTitle()}
			breadcrumbs={[
				{
					name: "Events",
					link: "/app/events",
				},
			]}
			actionButtons={[
				<Button
					key={"Save"}
					label="Save"
					variant={ButtonVariant.Fill}
					icon={faCheck}
					iconSide={ButtonIconSide.Left}
					disabled={!form.formState.isValid}
					onClick={() => form.handleSubmit(onSubmit)()}
					isResponsive
				/>,
			]}
		>
			<Form {...form}>
				<form>
					<div className="flex flex-col gap-4 lg:flex-row lg:gap-8">
						<div className="flex flex-col lg:w-1/2 gap-4 lg:gap-8">
							{/* Details */}
							<DataCard>
								<div className="flex">
									{/* Name */}
									<div className="w-full">
										<FormTextInput
											form={form}
											name="name"
											placeholder="Name"
											label="Name"
											isRequired
											isDisabled={form.formState.isSubmitting}
										/>
									</div>
								</div>
								<div className="flex flex-col gap-5 sm:flex-row">
									{/* StartDate */}
									<div className="w-full sm:w-1/2">
										<FormDatePicker
											form={form}
											name="startDate"
											placeholder="Startdatum"
											label="Startdatum"
											isDisabled={form.formState.isSubmitting}
										/>
									</div>
									{/* EndDate */}
									<div className="w-full sm:w-1/2">
										<FormDatePicker
											form={form}
											name="endDate"
											placeholder="Enddatum"
											label="Enddatum"
											isDisabled={form.formState.isSubmitting}
										/>
									</div>
								</div>
							</DataCard>
							{/* Inactivity */}
							{/*
						<DataCard>
							<div className="flex flex-col gap-4">
								<span className="flex flex-col gap-1">
									<h5 className="tracking-wide">Inaktivitätsmanager</h5>
									<p className="text-md">Hier können Sie den Zeitraum festlegen, nach dem das Event automatisch auf inaktiv gesetzt wird. <span className="link cursor-pointer mr-1 antialiased">Lesen Sie hier welche Auswirkung die Inaktivität hat.</span></p>
								</span>
								
							</div>
						</DataCard>
						 */}
						</div>
						<DataCard className="lg:w-1/2">
							<AddressForm form={form} />
						</DataCard>
					</div>
				</form>
			</Form>
		</ScreenWrapper>
	);
};

EventCreateScreen.displayName = "EventCreateScreen";
export default EventCreateScreen;
