import api from "@/lib/api";
import { QueryKeys } from "@/lib/enums/enums";
import type { IServerRes } from "@/lib/responses";
import type { IContentClient } from "shared-types";

/**
 * @description Fetches a single content by its ID.
 *
 * @param {Object} params - The parameters for the fetch request.
 * @param {string} params.contentId - The ID of the content to fetch.
 *
 * @returns {Promise<IContentClient>} A promise that resolves to the content data.
 *
 * @throws {Error} Throws an error if the fetch request fails.
 */
const updateOneContent = async ({
	data,
}: { data: IContentClient }): Promise<IServerRes<IContentClient>> => {
	try {
		const response = await api
			.post(`${QueryKeys.Content}/${data._id}`, {
				json: data,
			})
			.json<IServerRes<IContentClient>>();
		return response;
	} catch (error) {
		console.log("updateContentError", error);
		if (error instanceof Error) {
			throw new Error(error.message || "Failed to update content.");
		} else {
			throw new Error("Failed to update content.");
		}
	}
};

export default updateOneContent;
