import clsx from "clsx";
import { motion } from "framer-motion";
import type { CursorProps } from "../helpers/tabTypes";

const Cursor: React.FC<CursorProps> = ({ position, isDense = false }) => {
	return (
		<motion.span
			animate={{
				...position,
			}}
			className={clsx(
				"absolute z-0 rounded-md bg-background-quaternary h-4",
				isDense ? "md:h-7" : "md:h-8",
			)}
		/>
	);
};

Cursor.displayName = "Cursor";
export default Cursor;
