import ScreenWrapper from "@/components/layout/app/screenWrapper/screenWrapper";
import Button from "@/components/ui/button/button";
import {
	ButtonIconSide,
	ButtonVariant,
} from "@/components/ui/button/helpers/buttonTypes";
import TableErrorSkeleton from "@/components/ui/table/components/tableErrorSkeleton";
import TableLoadingSkeleton from "@/components/ui/table/components/tableLoadingSkeleton";
import Table from "@/components/ui/table/table";
import * as t from "@/i18n/paraglide/messages";
import { useTableActionBarState } from "@/store/tableActionBarState";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { useSuspenseQuery } from "@tanstack/react-query";
import { Link, useNavigate } from "@tanstack/react-router";
import { useCallback, useEffect } from "react";
import { EventStatus, type IEventClient } from "shared-types";
import { useSelectedEventTableRowIds } from "../helpers/selectedEventTableRowIdsStore";
import useDeleteEvents from "../hooks/useDeleteEvents";
import { queryAllEvents } from "../hooks/useFetchAllEvents";
import useGetEventTableColumns from "../hooks/useGetEventTableColumns";

const EventListScreen: React.FC = () => {
	const {
		data: response,
		isLoading,
		isError,
	} = useSuspenseQuery(queryAllEvents);

	const navigate = useNavigate({ from: "/app/events" });
	const columns = useGetEventTableColumns();
	const { clearEventRowIds, eventRowIds } = useSelectedEventTableRowIds();

	const { mutate: deleteEvents } = useDeleteEvents(clearEventRowIds);

	useEffect(() => {
		return () => {
			clearEventRowIds();
			useTableActionBarState.setState({ isOpen: false });
		};
	}, [clearEventRowIds]);

	const handleRowClick = useCallback((event: IEventClient) => {
		navigate({
			to: `/app/events/${event._id}`,
		});
	}, []);

	return (
		<ScreenWrapper
			title="Events"
			breadcrumbs={[]}
			actionButtons={[
				<Link key={t.add()} to="/app/events/create">
					<Button
						variant={ButtonVariant.Fill}
						icon={faPlus}
						iconSide={ButtonIconSide.Left}
						label={t.add()}
						isResponsive
					/>
				</Link>,
			]}
		>
			{isLoading && (
				<TableLoadingSkeleton<IEventClient> columns={columns} rowCount={10} />
			)}

			{!isLoading && (isError || !response?.success) && (
				<TableErrorSkeleton<IEventClient> columns={columns} />
			)}

			{!isLoading && !isError && response?.success && (
				<Table<IEventClient>
					columns={columns}
					data={response?.data}
					onRowClick={handleRowClick}
					onDelete={() => deleteEvents({ eventIds: eventRowIds })}
					onDuplicate={() => {}}
					tableColumnFilters={[
												{
													accessorKey: "status",
													label: t.status(),
													options: [
														{ label: t.active(), value: EventStatus.Active },
														{ label: t.inactive(), value: EventStatus.Inactive },
													],
												},
											]}
				/>
			)}
		</ScreenWrapper>
	);
};

EventListScreen.displayName = "EventListScreen";
export default EventListScreen;
