import { queryAllUsers } from "@/components/screens/app/users/hooks/useFetchAllUsers";
import UserListScreen from "@/components/screens/app/users/userList/userListScreen";
import * as t from "@/i18n/paraglide/messages";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_appLayout/app/users/")({
	component: UserListScreen,
	loader: ({ context: { queryClient } }) =>
		queryClient.ensureQueryData(queryAllUsers),
	beforeLoad: () => {
		document.title = `${import.meta.env.VITE_APP_NAME} • ${t.userMetaTitle()}`;
	},
});
