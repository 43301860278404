export enum Appearance {
	light = "light",
	dark = "dark",
}

export enum UserLanguage {
	De = "de",
	En = "en",
	Fr = "fr",
	It = "it",
	Pl = "pl",
	Es = "es",
}

export enum UserRole {
	Admin = "admin",
	Employee = "employee",
}

export enum UserStatus {
	Active = "active",
	Inactive = "inactive",
	Invited = "invited",
	Deleted = "deleted",
	Unverified = "unverified",
}

export enum UserAgentDeviceType {
	Mobile = "mobile",
	Tablet = "tablet",
	Desktop = "desktop",
	Unknown = "unknown",
}